import React from 'react'
import "./hexagonal.scss"

const Hexagonal = ({

  className,
  title,
  background,
  onMouseEnter,
  OnMouseLeave,
  hover,
  blurs,
  image,
  shadow = true,
  onClick,

  mobileOnClick,
  isDesktopActive = true
}) => {

  return (
    <div className='hexagonal-svg' onMouseEnter={onMouseEnter} onMouseLeave={OnMouseLeave} onClick={onClick}>

      <svg
        preserveAspectRatio='none'
        viewBox='0 0 120 138'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={`${className} ${title && !hover && !blurs && " blinck"} ${hover && shadow && " shadow"} ${blurs && " blur"}`}>
        <path
          d='M117.885 35.1269L61.3346 0.876688C60.5053 0.374437 59.4927 0.374437 58.6633 0.876688L2.11308 35.1269C1.2729 35.635 0.755859 36.5825 0.755859 37.6043V100.396C0.755859 101.417 1.2729 102.365 2.11308 102.873L58.6633 137.123C59.0781 137.375 59.5358 137.5 59.9991 137.5C60.4624 137.5 60.9201 137.374 61.3349 137.123L117.885 102.873C118.725 102.365 119.242 101.417 119.242 100.396V37.6043C119.242 36.5825 118.725 35.635 117.885 35.1269Z'
          fill={background}
        />
      </svg>

      {
        hover && <img src={image} loading='lazy' />
      }

      <div className={`hexagonal-title ${blurs && "blur"}`}>
        {title}
      </div>
    </div>
  )
}

export default React.memo(Hexagonal)
