import React from 'react'

import Imagedescription from '../../components/imagedescription/Imagedescription';
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';
import ProcessServices from '../../components/processServices/ProcessServices';
import Recentreviews from '../../components/recentReviews/Recentreviews';
import ContactForm from '../../components/contactus/ContactForm';

import mainImage from "../../assets/services/Utility Development/main utility.svg";

import Icons1 from "../../assets/services/Utility Development/Our Servicess/Frame 3466398.svg"
import Icons2 from "../../assets/services/Utility Development/Our Servicess/Frame 3466398-1.svg"
import Icons3 from "../../assets/services/Utility Development/Our Servicess/Frame 3466398-2.svg"
import Icons4 from "../../assets/services/Utility Development/Our Servicess/Frame 3466398-3.svg"

import processImage from '../../assets/services/processSection/utility.png'

const ImageDescriptiondata = {
  title: "Utility Development",
  subtitle: "Utility Development",
  description: [
    `At InFineIT Solutions, we understand the importance of having reliable and efficient utilities for your business operations. That's why we offer a full range of utility development services, designed to help you streamline processes and increase productivity.`
    ,
    `Our team of experienced software developers and engineers have extensive knowledge of various programming languages and technologies, ensuring that we can deliver custom utilities that meet your specific needs.`
  ],
  image: mainImage,

};
const ourServicesData = {
  subtitle: "What we Do",
  title: "Our Services",
  description: [
    `Ensuring the quality and reliability of your software is crucial for success. Our team of experts provides comprehensive testing and quality assurance services to help you deliver high-quality products to your customers. Contact us today to learn more about our web development services and how we can help you grow your business online.`
  ],
  button: {
    title: "Book A Free Consultation",
    url: "/contactus",
  },
  cardList: [
    {
      icon: Icons1,
      title: "API Integration:",
      description: `API integration is now a critical component of connecting systems, platforms, and applications in the modern, fast-paced digital world. InFineIT provides API integration services to help you organize your business, boost productivity, and spur expansion. We can effortlessly integrate your systems with a broad range of third-party platforms and applications thanks to the deep understanding of numerous APIs and technologies that our team of skilled developers and engineers has in this area.`
    },
    {
      icon: Icons2,
      title: "API Development:",
      description: "APIs (Application Programming Interfaces) have become an essential part of modern business operations, enabling seamless communication and data exchange between different systems and applications. Our API development services help businesses leverage the power of APIs to improve their digital operations, increase efficiency, and drive innovation."
    },

    {
      icon: Icons3,
      title: "Data Migration Tool:",
      description: `Data migration is a complex and critical process that requires expert planning and execution. Our data migration services help businesses move their data from one system to another, with minimal disruption to their operations and without losing any data.`
    },
    {
      icon: Icons4,
      title: "Image Processing Tool:",
      description: "Many contemporary commercial processes depend on image processing, which demands specialized knowledge and cutting-edge technology to produce accurate and effective outcomes. Our image processing services assist companies in utilizing the power of picture analysis to enhance workflow, boost productivity, and spur creativity."
    },
  ]
};


const Utilitydevelopment = () => {
  return (
    <>
      <Imagedescription
        image={ImageDescriptiondata.image}
        title={ImageDescriptiondata.title}
        subtitle={ImageDescriptiondata.subtitle}
        description={ImageDescriptiondata.description}
        listItem={ImageDescriptiondata.listItem}
      />

      <Toolstouse
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
        isDesigning={false}
        isFrontend={false}
        isMobility={false}
        isTechnologies={false}
        maxWidth={"1000px"}
      />

      <GeneralOurServices
        title={ourServicesData.title}
        subtitle={ourServicesData.subtitle}
        description={ourServicesData.description}
        buttonText={ourServicesData.button.title}
        buttonURl={ourServicesData.button.url}
        cartListItem={ourServicesData.cardList}
      />

      <ProcessServices
        title={'Utility Development Process'}
        img={processImage}
      />

      {/* <Recentreviews /> */}

      <ContactForm
        descriptionText={"Fill out the information below. To get more information about Testing & QA <br/> and its services."}
      />

    </>
  );
}

export default Utilitydevelopment
