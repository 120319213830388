import React from 'react'

const Pinmaterialright = () => {
  return (
    <svg width="96" height="100" viewBox="0 0 96 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M45.4074 90.9017C46.8471 90.8215 48.0077 89.5011 47.9994 87.9524C47.9911 86.4036 46.8171 85.2134 45.3774 85.2936C43.9377 85.3737 42.7771 86.6939 42.7854 88.2426C42.7937 89.7914 43.9677 90.9818 45.4074 90.9017Z" fill="#D1AB4A"/>
    <path d="M65.204 58.6272C65.7651 58.9607 66.4919 58.7283 66.8276 58.1081C67.1633 57.4882 66.9809 56.7151 66.4198 56.3816C65.8588 56.0481 65.1319 56.2807 64.7963 56.9007C64.4605 57.5208 64.643 58.2937 65.204 58.6272Z" fill="#D1AB4A"/>
    <path d="M65.2973 56.4606L49.9621 43.5755L49.9153 43.5839L49.9191 43.6346L65.2548 56.5193L65.3014 56.5114L65.2973 56.4606Z" fill="#4E494B"/>
    <path d="M47.5345 37.9868L47.6775 38.1287L54.2915 74.6334C54.2541 74.642 54.2167 74.6516 54.1795 74.6621L47.5345 37.9868Z" fill="#4E494B"/>
    <path d="M39.5951 48.6166L39.7381 48.7586L46.3521 85.2633C46.3147 85.2719 46.2772 85.2815 46.2401 85.292L39.5951 48.6166Z" fill="#4E494B"/>
    <path d="M27.7919 1.54881L28.8137 2.73788L28.5286 2.91693L27.5562 1.78538C27.6372 1.7106 27.716 1.63186 27.7919 1.54881ZM28.8803 2.81518L78.8124 60.9109L78.4243 60.9699L28.5948 2.99407L28.8803 2.81518Z" fill="#B1A5AA"/>
    <path d="M52.8274 76.9306C53.9302 77.7275 55.4655 77.3804 56.2568 76.155C57.0481 74.9297 56.7954 73.2905 55.6926 72.4936C54.59 71.6969 53.0551 72.0443 52.2638 73.2696C51.4725 74.495 51.7248 76.1338 52.8274 76.9306Z" fill="#D1AB4A"/>
    <path d="M34.8673 47.1447L35.0663 47.1258L64.4553 65.8595C64.4341 65.8945 64.4136 65.93 64.3938 65.9659L34.8673 47.1447Z" fill="#4E494B"/>
    <path d="M64.9479 68.5106C66.2811 68.17 67.1549 66.7418 66.8997 65.3204C66.6444 63.8991 65.3567 63.0232 64.0235 63.3639C62.6906 63.7045 61.8172 65.1325 62.0725 66.5539C62.3278 67.9753 63.6149 68.8512 64.9479 68.5106Z" fill="#D1AB4A"/>
    <path d="M76.383 62.3628C77.5477 63.0551 79.0568 62.573 79.7538 61.2857C80.4507 59.9984 80.0714 58.3938 78.9067 57.7015C77.7422 57.0093 76.2335 57.4916 75.5365 58.7789C74.8395 60.0662 75.2184 61.6706 76.383 62.3628Z" fill="#D1AB4A"/>
    </svg>
    
  )
}

export default Pinmaterialright
