import BusinessFocusIcon from "../svg/BusinessFocusIcon";
import LongternsupportIcon from "../svg/LongternsupportIcon";
import SimpleimplementIcon from "../svg/SimpleimplementIcon";
import DirectcommunicationIcon from "../svg/DirectcommunicationIcon";
import PencilIcon from "../svg/PencilIcon";
import Moderntechnology from "../svg/Moderntechnology";

const data = [
    {
      icon: <PencilIcon />,
      heading: "First Class Design",
      desc: "Elevate your expectations with first class design.",
    },
    {
      icon: <Moderntechnology />,
      heading: "Modern technologies",
      desc: "Developing with us means always being at the cutting edge of technology.",
    },
    {
      icon: <BusinessFocusIcon />,
      heading: "Business Focus",
      desc: "Powering your growth with IT solutions and business focus.",
    },
    {
      icon: <LongternsupportIcon />,
      heading: "Long Term support",
      desc: "Also in the long term we are at your side and take care of support and updates.",
    },
    {
      icon: <SimpleimplementIcon />,
      heading: "Simple Implementation",
      desc: "You take care of your running business, everything else is our job.",
    },
    {
      icon: <DirectcommunicationIcon />,
      heading: "Direct Communication",
      desc: "Transparency and success through direct communication.",
    },
  ];

export default data
  