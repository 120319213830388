import React from 'react'
import Imagedescription from '../../components/imagedescription/Imagedescription';
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';
import ProcessServices from '../../components/processServices/ProcessServices';
import Recentreviews from '../../components/recentReviews/Recentreviews';
import ContactForm from '../../components/contactus/ContactForm';

import mainImage from "../../assets/services/Web Development/main web.svg";

import Icons1 from "../../assets/services/Web Development/Our Services/Frame 3466398.svg"
import Icons2 from "../../assets/services/Web Development/Our Services/Frame 3466398-1.svg"
import Icons3 from "../../assets/services/Web Development/Our Services/Frame 3466398-2.svg"
import Icons4 from "../../assets/services/Web Development/Our Services/Frame 3466398-3.svg"
import Icons5 from "../../assets/services/Web Development/Our Services/Frame 3466398-4.svg"
import Icons6 from "../../assets/services/Web Development/Our Services/Frame 3466398-5.svg"

import processImage from '../../assets/services/processSection/webdevelopment.png'

const ImageDescriptiondata = {
  title: "Web development",
  subtitle: "Web development",
  description: [
    ` Building a website is a critical step in today’s digital world and having a well-designed and functional website can greatly impact the success of your business. Our team of experienced web developers is dedicated to creating professional, responsive, and user-friendly websites that help you achieve your online goals.`,
  ,
  `We understand the importance of having a website that meets the needs of your customers and provides them with a seamless user experience. That is why we offer a comprehensive web development service tha covers all aspects of website creation, from planning and design to coding and implementation.`
  ],
  image: mainImage,
};
const ourServicesData = {
  subtitle: "What we Do",
  title: "Our Services",
  description:[
    `Our web development services are designed to help you achieve your goals and make the most of your online presence. Whether you need a custom website, an e-commerce platform, or a responsive design, we can help you create a website that meets your needs and exceeds your expectations. Contact us today to learn more about our web development services and how we can help you grow your business online. `,
  ],
    button: {
    title: "Book A Free Consultation",
    url: "/contactus",
  },
  cardList: [
    {
      icon: Icons1,
      title: " Front End Development: ",
      description: `Use cutting-edge Frontend technologies like React.js, Angular, and Vue.js to deliver component-driven architecture by utilising the reusable components. InFineIT is a firm that creates web applications and simply integrates stunning UI designs with necessary capabilities.`
    },
    {
      icon: Icons2,
      title: "Back End Development",
      description: "Access the talented pool of backend web developers who can make your application scalable to seamlessly switch between various elements of an application. In addition to providing AWS and Azure services to host the backend services in the cloud, we also provide SQL and NoSQL databases for Microservices to use for storing and retrieving data with reliability."
    },
    {
      icon: Icons3,
      title: "SaaS Application Development:",
      description: "The process of developing cloud-based software solutions that can be downloaded and utilized online is known as SaaS (Software as a Service) Application Development. Our team of professionals specializes in creating scalable and secure SaaS apps that are tailored to your company's unique requirements. Custom SaaS application development, system integration, cloud migration, and ongoing maintenance and support are a few of the services we offer."
    },
    {
      icon: Icons4,
      title: " Progressive Web Apps:",
      description: `To meet the demands of contemporary app development, we produce native web applications with advanced technological capabilities, quicker application loads, highly dynamic visual content, and simple navigation between various application elements.`
    },
    {
      icon: Icons5,
      title: "E-commerce Web & App Development:",
      description: "Develop customised eCommerce web apps using Shopify, Magento, and unique frameworks to accelerate company growth and improve online presence. Our skilled web developers create agile, secure, and user-friendly eCommerce solutions to assure growth and customer engagement."
    },
    {
      icon: Icons6,
      title: "Custom Web Application Development:",
      description: "We assist you in providing top-notch web development services along with knowledgeable resources and technological acumen to support your overall business plan. Our expertise in developing custom web applications ensures interoperability, scalability, and user-friendly interfaces."
    },
  ]
};

const Webdevelopment = () => {
  return (
    <>
      <Imagedescription
        image={ImageDescriptiondata.image}
        title={ImageDescriptiondata.title}
        subtitle={ImageDescriptiondata.subtitle}
        description={ImageDescriptiondata.description}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={ourServicesData.title}
        subtitle={ourServicesData.subtitle}
        description={ourServicesData.description}
        buttonText={ourServicesData.button.title}
        buttonURl={ourServicesData.button.url}
        cartListItem={ourServicesData.cardList}
      />

      <ProcessServices
        title={'Web Development Process'}
        img={processImage}
      />

      {/* <Recentreviews /> */}

      <ContactForm
        descriptionText={"Fill out the information below. To get more information about Web <br/> Development and its services."}
      />

    </>
  );
}

export default Webdevelopment
