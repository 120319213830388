import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import postGressSvg from '../../assets/TechnologiesSvg/postGressSvg.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessPostGre from '../../assets/TechnologiesSvg/ourProcessPostGre.svg';
import HireUsNow from '../../components/HireUsNow/HireUsNow';


function PostGress() {
  const postGressData =
  {
    title: 'PostgreSQL',
    titleDescription: ['A popular open-source relational database management system (RDBMS) for usage in enterprise, mobile, and web applications is PostgreSQL. PostgreSQL offers strong and dependable data storage and retrieval capabilities that are necessary for contemporary software applications thanks to its strict adherence to SQL standards.',

      'The scalability, dependability, and security advantages of PostgreSQL are well known. It is an excellent option for applications with high data storage and retrieval requirements since it can handle massive amounts of data. Sensitive data is further safeguarded from unauthorised access by its sophisticated security measures, which also include role-based access control and strong encryption.'],
    mainSvg: '',
  };

  const processDataPostGres = [
    `<span className="li-header">Open Source:</span> PostgreSQL is an open source database management system, allowing organizations to benefit from a large and vibrant community of developers and users who contribute to its growth and improvement.`,

    `<span className="li-header">Scalability and Performance:</span> PostgreSQL is designed for large-scale, high-performance environments, making it ideal for organizations with growing data needs.`,

    `<span className="li-header">Flexibility:</span> With a wide range of data types, advanced indexing options, and support for stored procedures and triggers, PostgreSQL offers unparalleled flexibility for a variety of use cases.`,

    `<span className="li-header">Strong Community Support:</span> With a large and active user community, organizations can take advantage of a wealth of knowledge, resources, and third-party tools to help them get the most out of their investment in PostgreSQL.`,

    `<span className="li-header">Enterprise-Class Features:</span> PostgreSQL includes advanced features such as parallel query processing, data replication, and disaster recovery, making it a suitable choice for even the most demanding enterprise environments.`,

    `<span className="li-header">Cost Effective:</span> Because PostgreSQL is open source, organizations can benefit from lower software costs and reduced vendor lock-in compared to proprietary databases.`,
  ];

  const processDescription = `Some of the benefits of developing with Flutter include:`;

  return (
    <div className="container-technology">
      <Imagedescription
        image={postGressSvg}
        title={postGressData.title}
        subtitle={postGressData.title}
        description={postGressData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <OurProcedure
        heading="POSTGRESQL"
        subHeading="Why choose PostgreSQL?"
        mainSvg={ourProcessPostGre}
        procedureData={processDataPostGres}
        endText={`
          Overall, PostgreSQL is a versatile and powerful database management system that can meet the needs of IT-based companies of all sizes. Whether you need to support transactional workloads, business intelligence and analytics, or a combination of both, PostgreSQL provides the tools and features you need to succeed.`}
        processDescription={processDescription}
      />

      <HireUsNow
        techName='PostGreSQL'
      />
    </div>
  );
}

export default PostGress;