import React from 'react'
import wirefremeprototypeImage from "../../assets/services/Tools/wireframeprototype.svg"

const Wireframeprototypetools = () => {
  return (
    <img src={wirefremeprototypeImage}/>

  )
}

export default Wireframeprototypetools
