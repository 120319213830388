import React, { useState } from 'react'
import Hexagonal from './Hexagonal'
import "./hexagonalMobile.scss"
import data from "./data"

const HexagonalMobile = ({}) => {
    const [text,setText] = useState(data[0]);

    const  HexagonalOnClick1 = ()=>{
            setText(data[1])
    }

    const HexagonalOnClick2 = ()=>{
        setText(data[4])
    }

    const HexagonalOnClick3 = ()=>{
        setText(data[5])
    }

    const HexagonalOnClick4 = () =>{

    }

    const  HexagonalOnClick5 = ()=>{

    }

    const HexagonalOnClick6 = ()=>{

    }

    const HexagonalOnClick7 = ()=>{

    }

    const HexagonalOnClick8 = ()=>{

    }

  return (
    <div className='mobileHexagonal'>
            <div className='Item-Center'>
                <Hexagonal 
                    title={"UI/UX Designing"}
                    background={"#F1F1F1"}
                    hover={true}
                    shadow={false}
                    onClick={HexagonalOnClick1}
                />
                <Hexagonal 
                    title={"Web Development"}
                    background={"#F1F1F1"}
                    hover={true}
                    shadow={false}
                    onClick={HexagonalOnClick2}
                />
                <Hexagonal 
                    title={"App Development"}
                    background={"#F1F1F1"}
                    hover={true}
                    shadow={false}
                    onClick={HexagonalOnClick3}
                />
            </div>
            <div className='Item-Center'>
                <Hexagonal 
                    title={"Software Development"}
                    background={"#F1F1F1"}
                    hover={true}
                    shadow={false}
                    onClick={HexagonalOnClick4}
                    
                />
                <Hexagonal 
                    title={"Product Development"}
                    background={"#F1F1F1"}
                    hover={true}
                    shadow={false}
                    onClick={HexagonalOnClick5}
                />
            </div>
            <div className='Item-Center'>
                <Hexagonal 
                    title={"Utility Development"}
                    background={"#F1F1F1"}
                    hover={true}
                    shadow={false}
                    onClick={HexagonalOnClick6}
                />
                <Hexagonal 
                    title={"Dedicated Team Development"}
                    background={"#F1F1F1"}
                    hover={true}
                    shadow={false}
                    onClick={HexagonalOnClick7}
                />
                <Hexagonal 
                    title={"Testing & QA"}
                    background={"#F1F1F1"}
                    hover={true}
                    shadow={false}
                    onClick={HexagonalOnClick8}
                />
            </div>


            <div className='description'>
                  <h4>{text.heading}</h4>
                  {
                    text.paragraph.map((el,index) =>{
                      return(
                        <p key={"ourservices"+index}>{el}</p>
                      )
                    })
                  }
                </div>
    </div>
  )
}

export default HexagonalMobile
