import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import reactSvg from '../../assets/TechnologiesSvg/ReactSvg.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessReact from '../../assets/TechnologiesSvg/ourProcessReact.svg';
import HireUsNow from '../../components/HireUsNow/HireUsNow';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';


function ReactJs() {
  const reactData =
  {
    title: 'React',
    titleDescription: ['A well-known JavaScript package for creating interactive and user-friendly user interfaces is called React. Since its creation by Facebook, it has grown to be one of the most used libraries for creating web apps.',

      'Our IT company has a group of skilled React developers who are committed to providing our customers with high - quality solutions that are tailored to their individual demands.Our team can produce apps that are both useful and aesthetically pleasing thanks to a thorough understanding of the library and its possibilities.'],
    mainSvg: '',
  };

  const processDataReact = [
    `<span className="li-header">Virtual DOM:</span> React uses a virtual DOM (Document Object Model), which allows developers to build applications that perform well even when dealing with large amounts of data.`,

    `<span className="li-header">Reusable Components:</span> React makes it easy to create reusable components, which can be used across multiple pages and applications, making development more efficient and scalable.`,

    `<span className="li-header">Server-Side Rendering:</span> React can be used for server-side rendering, allowing businesses to build applications that perform well even on low-end devices and slow networks.`,

    `<span className="li-header">Strong Community:</span> React has a strong and active community of developers, which means that there is a wealth of knowledge and resources available to businesses and organizations that are using the library.`,

  ];

  const processDescription = `Some of the key benefits of using React for web development include:`;

  const GeneralOurServicesData = [
    {
      icon: null,
      title: "Custom App Development",
      description: null
    },
    {
      icon: null,
      title: "Web App Modernization",
      description: null
    },
    {
      icon: null,
      title: "API Development & Integration",
      description: null
    },
    {
      icon: null,
      title: "Custom UI/UX Design using React",
      description: null
    },
    {
      icon: null,
      title: "Staff Augmentation",
      description: null
    },
    {
      icon: null,
      title: "Maintenance & Support",
      description: null
    }
  ];

  return (
    <div className="container-technology">
      <Imagedescription
        image={reactSvg}
        title={reactData.title}
        subtitle={reactData.title}
        description={reactData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={'React Development Services'}
        subtitle={'Services'}
        description={[`Our IT company offers a comprehensive range of React development services, including custom software development, web application development, and user interface and experience (UI/UX) design. With a team of experienced React developers, we are able to deliver high-quality, scalable solutions that meet the specific needs of our clients.`]}
        buttonText={null}
        buttonURl={null}
        cartListItem={GeneralOurServicesData}
      />

      <OurProcedure
        heading="REACT DEVELOPMENT"
        subHeading="Why Choose React?"
        mainSvg={ourProcessReact}
        procedureData={processDataReact}
        endText={`
            Our team of React developers is committed to delivering custom solutions that meet the specific needs of our clients. Whether you're looking to build a new application or modernize an existing one, our team has the expertise and experience to deliver a solution that meets your needs. Contact us today to learn more about how we can help you achieve your goals with React.`}
        processDescription={processDescription}
      />

      <HireUsNow
        techName='ReactJS'
      />
    </div>
  );
}

export default ReactJs;