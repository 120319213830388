import React, {  useState } from 'react'
import "./ourservices.scss"
import Hexagonal from './Hexagonal'
import data from "./data"

import HexagonalMobile from './HexagonalMobile'
import { S3URL } from '../../constant/common'

const Ourservices = () => {
  const [hover,setHover] = useState({
    uiuxHover : false,
    webDevelopment : false,
    testingQA : false,
    dedicatedTeamDevelopment : false,
    appDevelopement : false,
    softwareDevelopement : false,
    productDevelopement : false,
    utitlityDevelopement : false,
  })
  const [blurMode,setBlurMode] = useState(false)
  const [text,setText] = useState(data[0])


  function defaultParagraph(){
      setText(data[0])
  }

  const uiuxMouseEnter = (e)=>{
    setHover({
        uiuxHover : true,
        webDevelopment : false,
        testingQA : false,
        dedicatedTeamDevelopment : false,
        appDevelopement : false,
        softwareDevelopement : false,
        productDevelopement : false,
        utitlityDevelopement : false,
      })
      setBlurMode(true)
      setText(data[1])
  }
  const webDevelopmentMouseEnter = ()=>{
    setHover({
      uiuxHover : false,
      webDevelopment : true,
      testingQA : false,
      dedicatedTeamDevelopment : false,
      appDevelopement : false,
      softwareDevelopement : false,
      productDevelopement : false,
      utitlityDevelopement : false,
    })
    setBlurMode(true)
    setText(data[4])
  }
  const testingQAMouseEnter = ()=>{
    setHover({
      uiuxHover : false,
      webDevelopment : false,
      testingQA : true,
      dedicatedTeamDevelopment : false,
      appDevelopement : false,
      softwareDevelopement : false,
      productDevelopement : false,
      utitlityDevelopement : false,
    })
    setBlurMode(true)
  }
  const dedicatedTeamDevelopmentMouseEnter =()=>{
      setHover({
        uiuxHover : false,
        webDevelopment : false,
        testingQA : false,
        dedicatedTeamDevelopment : true,
        appDevelopement : false,
        softwareDevelopement : false,
        productDevelopement : false,
        utitlityDevelopement : false,
      })
      setBlurMode(true)
  }
  const appDevelopementMouseEnter =()=>{
     setHover({
      uiuxHover : false,
      webDevelopment : false,
      testingQA : false,
      dedicatedTeamDevelopment : false,
      appDevelopement : true,
      softwareDevelopement : false,
      productDevelopement : false,
      utitlityDevelopement : false,
    })
    setBlurMode(true)
    setText(data[5])
  }
  const softwareDevelopementMouseEnter = ()=>{
    setHover({
      uiuxHover : false,
      webDevelopment : false,
      testingQA : false,
      dedicatedTeamDevelopment : false,
      appDevelopement : false,
      softwareDevelopement : true,
      productDevelopement : false,
      utitlityDevelopement : false,
    })
    setBlurMode(true)
  }
  const productDevelopementMouseEnter =()=>{
      setHover({
        uiuxHover : false,
        webDevelopment : false,
        testingQA : false,
        dedicatedTeamDevelopment : false,
        appDevelopement : false,
        softwareDevelopement : false,
        productDevelopement : true,
        utitlityDevelopement : false,
      })
      setBlurMode(true)
  }
  const utitlityDevelopementMouseEnter =()=>{
      setHover({
        uiuxHover : false,
        webDevelopment : false,
        testingQA : false,
        dedicatedTeamDevelopment : false,
        appDevelopement : false,
        softwareDevelopement : false,
        productDevelopement : false,
        utitlityDevelopement : true,
      })
      setBlurMode(true)
  }



  //  const uiuxMouseLeave = ()=>{
  //   setHover({
  //     uiuxHover : false,
  //     webDevelopment : false,
  //     testingQA : false,
  //     dedicatedTeamDevelopment : false,
  //     appDevelopement : false,
  //     softwareDevelopement : false,
  //     productDevelopement : false,
  //     utitlityDevelopement : false,
  //   })
  //   setBlurMode(false)
  //   defaultParagraph()
  // } 
  // const webDevelopmentMouseLeave = ()=>{
  //   setHover({
  //     uiuxHover : false,
  //     webDevelopment : false,
  //     testingQA : false,
  //     dedicatedTeamDevelopment : false,
  //     appDevelopement : false,
  //     softwareDevelopement : false,
  //     productDevelopement : false,
  //     utitlityDevelopement : false,
  //   })
  //   setBlurMode(false)
  //   defaultParagraph()
  // }
  // const testingQAMouseLeave = ()=>{
  //   setHover({
  //     uiuxHover : false,
  //     webDevelopment : false,
  //     testingQA : false,
  //     dedicatedTeamDevelopment : false,
  //     appDevelopement : false,
  //     softwareDevelopement : false,
  //     productDevelopement : false,
  //     utitlityDevelopement : false,
  //   })
  //   setBlurMode(false)
  //   defaultParagraph()
  // }
  // const dedicatedTeamDevelopmentMouseLeave =()=>{
  //   setHover({
  //     uiuxHover : false,
  //     webDevelopment : false,
  //     testingQA : false,
  //     dedicatedTeamDevelopment : false,
  //     appDevelopement : false,
  //     softwareDevelopement : false,
  //     productDevelopement : false,
  //     utitlityDevelopement : false,
  //   })
  //   setBlurMode(false)
  //   defaultParagraph()
  // }
  // const appDevelopementMouseLeave =()=>{
  //   setHover({
  //     uiuxHover : false,
  //     webDevelopment : false,
  //     testingQA : false,
  //     dedicatedTeamDevelopment : false,
  //     appDevelopement : false,
  //     softwareDevelopement : false,
  //     productDevelopement : false,
  //     utitlityDevelopement : false,
  //   })
  //   setBlurMode(false)
  //   defaultParagraph()
  // }
  // const softwareDevelopementMouseLeave = ()=>{
  //   setHover({
  //     uiuxHover : false,
  //     webDevelopment : false,
  //     testingQA : false,
  //     dedicatedTeamDevelopment : false,
  //     appDevelopement : false,
  //     softwareDevelopement : false,
  //     productDevelopement : false,
  //     utitlityDevelopement : false,
  //   })
  //   setBlurMode(false)
  //   defaultParagraph()
  // }
  // const productDevelopementMouseLeave =()=>{
  //   setHover({
  //     uiuxHover : false,
  //     webDevelopment : false,
  //     testingQA : false,
  //     dedicatedTeamDevelopment : false,
  //     appDevelopement : false,
  //     softwareDevelopement : false,
  //     productDevelopement : false,
  //     utitlityDevelopement : false,
  //   })
  //   setBlurMode(false)
  //   defaultParagraph()
  // }
  // const utitlityDevelopementMouseLeave =()=>{
  //   setHover({
  //     uiuxHover : false,
  //     webDevelopment : false,
  //     testingQA : false,
  //     dedicatedTeamDevelopment : false,
  //     appDevelopement : false,
  //     softwareDevelopement : false,
  //     productDevelopement : false,
  //     utitlityDevelopement : false,
  //   })
  //   setBlurMode(false)
  //   defaultParagraph()
  // }

  

  /*****accoding to hover effect */
  const handleHexogonal2 = ()=>{
    if(hover.uiuxHover){
      setText(data[3])
    }
    else if(hover.webDevelopment){
      setText(data[6])
    }
  }
  const handleHexagonal3 = ()=>{
      if(hover.webDevelopment){
        setText(data[7])
      }
  }
  const handleHexgonal5 = ()=>{
    if(hover.uiuxHover){
        setText(data[2])
    } 
    else if(hover.testingQA){

    }
  }
  const handleHexagonal8 = ()=>{
    if(hover.webDevelopment){
      setText(data[8])
    }
    else if(hover.appDevelopement){
        setText(data[13])
    }
  }
  const handleHexagonal9 = ()=>{
    if(hover.webDevelopment){
      setText(data[9])
    }
    else if(hover.appDevelopement){
      setText(data[11])
  }
  }
  // const handleHexagonal11 = ()=>{
    
  // }
  const handleHexagonal12 = ()=>{
    if(hover.appDevelopement){
      setText(data[12])
    }
  }
  // const handleHexagonal14 = ()=>{

  // }
  // const handleHexagonal16 = ()=>{

  // }
  const handleHexagonal17 = ()=>{
      setText(data[10])
  }
  // const handleHexagonal18 = ()=>{

  // }




  const handleMouseLeave = ()=>{
    setHover({
      uiuxHover : false,
      webDevelopment : false,
      testingQA : false,
      dedicatedTeamDevelopment : false,
      appDevelopement : false,
      softwareDevelopement : false,
      productDevelopement : false,
      utitlityDevelopement : false,
    })
    setBlurMode(false)
    defaultParagraph()
  } 

  return (
    <section className='our-services'>
        <div className='roundedTop'></div>
        <div className='container'>
            <p className='subtitle'>What We Do</p>
            <h2 className='title'>Explore Our Services</h2>

            <div className='content'>

                <div className='description'>
                  <h4>{text.heading}</h4>
                  {
                    text.paragraph.map((el,index) =>{
                      return(
                        <p key={"ourservices"+index}>{el}</p>
                      )
                    })
                  }
                </div>
                <div className='wrapper-hexagonal' onMouseLeave={handleMouseLeave}>
                    
                  <div className='hexagonal-side'>
                    <Hexagonal
                      title="UI/UX Designing" 
                      background={ hover.uiuxHover ? '#ffffff':'#F1F1F1'} 
                      hover={hover.uiuxHover} 
                      onMouseEnter={uiuxMouseEnter}
                      // OnMouseLeave={uiuxMouseLeave}
                      blurs={blurMode && !hover.uiuxHover}
                    />

                    <Hexagonal 
                      background={'#F1F1F1'} 
                      blurs={blurMode && !hover.uiuxHover}
                      hover={hover.uiuxHover ? hover.uiuxHover : hover.webDevelopment }
                      image={hover.uiuxHover ? (`${S3URL}/OurServices/AdobeXD.svg`) : (`${S3URL}/OurServices/Angular.svg`)}
                      onMouseEnter={handleHexogonal2}
                    />
                    <Hexagonal 
                      background={'#F1F1F1'}  
                      hover={hover.webDevelopment }
                      image={(`${S3URL}/OurServices/Node.svg`)}
                      blurs={blurMode && !hover.webDevelopment }
                      onMouseEnter={handleHexagonal3}
                    />
                    <Hexagonal 
                      title={"Web Development"} 
                      background={ hover.webDevelopment ? '#ffffff':'#F1F1F1'} 
                      hover={hover.webDevelopment} 
                      onMouseEnter={webDevelopmentMouseEnter}
                      // OnMouseLeave={webDevelopmentMouseLeave}
                      blurs={blurMode && !hover.webDevelopment}
                      />
                  </div>

                  <div className='hexagonal-side'>
                    <Hexagonal 
                      background={'#F1F1F1'} 
                      blurs={blurMode && !hover.uiuxHover} 
                      image={`${S3URL}/OurServices/Figma.svg`} 
                      hover={hover.uiuxHover} 
                      onMouseEnter={handleHexgonal5}
                    />

                    <Hexagonal background={'#F1F1F1'}  blurs={blurMode}/>
                    <Hexagonal 
                      title={"Testing & QA"} 
                      background={ hover.testingQA ? '#ffffff':'#F1F1F1'} 
                      hover={hover.testingQA} 
                      onMouseEnter={testingQAMouseEnter}
                      // OnMouseLeave={testingQAMouseLeave}
                      blurs={blurMode && !hover.testingQA}
                    />
                    <Hexagonal 
                      background={'#F1F1F1'}
                      blurs={blurMode}  
                      hover={hover.webDevelopment || hover.appDevelopement}
                      image={hover.webDevelopment ? (`${S3URL}/OurServices/8.svg`) : hover.appDevelopement ? (`${S3URL}/OurServices/IOS.svg`) : "" }
                      onMouseEnter={handleHexagonal8}
                      />
                    <Hexagonal 
                      background={'#F1F1F1'}  
                      blurs={blurMode}  
                      hover={hover.webDevelopment || hover.appDevelopement}
                      image={hover.webDevelopment ? (`${S3URL}/OurServices/React.svg`) : hover.appDevelopement ? (`${S3URL}/OurServices/Andorid.svg`) : "" }
                      onMouseEnter={handleHexagonal9}
                    />
                  </div>

                <div className='hexagonal-side'>
                  <Hexagonal 
                    title={"Dedicated Team Development"} 
                    background={ hover.dedicatedTeamDevelopment ? '#ffffff':'#F1F1F1'} 
                    hover={hover.dedicatedTeamDevelopment} 
                    onMouseEnter={dedicatedTeamDevelopmentMouseEnter}
                    // OnMouseLeave={dedicatedTeamDevelopmentMouseLeave}
                    blurs={blurMode && !hover.dedicatedTeamDevelopment}
                  />
                  <Hexagonal background={'#F1F1F1'}  blurs={blurMode}/>
                  <Hexagonal 
                    background={'#F1F1F1'}  
                    blurs={blurMode}
                    hover={hover.appDevelopement}
                    image={hover.appDevelopement ? (`${S3URL}/OurServices/Flutter.svg`) : "" }
                    onMouseEnter={handleHexagonal12}
                  />
                  <Hexagonal 
                    title={"App Development"}
                    background={ hover.appDevelopement ? '#ffffff':'#F1F1F1'} 
                    hover={hover.appDevelopement} 
                    onMouseEnter={appDevelopementMouseEnter}
                    // OnMouseLeave={appDevelopementMouseLeave}
                    blurs={blurMode && !hover.appDevelopement}  
                  />
              </div>

              <div className='hexagonal-side'>
                <Hexagonal background={'#F1F1F1'}  blurs={blurMode}/>
                <Hexagonal 
                  title={"Software Development"} 
                  background={ hover.softwareDevelopement ? '#ffffff':'#F1F1F1'} 
                  hover={hover.softwareDevelopement} 
                  onMouseEnter={softwareDevelopementMouseEnter}
                  // OnMouseLeave={softwareDevelopementMouseLeave}
                  blurs={blurMode && !hover.softwareDevelopement}  
                  />
                <Hexagonal background={'#F1F1F1'}  blurs={blurMode}/>
                <Hexagonal 
                  background={'#F1F1F1'}  
                  blurs={blurMode}
                  hover={hover.appDevelopement}
                  image={hover.appDevelopement ? (`${S3URL}/OurServices/reactnative.svg`) : "" }
                  onMouseEnter={handleHexagonal17}
                 

                />
                <Hexagonal 
                  title={"Product Development"} 
                  background={ hover.productDevelopement ? '#ffffff':'#F1F1F1'} 
                  hover={hover.productDevelopement} 
                  onMouseEnter={productDevelopementMouseEnter}
                  // OnMouseLeave={productDevelopementMouseLeave}
                  blurs={blurMode && !hover.productDevelopement}  
                />
              </div>

              <div className='hexagonal-side'>
              <Hexagonal background={'#F1F1F1'}  blurs={blurMode}/>
              <Hexagonal 
                title={"Utility Development"} 
                background={ hover.utitlityDevelopement ? '#ffffff':'#F1F1F1'} 
                hover={hover.utitlityDevelopement} 
                onMouseEnter={utitlityDevelopementMouseEnter}
                // OnMouseLeave={utitlityDevelopementMouseLeave}
                blurs={blurMode && !hover.utitlityDevelopement}  

              />
            </div>

                </div>
            </div>
            
            <div className='content_mobile'>
                  <HexagonalMobile/>
            </div>

        </div>
    </section>
  )
}

export default Ourservices
