import React from 'react';

function LocationIcon() {
    return (
        <svg width="27" style = {{minWidth : '27px'}} height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.793 25.5721C11.793 25.5721 3.75391 16.7408 3.75391 10.9846C3.75391 4.19238 8.19766 2.62207 11.793 2.62207C15.1023 2.62207 19.3539 4.39863 19.3539 10.9846C19.3539 16.9283 11.793 25.5721 11.793 25.5721Z" stroke="white" strokeWidth="2" />
            <path d="M11.5 14C13.433 14 15 12.433 15 10.5C15 8.567 13.433 7 11.5 7C9.567 7 8 8.567 8 10.5C8 12.433 9.567 14 11.5 14Z" stroke="white" />
        </svg>

    );
}

export default LocationIcon;