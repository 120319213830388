import React from 'react'
import Imagedescription from '../../components/imagedescription/Imagedescription';
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';
import ProcessServices from '../../components/processServices/ProcessServices';
import Recentreviews from '../../components/recentReviews/Recentreviews';
import ContactForm from '../../components/contactus/ContactForm';

import mainImage from "../../assets/services/App Development/Main App.svg";

import Icons1 from "../../assets/services/App Development/Our Services/Frame 3466398.svg"
import Icons2 from "../../assets/services/App Development/Our Services/Frame 3466398-1.svg"
import Icons3 from "../../assets/services/App Development/Our Services/Frame 3466398-2.svg"

import processImage from '../../assets/services/processSection/appdevelopment1.png'


const ImageDescriptiondata = {
  title: "App Development",
  subtitle: "App Development",
  description: [
    ` The consumption of data has grown significantly due to the exponential rise in mobile users worldwide. Additionally, it has given business owners the chance to consider and carry out creative business concepts for the creation of apps. A group of mobile app developers at InFineIT Solutions has years of expertise building high-performing, feature-rich native and cross-platform mobile applications. For both iOS and Android devices, we use a thorough development process to produce strong apps that serve millions of people daily.`,
    `
    Our main areas of concentration as an app development business include increasing performance, employing appealing and seamless UI/UX designs, and reducing any security threats by abiding by best practice’s. We use a variety of APIs and data connectors to build the best possible mobile experience. Our team makes sure that we produce a solid, trustworthy, and flawlessly operating mobile app that achieves your business objectives.
    `,
  ],
  image: mainImage,
};
const ourServicesData = {
  subtitle: "What we Do",
  title: "Our Services",
  description:[
    `At InFineIT Services, we understand the importance of delivering high-quality App Development solutions that meet business needs. Our developers work closely with clients to understand their requirements, delivering customized Applications that are efficient, scalable, and reliable.
    `,`If you're looking for a trusted partner to help you harness the power of software technology, look no further. Contact us today to schedule a consultation and find out how we can help your business grow.`,
  ],
  button: {
    title: "Book A Free Consultation",
    url: "/contactus",
  },
  cardList : [
    {
      icon : Icons1,
      title : "Design & Prototyping of apps:",
      description : `Our mobile app development services have always placed a priority on creating visually stunning apps that are both engaging and user-friendly. Our mobile app design team strives to develop highly engaging, intuitive, and standardized user interfaces for various mobile platforms.`
    },
    {
      icon : Icons2,
      title : "Native App Development:",
      description : "Our team of skilled iOS and Android app developers will work with you to create custom, safe, and interesting mobile apps that will help you achieve your aims and goals. InFineIT Solutions is the ideal location if you're looking for a mobile application development firm."
    },
    {
      icon : Icons3,
      title : "Cross-Platform App Development:",
      description : "Our expertise in React Native, Flutter, and Xamarin technologies has allowed us to successfully develop cutting-edge, robust cross-platform hybrid apps for small, medium, and large businesses. Get unique mobile apps that are quick to market, affordable, and can reach a larger audience."
    },
  ]
};


const Applicationdevelopment = () => {
  return (
    <>
      <Imagedescription
        image={ImageDescriptiondata.image}
        title={ImageDescriptiondata.title}
        subtitle={ImageDescriptiondata.subtitle}
        description={ImageDescriptiondata.description}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
        isBackend={false}
        isFrontend={false}
        isTesting={false}
        isCloud={false}
        isDevops={false}

      />

      <GeneralOurServices 
        title={ourServicesData.title}
        subtitle={ourServicesData.subtitle}
        description={ourServicesData.description}
        buttonText={ourServicesData.button.title}
        buttonURl={ourServicesData.button.url}
        cartListItem={ourServicesData.cardList}
      />

      <ProcessServices
        title={'App Development Process'}
        img={processImage}
      />

      {/* <Recentreviews/> */}

      <ContactForm
        descriptionText={"Fill out the information below. To get more information about App <br/> Development and its services."}
      />
      
    </>
  );
}

export default Applicationdevelopment
