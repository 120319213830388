import React from 'react'
import cloudtools from "../../assets/services/Tools/Cloud.svg"

const Cloudtools = () => {
  return (
    <img src={cloudtools}/>

  )
}

export default Cloudtools
