import React from 'react'
import { S3URL } from '../../constant/common'

const BusinessFocusIcon = () => {
  return (
    <img src={`${S3URL}/completepackage/Business+Focus.svg`} alt='BusinessFocusIcon' />
  )
}

export default BusinessFocusIcon
