import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import xamarinSvg from '../../assets/TechnologiesSvg/xamarinSvg.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessXamarin from '../../assets/TechnologiesSvg/ourProcessXamarin.svg';
import HireUsNow from '../../components/HireUsNow/HireUsNow';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';


function Xamarin() {
  const xamarinData =
  {
    title: 'Xamarin',
    titleDescription: ['With the help of the cross-platform app development platform Xamarin, programmers can create native applications for different operating systems, including Android, iOS, and Windows, using a single codebase. This technology is commonly used to create both consumer- and enterprise-level applications and is based on the.NET framework.',

      'With Xamarin, programmers can create native applications for several platforms using the C# programming language, a well-known and popular language in the.NET ecosystem. It is the perfect answer for businesses that need to develop and deploy apps fast since it considerably decreases the time and effort needed to develop applications for numerous platforms.'],
    mainSvg: '',
  };

  const processDataXamarin = [
    `<span className="li-header">Single Codebase:</span> Xamarin allows developers to write code once and reuse it across multiple platforms, reducing the amount of time and effort required to develop applications for multiple platforms.`,

    `<span className="li-header">Familiar Language:</span> Xamarin uses the C# programming language, which is widely used in the .NET ecosystem, making it easier for developers to get started with Xamarin development.`,

    `<span className="li-header">Seamless Integration:</span> Xamarin integrates seamlessly with popular tools and services, such as Visual Studio and Azure, allowing developers to streamline their workflows.`,

    `<span className="li-header">Security:</span> Xamarin is a highly secure platform that provides advanced security features and APIs, making it ideal for businesses that need to build applications that handle sensitive data.`,

    `<span className="li-header">Cost-Effective:</span> Xamarin's ability to share code across multiple platforms can result in significant time and cost savings, making it a cost-effective solution for businesses of all sizes.`,

    `<span className="li-header">Reduced Costs:</span> Figma's cloud-based platform eliminates the need for expensive hardware and software, reducing costs and improving affordability.`,
  ];


  const GeneralOurServicesData = [
    {
      icon: null,
      title: "Custom App Development",
      description: null
    },
    {
      icon: null,
      title: "App Modernization",
      description: null
    },
    {
      icon: null,
      title: "API Development & Integration",
      description: null
    },
    {
      icon: null,
      title: "Custom UI/UX Design",
      description: null
    },
    {
      icon: null,
      title: "Maintenance & Support",
      description: null
    }
  ];

  return (
    <div className="container-technology">
      <Imagedescription
        image={xamarinSvg}
        title={xamarinData.title}
        subtitle={xamarinData.title}
        description={xamarinData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={'Xamarin Development Services'}
        subtitle={'Services'}
        description={[`Our IT company provides professional Xamarin development services to help clients build high-quality, cross-platform mobile applications. Our team of experienced Xamarin developers has a deep understanding of the framework and is dedicated to delivering innovative solutions that meet the specific needs of our clients.`]}
        buttonText={null}
        buttonURl={null}
        cartListItem={GeneralOurServicesData}
      />

      <OurProcedure
        heading="XAMARIN DEVELOPMENT"
        subHeading="Why Choose Xamarin?"
        mainSvg={ourProcessXamarin}
        procedureData={processDataXamarin}
        endText={`           
            In conclusion, Xamarin offers businesses a powerful, cost-effective, and efficient solution for developing cross-platform applications, providing a competitive advantage in today's digital landscape.`}
        processDescription={false}
      />

      <HireUsNow
        techName='Xamarin'
      />
    </div>
  );
}

export default Xamarin;