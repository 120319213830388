import React, { useState, useEffect } from "react";
import data from "./data";
import Pinmaterialleft from "../svg/Pinmaterialleft";
import "./toolsmobile.scss"
import Pinmaterialright from "../svg/Pinmaterialright";

const Toolsmobile = () => {
    const [count, setCount] = useState(0);

    useEffect(() => {
      const handleIncrease = () => {
        if (count < data.length - 1) {
          setCount((preve) => preve + 1);
        } else {
          setCount(0);
        }
      };
      const interval = setInterval(() => {
        handleIncrease();
      }, 3000);
      return () => clearInterval(interval);
    }, [count]);
    return (
      <div className="technologiesMobileCard">
        <div className="boxContainer">

          <div className="box">
              <span className="pin-material">
              <Pinmaterialleft />
            </span>
            <span className="pin-material-botoom">
                <Pinmaterialright />
              </span>
            {data.map((el, index) => {
              return (
                  <div className={`content ${count == index && "active"}`} key={el.heading+index}>
                    {el.image}
                  </div>
              );
            })}
          </div>
          
        </div>
      </div>
    );
  
}

export default Toolsmobile
