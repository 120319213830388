import React from 'react'

function Equal() {
    return (
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_671_7903)">
                <path d="M31.82 27.9504L23.65 30.6164C23.392 29.4984 22.446 28.3804 20.726 28.2944L18.834 28.2084C18.318 28.2084 17.974 28.5524 17.888 29.0684C17.888 29.5844 18.232 29.9284 18.748 30.0144L20.64 30.1004C21.586 30.1004 21.93 30.7024 21.93 31.1324C21.93 31.2184 21.93 31.3904 21.844 31.4764V31.5624C21.672 31.9064 21.242 32.0784 20.726 32.0784L14.534 32.5084C14.018 32.5084 13.674 32.9384 13.674 33.4544C13.674 33.9704 14.104 34.3144 14.62 34.3144L20.812 33.8844C21.93 33.7984 22.704 33.2824 23.134 32.7664L32.164 29.8424C33.11 29.5844 34.744 29.3264 35.174 30.5304C35.174 30.6164 35.088 30.7884 34.916 30.8744L23.65 36.2924C21.758 36.9804 17.544 37.3244 14.104 37.4104C12.642 37.4964 11.18 37.5824 10.148 37.7544C10.062 37.7544 9.976 37.7544 9.89 37.8404L1.634 41.1084V34.0564H4.988C5.504 34.0564 5.848 33.6264 5.848 33.1964C5.848 32.1644 6.278 31.3044 6.88 30.5304C7.568 29.8424 8.342 29.4984 9.288 29.5844L11.18 29.6704C11.696 29.6704 12.04 29.3264 12.126 28.8104C12.126 28.2944 11.782 27.9504 11.266 27.8644L9.46 27.7784C8.17 27.6924 6.794 28.2084 5.848 29.1544C4.988 30.0144 4.472 30.9604 4.3 32.1644H0.86C0.344 32.1644 0 32.5944 0 33.0244V42.1404C0 42.3984 0.172 42.7424 0.43 42.8284C0.516 42.9144 0.688 43.0004 0.86 43.0004C0.946 43.0004 1.118 43.0004 1.204 42.9144L10.664 39.0444C11.696 38.9584 13.072 38.8724 14.448 38.7004C18.146 38.4424 22.274 38.1844 24.424 37.4104H24.51L35.776 31.9924C36.894 31.3904 37.066 30.3584 36.808 29.5844C36.12 27.8644 34.314 27.2624 31.82 27.9504Z" fill="#D1AB4A" />
                <path d="M42.656 0.171786C42.398 -0.000213658 42.14 -0.000213658 41.882 0.0857863L32.336 3.95579C31.304 4.04179 29.928 4.12779 28.552 4.29979C24.854 4.47179 20.64 4.81579 18.576 5.50379H18.49L7.31004 11.0078C6.19204 11.6098 6.02004 12.6418 6.19204 13.4158C6.70804 14.6198 7.74004 15.3078 9.37404 15.3078C9.97604 15.3078 10.492 15.2218 11.266 15.0498L19.35 12.3838C19.608 13.5018 20.554 14.6198 22.274 14.7058L33.54 15.2218C34.83 15.3078 36.206 14.7918 37.152 13.8458C38.012 12.9858 38.528 12.0398 38.7 10.8358H42.14C42.656 10.8358 43 10.4058 43 9.97579V0.859786C43 0.601786 42.828 0.343786 42.656 0.171786ZM41.366 9.20179H38.012C37.496 9.20179 37.152 9.63179 37.152 10.0618C37.152 11.0938 36.722 11.9538 36.12 12.7278C35.432 13.4158 34.658 13.7598 33.712 13.6738L22.36 13.0718C21.414 13.0718 21.07 12.4698 21.07 11.9538C21.07 11.8678 21.07 11.6958 21.156 11.6098V11.5238C21.328 11.1798 21.758 11.0078 22.274 11.0078L28.466 10.5778C28.982 10.5778 29.326 10.1478 29.326 9.63179C29.326 9.11579 28.896 8.77179 28.38 8.77179L22.188 9.20179C21.07 9.28779 20.296 9.80379 19.866 10.3198L10.836 13.2438C9.89004 13.5018 8.25604 13.7598 7.82604 12.5558C7.82604 12.4698 7.91204 12.2978 8.08404 12.2118L19.35 6.79379C21.242 6.10579 25.456 5.76179 28.896 5.67579C30.358 5.58979 31.82 5.50379 32.852 5.33179C32.938 5.33179 33.024 5.33179 33.11 5.24579L41.366 1.89179V9.20179Z" fill="#D1AB4A" />
                <path d="M21.2419 27.0901C21.3279 27.0901 21.4139 27.0901 21.4999 27.0901C21.5859 27.0901 21.6719 27.0901 21.7579 27.0901C23.3919 26.6601 27.0039 23.7361 27.7779 20.7261C28.2079 19.2641 27.8639 17.9741 26.8319 17.0281C25.4559 15.6521 23.1339 15.6521 21.5859 17.0281C21.4139 17.2001 21.3279 17.0281 21.2419 17.0281C19.8659 15.6521 17.5439 15.6521 15.9959 17.0281C14.9639 18.0601 14.7059 19.3501 15.0499 20.7261C16.0819 23.7361 19.6079 26.6601 21.2419 27.0901ZM17.3719 18.1461C17.8019 17.7161 18.3179 17.5441 18.7479 17.5441C19.1779 17.5441 19.7799 17.7161 20.1239 18.1461C20.8979 18.9201 22.0159 18.9201 22.7899 18.1461C23.5639 17.3721 24.8539 17.3721 25.6279 18.1461C26.2299 18.7481 26.4019 19.3501 26.1439 20.2961C25.5419 22.5321 22.7899 24.9401 21.4999 25.4561C20.2959 24.9401 17.4579 22.5321 16.8559 20.2961C16.5979 19.4361 16.7699 18.6621 17.3719 18.1461Z" fill="#D1AB4A" />
                <path d="M14.2773 28.8963C14.2773 29.4123 14.7074 29.7564 15.1375 29.7564C15.6536 29.7564 15.9976 29.3263 15.9976 28.8963C15.9976 28.3802 15.5675 28.0361 15.1375 28.0361C14.6214 28.0361 14.2773 28.3802 14.2773 28.8963Z" fill="#D1AB4A" />
            </g>
            <defs>
                <clipPath id="clip0_671_7903">
                    <rect width="43" height="43" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Equal