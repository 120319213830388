import React, { useState, useEffect } from "react";
import data from "./data";
import Pinmaterialleft from "../svg/Pinmaterialleft";
import "./completePackageMobileCard.scss"
import Pinmaterialright from "../svg/Pinmaterialright";

const CompletePackageMobileCard = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const handleIncrease = () => {
      if (count < data.length - 1) {
        setCount((preve) => preve + 1);
      } else {
        setCount(0);
      }
    };
    const interval = setInterval(() => {
      handleIncrease();
    }, 3000);
    return () => clearInterval(interval);
  }, [count]);
  return (
    <div className="completePackageMobileCard">
      <div className="boxContainer">
        <span className="pin-material">
          <Pinmaterialleft />
        </span>
        <span className="pin-material-botoom">
            <Pinmaterialright />
          </span>
        <div className="box">
          {data.map((el, index) => {
            return (
                <div className={`content ${count == index && "active"}`} key={el.heading+index}>
                  <div className="icons">{el.icon}</div>
                  <div className="heading">{el.heading}</div>
                  <p>{el.desc}</p>
                </div>
            );
          })}

          <span className="border-top-left"></span>
          <span className="border-top-right"></span>
          <span className="border-bottom-right"></span>
          <span className="border-bottom-left"></span>

        </div>
      </div>
    </div>
  );
};

export default CompletePackageMobileCard;
