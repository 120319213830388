import React, { useState } from 'react';
import './dashboard.scss';
import DashboardSidebar from '../../components/DashboardSidebar/DashboardSidebar';
import DashboardMain from '../../components/DashboardMain/DashboardMain';

function Dashboard() {

    const [selectedTab, setSelectedTab] = useState('info');

    //Lifting Selection State Up
    const handleSelectionState = (value) => {
        setSelectedTab(value);
    }

    return (
        <div className='container-dashboard'>
            <div className='dashboard-left'>
                <DashboardSidebar handleSelectionState={handleSelectionState} />
            </div>

            <div className='dashboard-right'>
                <DashboardMain selectedTab={selectedTab} />
            </div>
        </div>
    )
};

export default Dashboard;