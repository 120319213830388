import React from 'react';
import './contactus.scss';
import ContactForm from "../../components/contactus/ContactForm";

function ContactUs(){
    return(
    <div className = "ContactUs-Container">
        <ContactForm descriptionText={`We'd love to hear from you! Whether you have a question, suggestion, or <br /> just want to say hello, we're here to help.`}/>
    </div>);
}

export default ContactUs;