import React from 'react'
import databaseImage from "../../assets/services/Tools/database.svg"

const Databasetools = () => {
  return (
    <img src={databaseImage} loading='lazy'/>
  )
}

export default Databasetools
