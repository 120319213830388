import React from 'react'
import designImage from "../../assets/services/Tools/Designoing.svg"

const Designtools = () => {
  return (
    <img src={designImage}/>

  )
}

export default Designtools
