import { S3URL } from "../../constant/common";

const data = [
    {
        img : `${S3URL}/recentReviews/RameshJain.svg`,
        name : "Ramesh Jain",
        rating : 4,
        desc : `"The team at InFineIT was instrumental in launching our new website on time and within budget. Their expertise in web development and their commitment to delivering quality work was evident in every stage of the project."`
    },
    {
        img : `${S3URL}/recentReviews/DharmeshPatel.svg`,
        name : "Dharmesh Patel",
        rating : 3,
        desc : `"The team at InFineIT was instrumental in launching our new website on time and within budget. Their expertise in web development and their commitment to delivering quality work was evident in every stage of the project."`
    },
    {
        img : `${S3URL}/recentReviews/SteephenDsouza.svg`,
        name : "Steephen Dsouza",
        rating : 5,
        desc : `"The team at InFineIT was instrumental in launching our new website on time and within budget. Their expertise in web development and their commitment to delivering quality work was evident in every stage of the project."`
    },
    {
        img : `${S3URL}/recentReviews/KashishJoshi.svg`,
        name : "Kashish Joshi",
        rating : 4,
        desc : `"The team at InFineIT was instrumental in launching our new website on time and within budget. Their expertise in web development and their commitment to delivering quality work was evident in every stage of the project."`
    },
    {
        img : `${S3URL}/recentReviews/DimpleShah.svg`,
        name : "Dimple Shah",
        rating : 4,
        desc : `"The team at InFineIT was instrumental in launching our new website on time and within budget. Their expertise in web development and their commitment to delivering quality work was evident in every stage of the project."`
    },
    {
        img : `${S3URL}/recentReviews/MuskanSingh.svg`,
        name : "Muskan Singh",
        rating : 5,
        desc : `"The team at InFineIT was instrumental in launching our new website on time and within budget. Their expertise in web development and their commitment to delivering quality work was evident in every stage of the project."`
    },
]


export default data