import React from 'react';
import './employeebenefits.scss';
import Supportive from './Supportive';
import Equal from './Equal';
import Canteen from './Canteen';
import Leaders from './Leaders';
import Leadership from './Leadership';
import Transparent from './Transparent';
import Opportunities from './Opportunities';
import WorkLife from './WorkLife';
import Pinmaterialleft from '../../svg/Pinmaterialleft';
import BenefitsData from './BenefitsData';
import { useState, useEffect } from 'react';


function EmployeeBenefits() {

    const [count, setCount] = useState(0);

    useEffect(() => {
        const handleIncrease = () => {
            if (count < BenefitsData.length - 1) {
                setCount((preve) => preve + 1);
            } else {
                setCount(0);
            }
        };
        const interval = setInterval(() => {
            handleIncrease();
        }, 3000);
        return () => clearInterval(interval);
    }, [count]);

    return (
        <section className="container-employee-benefits">
            <div className="wrapper-employee-benefits">
                <div className="eb-row1">
                    <p>BENEFITS</p>
                    <h1>Employee Benefits</h1>
                </div>

                <div className="eb-row2">
                    <div className="row2-item1">
                        <div className="item1-wrapper">
                            <img src={require('../../../assets/OurTeam/team.png')} alt="HR" />
                            <p>INFINEIT TEAM</p>
                        </div>
                    </div>

                    <div className="row2-item2">
                        <ul>
                            <li>
                                <div class="wrapper-svg"><Equal /></div>
                                <p>Equal Opportunity</p>
                            </li>

                            <li>
                                <div class="wrapper-svg"><Supportive /></div>
                                <p>Supportive Environment</p>
                            </li>

                            <li>
                                <div id="work-life" class="wrapper-svg"><WorkLife /></div>
                                <p>A Healthy Work-Life Balance</p>
                            </li>

                            <li>
                                <div class="wrapper-svg"><Leadership /></div>
                                <p>Opportunities for<br /> Leadership & Impact</p>
                            </li>
                        </ul>

                        <ul>
                            <li>
                                <div class="wrapper-svg"><Leaders /></div>
                                <p>Leaders  Support</p>
                            </li>

                            <li>
                                <div class="wrapper-svg"><Canteen /></div>
                                <p>Canteen Facility</p>
                            </li>

                            <li>
                                <div id="transparent" class="wrapper-svg"><Transparent /></div>
                                <p>Transparent Evaluation</p>
                            </li>

                            <li>
                                <div class="wrapper-svg"><Opportunities /></div>
                                <p>Opportunities for<br /> Professional Growth</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="eb-row3-mobile">
                    {/* Tablet/Mobile View Here */}
                    <div className="completePackageMobileCard">
                        <div className="boxContainer">
                            <span className="pin-material">
                                <Pinmaterialleft />
                            </span>
                            <div className="box">
                                {BenefitsData.map((el, index) => {
                                    return (
                                        <>
                                            <div className={`content ${count == index && "active"}`}>
                                                <div className="icons">{el.icon}</div>
                                                <div className="heading">{el.heading}</div>
                                            </div>
                                        </>
                                    );
                                })}

                                <span className="border-top-left"></span>
                                <span className="border-top-right"></span>
                                <span className="border-bottom-right"></span>
                                <span className="border-bottom-left"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default EmployeeBenefits;