import Supportive from './Supportive';
import Equal from './Equal';
import Canteen from './Canteen';
import Leaders from './Leaders';
import Leadership from './Leadership';
import Transparent from './Transparent';
import Opportunities from './Opportunities';
import WorkLife from './WorkLife';

const BenefitsData = [
    {
        icon: <Equal />,
        heading: "Equal Opportunity",
    },
    {
        icon: <Leaders />,
        heading: "Leaders Support",
    },
    {
        icon: <Supportive />,
        heading: "Supportive Environment",
    },
    {
        icon: <Canteen />,
        heading: "Canteen Facility",
    },
    {
        icon: <WorkLife />,
        heading: "A Healthy Work-Life Balance",
    },
    {
        icon: <Transparent />,
        heading: "Transparent Evaluation",
    },
    {
        icon: <Leadership />,
        heading: "Opportunities for Leadership & Impact",
    },
    {
        icon: <Opportunities />,
        heading: "Opportunities for Professional Growth",
    },
];

export default BenefitsData;