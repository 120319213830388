import React from 'react'

import Imagedescription from '../../components/imagedescription/Imagedescription';
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';
import ProcessServices from '../../components/processServices/ProcessServices';
import Recentreviews from '../../components/recentReviews/Recentreviews';
import ContactForm from '../../components/contactus/ContactForm';

import mainImage from "../../assets/services/UI UX designing/Main UI UX.svg";

import Icons1 from "../../assets/services/UI UX designing/Our Services/Frame 3466398.svg"
import Icons2 from "../../assets/services/UI UX designing/Our Services/Frame 3466398-1.svg"
import Icons3 from "../../assets/services/UI UX designing/Our Services/Frame 3466398-2.svg"
import Icons4 from "../../assets/services/UI UX designing/Our Services/Frame 3466398-3.svg"
import Icons5 from "../../assets/services/UI UX designing/Our Services/Frame 3466398-4.svg"
import Icons6 from "../../assets/services/UI UX designing/Our Services/Frame 3466398-5.svg"
import Icons7 from "../../assets/services/UI UX designing/Our Services/Frame 3466398-6.svg"

import processImage from '../../assets/services/processSection/uiux.png'

const ImageDescriptiondata = {
  title: "UI/UX Designing",
  subtitle: "UI/UX Designing",
  description: [
    ` At InFineIT Services, we understand the importance of creating user-friendly and visually appealing software and digital products. That’s why we offer a comprehensive range of UI/UX design services to help our clients achieve their goals and improve their customers’ experience.`,
    `Our team of experienced designers uses a user-centered approach to design, focusing on the needs and goals of the target audience. We conduct thorough research, create prototypes, test our designs, and iterate until we achieve the desired results. Our goal is to create intuitive and aesthetically pleasing interfaces that make user’s experience seamless and enjoyable.`
  ],
  image: mainImage,
};
const ourServicesData = {
  subtitle: "What we Do",
  title: "Our Services",
  description: [
    `We come up with ideas, plan ahead, and suggest digital design concepts for online and mobile applications. Our strategy, which is based on extensive market experience, effectively combines the correct technology, functionally engaging and eye-catching designs with content to build well-known brand identities for businesses. Contact us today to learn more about our UI/UX Designing services and how we can help you grow your business online.`
  ],
  button: {
    title: "Book A Free Consultation",
    url: "/contactus",
  },
  cardList: [
    {
      icon: Icons1,
      title: " User Research: ",
      description: `We gather and analyze data to better understand your users and their needs, behaviors, and pain points, in order to create digital products that meet their expectations.`
    },
    {
      icon: Icons2,
      title: "User Experience (UX) Development:",
      description: "A comprehensive strategy that considers all features and options in order to track end-user activity and make additional improvements. We do in-depth analyses of fundamental aspects and employ the rendering of visuals as necessary to achieve business objectives."
    },
    {
      icon: Icons3,
      title: "User Interface (UI) Development:",
      description: "Increase revenue and business profitability by attracting more clients with alluring web and application designs. We transform your concepts into fluid, practical designs after carefully considering information architecture."
    },
    {
      icon: Icons4,
      title: "Information Architecture Designing:",
      description: `On the web or in mobile apps, organize, structure, and identify the content in accordance with the business's theme. resolve navigational, content management, and system structure concerns after a thorough examination of the demands of the customer, then suggest the option that would work best for the company's objectives.`
    },
    {
      icon: Icons5,
      title: "Wireframing:",
      description: "Organize, categorize, and identify the information in accordance with the concept of the company on the web or in mobile apps. After carefully analyzing the customer's requirements, address navigational, content management, and system structure issues, and then provide the solution that would be most beneficial to the company's goals."
    },
    {
      icon: Icons6,
      title: "Prototyping:",
      description: "Prototyping is the first step in conceptualizing a page's structure. Here, we optimize the page layout while showcasing carefully thought-out structural alignments and arrangements that will give users a responsive and intuitive digital experience."
    },
    {
      icon: Icons7,
      title: "Usability Testing:",
      description: "A product is put through usability and scenario testing to see if the market will accept it. We provide a full range of usability testing, including screen resolution tests, compatibility tests, crowd testing, and comparable tests, with a quicker turn-around time and affordable costs."
    },
  ]
};

const Uiuxdesign = () => {
  return (
    <>
      <Imagedescription
        image={ImageDescriptiondata.image}
        title={ImageDescriptiondata.title}
        subtitle={ImageDescriptiondata.subtitle}
        description={ImageDescriptiondata.description}
      />

      <Toolstouse
        isFrontend={false}
        isBackend={false}
        isDatabase={false}
        isMobility={false}
        isDesigning={false}
        isTesting={false}
        isDevops={false}
        isCloud={false}
      />

      <GeneralOurServices
        title={ourServicesData.title}
        subtitle={ourServicesData.subtitle}
        description={ourServicesData.description}
        buttonText={ourServicesData.button.title}
        buttonURl={ourServicesData.button.url}
        cartListItem={ourServicesData.cardList}
      />

      <ProcessServices
        title={'UI/UX Designing Process'}
        img={processImage}
      />

      {/* <Recentreviews /> */}

      <ContactForm
        descriptionText={"Fill out the information below. To get more information about Product <br/> Development and its services."}
      />

    </>
  );
}

export default Uiuxdesign
