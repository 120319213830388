import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import cloudSvg from '../../assets/TechnologiesSvg/cloud.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import ourProcessClouds from '../../assets/TechnologiesSvg/ourProcessClouds.svg';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import HireUsNow from '../../components/HireUsNow/HireUsNow';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';


function Cloud() {
  const cloudData =
  {
    title: 'DevOps & Cloud',
    titleDescription: ['As organizations depend more on technology to spur growth and maintain competitiveness, cloud and DevOps are two of the most important technologies in the IT sector today.',

      'In order to provide quicker innovation, adaptable resources, and scale economies, cloud computing refers to the supply of computing services—including servers, storage, databases, networking, software, analytics, and intelligence—over the internet. Cloud computing has completely changed how businesses store and access data, allowing them to scale up or down their resources as necessary, lower expenses, and boost productivity.',

      'Contrarily, DevOps is a collection of methods that fuses software development and IT operations to speed up the development life cycle and offer continuous delivery of high-quality software. It lets businesses to expand and deploy applications fast, adapt to changing consumer and market demands, and ultimately spur business expansion.'
    ],
    mainSvg: '',
  };

  const processDataCloud = [
    `<span className="li-header">Expertise and Experience:</span> Our team has years of experience working with a wide range of organizations, helping them successfully implement and manage cloud and DevOps solutions.`,

    `<span className="li-header">Tailored Solutions:</span> We deliver results-driven solutions that are tailored to meet the unique needs and goals of each of our clients.`,

    `<span className="li-header">Results-Driven Approach:</span> Our focus is on delivering measurable results that drive business success.`,

    `<span className="li-header">Collaborative Partnership:</span> We believe in a collaborative approach, working closely with our clients to achieve their goals.`,

    `<span className="li-header">Culture of Innovation:</span> We help our clients build a culture of collaboration and automation that drives innovation and growth.`,

    `<span className="li-header">Commitment to Success:</span> We are dedicated to helping our clients succeed with cloud and DevOps and are committed to their success.`
  ];

  const GeneralOurServicesData = [
    {
      icon: null,
      title: "Cloud Migration",
      description: null
    },
    {
      icon: null,
      title: "Cloud Infrastructure Managementt",
      description: null
    },
    {
      icon: null,
      title: "Cloud Security",
      description: null
    },
    {
      icon: null,
      title: "Cloud Cost Optimization",
      description: null
    },
    {
      icon: null,
      title: "CI/CD Pipeline Setup",
      description: null
    },
    {
      icon: null,
      title: "Application Performance Monitoring",
      description: null
    },
    {
      icon: null,
      title: "DevOps Culture Transformation",
      description: null
    },
    {
      icon: null,
      title: "Automated Testing",
      description: null
    }
  ];


  return (
    <div className="container-technology">
      <Imagedescription
        image={cloudSvg}
        title={cloudData.title}
        subtitle={cloudData.title}
        description={cloudData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={'Cloud & DevOPs Services'}
        subtitle={'Services'}
        description={[`At InFineIT, we have a deep understanding of the complexities and challenges involved in cloud and DevOps adoption. Our team of experts has years of experience working with a wide range of organizations, from small startups to large enterprises, to help them successfully implement and manage Cloud and DevOps solutions.`]}
        buttonText={null}
        buttonURl={null}
        cartListItem={GeneralOurServicesData}
      />

      <OurProcedure
        heading="CLOUD & DEVOPS"
        subHeading="Why choose Cloud & DevOps?"
        mainSvg={ourProcessClouds}
        procedureData={processDataCloud}
        endText={`
            Our team of Cloud & DevOps has extensive experience in developing custom solutions for a wide range of clients, from small businesses to large enterprises. Whether you're looking to manage cloud or DevOps services, our team has the expertise and experience to help you achieve your goals. Contact us today to learn more about how we can help you with your Cloud and DevOps needs.`}
        processDescription={false}
      />

      <HireUsNow
        techName='Cloud & DevOps'
      />
    </div>
  );
}

export default Cloud;