import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import pythonSvg from '../../assets/TechnologiesSvg/pythonSvg.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessPython from '../../assets/TechnologiesSvg/ourProcessPython.svg';
import HireUsNow from '../../components/HireUsNow/HireUsNow';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';


function Python() {
  const pythonData =
  {
    title: 'Python',
    titleDescription: [`Python is a popular, high-level programming language that has been widely adopted by businesses and organizations of all sizes for a variety of purposes. It's known for its simplicity and ease of use, which makes it a great choice for both experienced and novice programmers`,

      'At our IT company, we believe in the power of Python and have a team of experienced developers who are experts in delivering custom solutions that meet the specific needs of our clients. With years of experience in developing Python-based applications, our team is able to deliver high-quality solutions that are both robust and scalable."'],
    mainSvg: '',
  };

  const processDataPython = [
    `<span className="li-header">Easy to Learn and Use:</span> Python is known for its simplicity and ease of use, making it an excellent choice for businesses and organizations that are looking to build applications quickly and efficiently.`,

    `<span className="li-header">Versatile:</span> Python can be used for a wide range of applications, including web development, scientific computing, data analysis, and machine learning. This versatility makes it a great choice for businesses that need to solve a variety of problems.`,

    `<span className="li-header">Large Community of Developers:</span> Python has a large and active community of developers, which means that there is a wealth of knowledge and resources available to businesses and organizations that are using the language.`,

    `<span className="li-header">Robust Standard Library:</span> Python has a comprehensive standard library that provides a wide range of functionality, including file I/O, regular expressions, and Internet protocols. This makes it easier for businesses to add new features and functionality to their applications without having to write everything from scratch.`,

    `<span className="li-header">Support for Multiple Programming Paradigms:</span> Python supports multiple programming paradigms, including procedural, object-oriented, and functional programming. This makes it a great choice for businesses that need to develop applications using different approaches.`,

  ];

  const processDescription = `Some of the key benefits of using Python include:`;

  const GeneralOurServicesData = [
    {
      icon: null,
      title: "Custom Software development",
      description: null
    },
    {
      icon: null,
      title: "Web Application Development",
      description: null
    },
    {
      icon: null,
      title: "Data Analysis & Visualization",
      description: null
    },
    {
      icon: null,
      title: "Machine Learning",
      description: null
    }
  ];

  return (
    <div className="container-technology">
      <Imagedescription
        image={pythonSvg}
        title={pythonData.title}
        subtitle={pythonData.title}
        description={pythonData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={'Python Development Services'}
        subtitle={'Services'}
        description={[`Our IT company provides a variety of Python development services, including machine learning, data analysis and visualization, web application development, and custom software development. Our team of skilled developers can offer solutions that are optimized for performance, scalability, and reliability by utilizing all of Python's features.`]}
        buttonText={null}
        buttonURl={null}
        cartListItem={GeneralOurServicesData}
      />

      <OurProcedure
        heading="PYTHON DEVELOPMENT"
        subHeading="Why Choose Python?"
        mainSvg={ourProcessPython}
        procedureData={processDataPython}
        endText={`
            Our team of Python developers is dedicated to delivering high-quality, custom solutions that meet the specific needs of our clients. Whether you're looking to build a new application or modernize an existing one, our team has the expertise and experience to deliver a solution that meets your needs. Contact us today to learn more about how we can help you achieve your goals with Python.`}
        processDescription={processDescription}
      />

      <HireUsNow
        techName='Python'
      />
    </div>
  );
}

export default Python;