import React from 'react'
import "./subnavigation.scss"

const Subnavigation = ({children,onMouseLeave}) => {
  return (
    <div className='subnavigation_container' onMouseLeave={onMouseLeave} >
            {children}
    </div>
  )
}

export default Subnavigation
