import React from "react";
import "./servicenav.scss";
import { Link } from "react-router-dom";
import InfineItlogo from "../svg/InfineItlogo";
import scrollToTop from "../../helper/scrollToTop";

const Servicesnav = ({linkClickClose}) => {
  return (
    <div className="serviceNav">
      <div className="logo">
        <InfineItlogo />
      </div>

      {
        servicenavData.map((el,index) =>{
          return(
            <div className="navContainer" onClick={linkClickClose} key={el.title+index}>
            <Link to={`services/${el.link}`} className={"link"} onClick={()=>scrollToTop()}>
            <div className="heading">{el.title}</div>
            <ul className="subNav">
              {
                el.data.map((el,index) =>{
                  return(
                    <li key={el.title+index}>
                      {el.title}
                    </li>
                  )
                })
              }
            </ul>
            </Link>
          </div>
          )
        })
      }
    </div>
  );
};

export default Servicesnav;

const servicenavData = [
  {
    title : "Software Development",
    link : "software-development",
    data : [
      {
        title : "Bespoke Software Development"
      },
      {
        title : "Application Migration & re-engineering"
      },
      {
        title : "Software Application Maintence",
      },
      {
        title : "Saas Application"
      },
      {
        title : "Maintenance & Support"
      }
    ] 
  },
  {
    title : "Application Development",
    link : "application-development",
    data : [
      {
        title : 'Cross-Platform App Development'
      },
      {
        title : "Native App Development (iOS, Android)"
      },
      {
        title : 'Design & Prototyping of Apps'
      }
    ]
  },
  {
    title : "Web Development",
    link : "web-development",
    data : [
      {
        title : "Front End Web Development"
      },
      {
        title : "Back End Development"
      },
      {
        title : "SaaS Application Development"
      },
      {
        title : "Progressive Web Apps"
      },
      {
        title : "E-Commerce Web App Development"
      },
      {
        title : "Custom Web Application Development Services"
      },
    ]
  },
  {
    title : "Product Development",
    link : "product-development",
    data : [
      {
        title : "Prototyping & MVP Development"
      },
      {
        title : "Product Architecture Design Service"
      },
      {
        title : "UI/UX Design Service"
      },
      {
        title : "Product Development"
      },
      {
        title : "Product Testing"
      },
      {
        title : "Maintenance & Support Services"
      }
    ]
  },
  {
    title : "UI/UX Designing",
    link : "uiux-design",
    data : [
      {
        title : "User Research"
      },
      {
        title : "User Experience (UX) Development"
      },
      {
        title : "User Interface (UI) Development"
      },
      {
        title : "Information Architecture Designing"
      },
      {
        title : "Wireframing"
      },
      {
        title : "Prototyping"
      },
      {
        title : "Usability Testing"
      }
    ]
  },
  {
    title : "Dedicated Development Team ",
    link : "dedicated-development-team",
    data : [
      {
        title : "IT Staff Augmentation"
      },
      {
        title : "Dedicate Development Team"
      }
    ]
  },
  {
    title : "Testing & QA",
    link : "testing-qa",
    data : [
      {
        title : "Functional Testing"
      },
      {
        title : "Performance Testing"
      },
      {
        title : "Security Testing"
      },
      {
        title : "Usability Testing"
      },
      {
        title : "Compatibility Testing"
      },
      {
        title : "Mobile App Testing"
      }
    ]
  },
  {
    title : "Utility Development",
    link : "utility-development",
    data : [
      {
        title : "API Integration"
      },
      {
        title : "API Development"
      },
      {
        title : "Data Migration Tool"
      },
      {
        title : "Image Processing Tool"
      }
    ]
  }
]

export {servicenavData}
