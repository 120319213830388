import React from 'react';
import './ourhiringprocess.scss';
import HiringProcessMobile from '../../assets/OurHiringProcess/HiringProcessMobile';
import { S3URL } from '../../constant/common';

function OurHiringProcess() {
    return (
        <section className="container-hiring-process">
            <div className="wrapper-hiring-process">
                <div className="hiring-process-info">
                    <p>PROCESS</p>
                    <h1>Our Hiring Process</h1>
                </div>

                <div className="hiring-process-gif">
                    <img src={`${S3URL}/OurHiringProcess/OurProcessVideo.gif`} alt="Hiring Process" />
                </div>

                <div className = "mobile-hiring-process-svg">
                    <HiringProcessMobile />
                </div>
            </div>
        </section>
    );
}

export default OurHiringProcess;