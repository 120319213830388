import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import figmaSvg from '../../assets/TechnologiesSvg/figmaSvg.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessFigma from '../../assets/TechnologiesSvg/ourProcessFigma.svg';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';


function Figma() {
  const figmaData =
  {
    title: 'Figma',
    titleDescription: ['Figma is a cloud-based interface design tool that helps teams collaborate and design digital products more efficiently. It provides a powerful and user-friendly platform for creating and sharing UI designs, wireframes, prototypes, and more.',

      'Figma allows multiple team members to work on the same design file simultaneously, eliminating the need for version control and reducing the time and effort required to coordinate design changes. With Figma, teams can easily share their work with stakeholders and get real-time feedback, speeding up the design review process and reducing the need for revisions.'],
    mainSvg: '',
  };

  const processDataFigma = [
    `<span className="li-header">Improved Efficiency:</span> Figma's cloud-based platform and collaboration features allow teams to work together more efficiently and get designs done faster.`,

    `<span className="li-header">Increased Productivity:</span> Figma's intuitive interface and powerful tools make it easier for designers to create and refine designs, increasing productivity and reducing the need for revisions.`,

    `<span className="li-header">Enhanced Collaboration:</span> Figma's collaboration features allow designers, developers, and stakeholders to work together more effectively and get feedback in real-time, improving the design review process.`,

    `<span className="li-header">Seamless Integration:</span> Figma integrates with a variety of tools and platforms, making it easier for customers to move their work from design to development.`,

    `<span className="li-header">Improved Design Quality:</span> Figma's robust design tools and resources help designers create high-quality designs that meet the needs of their users.`,

    `<span className="li-header">Reduced Costs:</span>  Figma's cloud-based platform eliminates the need for expensive hardware and software, reducing costs and improving affordability.`,
  ];

  const processDescription = `Offering Figma development services through your website can provide several benefits to your customers, including:`;

  const GeneralOurServicesData = [
    {
      icon: null,
      title: "UI/UX Designing",
      description: null
    },
    {
      icon: null,
      title: "Prototype Development",
      description: null
    },
    {
      icon: null,
      title: "Integration Service",
      description: null
    },
    {
      icon: null,
      title: "Application Designing",
      description: null
    },
    {
      icon: null,
      title: "Website Designing",
      description: null
    },
    {
      icon: null,
      title: "Software designing",
      description: null
    }
  ];

  return (
    <div className="container-technology">
      <Imagedescription
        image={figmaSvg}
        title={figmaData.title}
        subtitle={figmaData.title}
        description={figmaData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={'Figma Service'}
        subtitle={'Services'}
        description={[`Our IT company provides professional Figma development services to help clients build high-quality, cross-platform mobile , Web, & Software Design. Our team of experienced Figma developers has a deep understanding of the Designing and is dedicated to delivering innovative solutions that meet the specific needs of our clients.`]}
        buttonText={null}
        buttonURl={null}
        cartListItem={GeneralOurServicesData}
      />

      <OurProcedure
        heading="FIGMA"
        subHeading="Why Choose Figma?"
        mainSvg={ourProcessFigma}
        procedureData={processDataFigma}
        endText={`
        By using Figma development services, we can help your customers take advantage of these benefits and improve their digital product development process. We can provide the support and expertise they need to get the most out of Figma and ensure the success of their projects.`}
        processDescription={processDescription}
      />

    </div>
  );
}

export default Figma;