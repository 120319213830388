import React from "react";
import "./imagedescription.scss";

const Imagedescription = ({ image ,subtitle,title,description, styleImage,listItem = []}) => {
  return (
    <section className = {`ImageDescription-container`}>
      <div className="container">
        <div className="left">
          <div className="subtitle">About {subtitle}</div>
          <h2 className="title">{title}</h2>
          {
            description.map((el,index) =>{
              return(
                <p key={title+index}>
                  {el}
                </p>
              )
            })
          }
          <ul>
          {
            listItem[0] && (
              listItem.map((el,index)=>{
                return(
                  <li>{el}</li>
                )
              }) 
            )
            
          }
          </ul>
        </div>
        <div className={`right ${styleImage}`}>
          <img src={image} />
        </div>
      </div>
    </section>
  );
};

export default Imagedescription;
