import React from 'react'
import mobilitytoolsImage from "../../assets/services/Tools/Mobility.svg"

const Mobilitytools = () => {
  return (
    <img src={mobilitytoolsImage} loading='lazy' />

  )
}

export default Mobilitytools
