import React from 'react'

function Transparent() {
    return (
        <svg width="43" height="43" viewBox="0 0 38 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_671_7952)">
                <path d="M25.7224 13.5016C25.6365 13.2006 25.3788 12.9856 25.0351 12.9426L21.3838 12.4266L19.7515 9.11558C19.6226 8.81458 19.3219 8.64258 18.9783 8.64258C18.6776 8.64258 18.3769 8.81458 18.205 9.11558L16.5727 12.4266L12.9214 12.9426C12.6207 12.9856 12.363 13.2006 12.2341 13.5016C12.1482 13.8026 12.2341 14.1466 12.4489 14.3616L15.1122 16.9416L14.5108 20.5966C14.4678 20.8976 14.5967 21.2416 14.8544 21.4136C14.9833 21.4996 15.1551 21.5856 15.327 21.5856C15.4558 21.5856 15.5847 21.5426 15.7136 21.4996L18.9783 19.7796L22.243 21.4996C22.5437 21.6286 22.8873 21.6286 23.1451 21.4566C23.4028 21.2846 23.5317 20.9406 23.4887 20.6396L22.8444 16.9846L25.4647 14.4046C25.7224 14.1466 25.8084 13.8026 25.7224 13.5016ZM21.3838 16.0386C21.1691 16.2106 21.0831 16.5116 21.1261 16.7696L21.5557 19.1776L19.4078 18.0596C19.1501 17.9306 18.8924 17.9306 18.6346 18.0596L16.4868 19.1776L16.9164 16.7696C16.9593 16.5116 16.8734 16.2106 16.6586 16.0386L14.9404 14.3616L17.3459 14.0176C17.6037 13.9746 17.8614 13.8026 17.9903 13.5446L19.0642 11.3516L20.1381 13.5446C20.267 13.8026 20.4817 13.9746 20.7824 14.0176L23.188 14.3616L21.3838 16.0386Z" fill="#D1AB4A" />
                <path d="M37.8369 36.894L30.9209 24.811C33.0258 22.188 34.2715 18.877 34.2715 15.265C34.2715 6.837 27.4415 0 19.022 0C10.6025 0 3.72946 6.837 3.72946 15.265C3.72946 18.877 4.9752 22.188 7.08007 24.811L0.164072 36.894C-0.0077542 37.152 0.0352023 37.496 0.207028 37.797C0.378854 38.055 0.67955 38.184 1.0232 38.141L6.43572 37.41L8.49764 42.484C8.62651 42.785 8.88424 43 9.2279 43H9.27085C9.57155 43 9.82929 42.828 10.0011 42.57L17.003 30.444C17.6474 30.53 18.3347 30.573 19.022 30.573C19.7093 30.573 20.3966 30.53 21.0409 30.444L28.0429 42.57C28.2147 42.828 28.4724 43 28.7731 43H28.8161C29.1168 43 29.4175 42.785 29.5463 42.484L31.6082 37.41L37.0208 38.141C37.3215 38.184 37.6651 38.055 37.8369 37.797C38.0088 37.539 38.0088 37.195 37.8369 36.894ZM9.35677 40.248L7.85329 36.55L9.2279 34.314C9.48564 33.927 9.35677 33.411 8.97016 33.153C8.58355 32.895 8.06807 33.024 7.81033 33.411L6.43572 35.647L2.48372 36.163L8.28285 26.144C10.1729 28.036 12.5785 29.412 15.2418 30.1L9.35677 40.248ZM19.022 28.896C11.5476 28.896 5.44772 22.79 5.44772 15.308C5.44772 7.826 11.5046 1.677 19.022 1.677C26.5394 1.677 32.5962 7.783 32.5962 15.265C32.5962 22.747 26.4964 28.896 19.022 28.896ZM31.5653 35.733L30.1907 33.497C29.9329 33.11 29.4175 32.981 29.0309 33.239C28.6442 33.497 28.5154 34.013 28.7731 34.4L30.1477 36.636L28.6442 40.334L22.7592 30.1C25.4225 29.412 27.8281 28.036 29.7182 26.144L35.5173 36.249L31.5653 35.733Z" fill="#D1AB4A" />
                <path d="M19.0213 4.38574C17.1313 4.38574 15.2841 4.85874 13.6947 5.76174C13.3081 5.97674 13.1363 6.49274 13.394 6.92274C13.6088 7.30974 14.1243 7.48174 14.5539 7.22374C15.9285 6.44974 17.4749 6.06274 19.0643 6.06274C24.1332 6.06274 28.3 10.1907 28.3 15.3077C28.3 16.6837 27.9993 18.0167 27.3979 19.2637C27.1831 19.6937 27.3549 20.1667 27.7845 20.3817C27.9133 20.4247 28.0422 20.4677 28.1281 20.4677C28.4288 20.4677 28.7295 20.2957 28.9013 19.9947C29.5886 18.5327 29.9753 16.9417 29.9753 15.3077C29.8893 9.24474 25.0353 4.38574 19.0213 4.38574Z" fill="#D1AB4A" />
                <path d="M26.7962 21.7148C26.4526 21.3708 25.9371 21.4138 25.5935 21.7148C25.2498 22.0588 25.2928 22.5748 25.5935 22.9188C25.7653 23.0908 25.9801 23.1768 26.1949 23.1768C26.4096 23.1768 26.6244 23.0908 26.7962 22.9188L26.8392 22.8758C27.1399 22.5748 27.1399 22.0158 26.7962 21.7148Z" fill="#D1AB4A" />
                <path d="M24.6056 23.6928C24.3908 23.3058 23.8754 23.1338 23.4458 23.3488C22.0712 24.0798 20.5677 24.5098 18.9783 24.5098C13.9095 24.5098 9.74267 20.3818 9.74267 15.2648C9.74267 13.9318 10.0004 12.6418 10.5588 11.4378C10.7307 11.0078 10.5588 10.5348 10.1293 10.3198C9.69972 10.1478 9.2272 10.3198 9.01241 10.7498C8.36807 12.1688 8.02441 13.6738 8.02441 15.2648C8.02441 21.2848 12.9215 26.1868 18.9354 26.1868C20.7825 26.1868 22.5867 25.7138 24.1761 24.8538C24.6486 24.6388 24.8204 24.1228 24.6056 23.6928Z" fill="#D1AB4A" />
                <path d="M12.1918 7.78314C11.8481 7.48214 11.3326 7.48214 10.989 7.82614C10.6883 8.17014 10.6883 8.68614 11.0319 9.03014C11.2038 9.15914 11.4185 9.24514 11.5904 9.24514C11.8051 9.24514 12.0199 9.15914 12.1918 8.98714C12.5784 8.64314 12.5354 8.12714 12.1918 7.78314Z" fill="#D1AB4A" />
            </g>
            <defs>
                <clipPath id="clip0_671_7952">
                    <rect width="38" height="43" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Transparent