import React from 'react'
import processImage from "../../assets/services/process_Section.png"
import "./processServices.scss"

const ProcessServices = ({title,img}) => {
    return (
        <section className='processSevices_Container'>
            <div className='container'>
                <div className="subtitle">Process</div>
                <div className="title">{title}</div>
                <div className='ImageContainer_img'>
                    <img src={img} className='img'/>
                </div>
            </div>
        </section>
    )
}

export default ProcessServices