import React from 'react';
import './footer.scss';
import EmailIcon from './EmailIcon';
import PhoneIcon from './PhoneIcon';
import LocationIcon from './LocationIcon';
import LinkedinIcon from './LinkedInIcon';
// import FbIcon from './FbIcon';
// import SkypeIcon from './SkypeIcon';
import WhatsAppIcon from './WhatsAppIcon';
// import TwitterIcon from './TwitterIcon';
// import InstagramIcon from './InstagramIcon';
import { Link } from 'react-router-dom';
import ScrolltopTop from '../scrolltoptop/ScrolltopTop';
import scrollToTop from '../../helper/scrollToTop';

function Footer() {
  return (

    <div className="footer-container">
      <div className="footer-wrapper">
        <footer>
          <div className="footer-flex-item1">
            <div className="flex-item1">

              <h3><span id="text-infine-golden">InFine</span>IT Global Services LLP</h3>
              <p>InFinite Possibilities</p>
              <div className="social-img-container">
                <Link to='https://www.linkedin.com/in/infineit' target="_blank" rel="noreferrer">
                  <LinkedinIcon />
                </Link>

                <Link to='https://api.whatsapp.com/send?phone=9825344145&text=Hello, I am interested in your services. When can we talk?' target="_blank" rel="noreferrer">
                  <WhatsAppIcon />
                </Link>

                {/* <FbIcon /> */}
                {/* <SkypeIcon /> */}
                {/* <TwitterIcon /> */}
                {/* <InstagramIcon /> */}
              </div>

            </div>

            <div className="flex-item2">
              <h3>Serivces</h3>
              <div className="text-underline"></div>
              <ul>
                <Link onClick={() => scrollToTop()} to={'/services/software-development'}><li>Software Development</li></Link>
                <Link onClick={() => scrollToTop()} to={'/services/application-development'}><li>App Development</li></Link>
                <Link onClick={() => scrollToTop()} to={'/services/web-development'}><li>Web Development</li></Link>
                <Link onClick={() => scrollToTop()} to={'/services/product-development'}><li>Product Development</li></Link>
                <Link onClick={() => scrollToTop()} to={'/services/uiux-design'}><li>UI/UX Designing</li></Link>
                <Link onClick={() => scrollToTop()} to={'/services/dedicated-development-team'}><li>Dedicated Development Team</li></Link>
                <Link onClick={() => scrollToTop()} to={'/services/testing-qa'}><li>Testing & QA</li></Link>
                <Link onClick={() => scrollToTop()} to={'/services/utility-development'}><li>Utility Development</li></Link>
              </ul>
            </div>

            <div className="flex-item3">
              <h3>Quick Link</h3>
              <div className="text-underline"></div>
              <ul>
                <a href="#" onClick={(e) => { e.preventDefault() }}><li>Blogs & News</li></a>
                <a href="#" onClick={(e) => { e.preventDefault() }}><li>Privacy Policy</li></a>
                <a href="#" onClick={(e) => { e.preventDefault() }}><li>Terms & Conditions</li></a>
              </ul>
            </div>

            <div className="flex-item4">
              <h3>Contact Us</h3>
              <div className="text-underline"></div>
              <ul id="ul-style-remove">
                <li><PhoneIcon svgColor={'white'} /> +91-98253-44145</li>
                <li><EmailIcon /> Hr@infineit.in</li>
                <li><LocationIcon /> InFineIT Global Services LLP<br />
                  (Finestar Jewellery & Diamonds)<br />
                  Plot No. : N 13-20(N Road) Gujarat Hira Bourse,< br />
                  Ichhapore, Surat.
                  Pincode - 394510</li>
              </ul>
            </div>
          </div>
          <div className="footer-flex-item2">
            <p>Copyright &#169; InFineIT Global Services 2023. All Rights Reserved.</p>
            <div className="main-links">
              <Link onClick={() => scrollToTop()} to="/">Home</Link>
              {/* <Link to = "/services">Services</Link>
              <Link to = "/technologies">Technologies</Link> */}
              <Link onClick={() => scrollToTop()} to="/aboutus">About Us</Link>
              <Link onClick={() => scrollToTop()} to="/we-are-hiring">We Are Hiring</Link>
              <Link onClick={() => scrollToTop()} to="/contactus">Contact Us</Link>
            </div>
          </div>
        </footer>


        <div className='scrolltop'>
          <div className='container'>
            <ScrolltopTop />
          </div>
        </div>
      </div>


    </div>
  );
}

export default Footer
