import React from 'react'

const CancelIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25 25L15 15" stroke="#2B3342" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 25L25 15" stroke="#2B3342" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35Z" stroke="#2B3342" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  )
}

export default CancelIcon
