import React from 'react'
import "./technologies.scss"
import { Link, Outlet, useMatches } from 'react-router-dom'
import { technologiesData } from '../../components/Header/TechnologiesNav'
import scrollToTop from '../../helper/scrollToTop'

const Technologies = () => {
  const location = useMatches()

  return (
    <div>

      {/* subnavigation for the mobile version  */}
      {
        location[location.length - 1].pathname === "/technologies" && (
          <div className='navigationTechnology_Mobile container'>

            <div className="title">Technologies</div>
            <div className="subtitle">We work with</div>


            {
              technologiesData.map((el, index) => {
                return (
                  <section className='section_Navigation'>
                    <h4>{el.title}</h4>
                    <nav className='navigation'>
                      {
                        el.data.map((el1, index) => {
                          return (
                            <Link to={el1.sublink} className='box' onClick={()=>scrollToTop()}>
                              <img src={el1.img} />
                            </Link>
                          )
                        })
                      }
                    </nav>
                  </section>
                )
              })
            }
          </div>
        )
      }


      {/* display the mobile and desktop navigation all  */}
      <Outlet />
    </div>

  )
}

export default Technologies
