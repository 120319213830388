import React from 'react';

function QuoteIcon() {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.8535 24.2322H7.08268C7.24935 14.5032 9.16602 12.899 15.1452 9.35732C15.8327 8.94065 16.0618 8.06565 15.6452 7.35732C15.2493 6.66982 14.3535 6.44065 13.666 6.85732C6.62435 11.024 4.16602 13.5657 4.16602 25.6697V36.8989C4.16602 40.4614 7.06185 43.3364 10.6035 43.3364H16.8535C20.5202 43.3364 23.291 40.5656 23.291 36.8989V30.6489C23.291 27.0031 20.5202 24.2322 16.8535 24.2322Z" fill="#2B3342" />
            <path d="M39.3939 24.2322H29.623C29.7897 14.5032 31.7064 12.899 37.6855 9.35732C38.373 8.94065 38.6022 8.06565 38.1855 7.35732C37.7689 6.66982 36.8939 6.44065 36.1855 6.85732C29.1439 11.024 26.6855 13.5657 26.6855 25.6906V36.9197C26.6855 40.4822 29.5814 43.3572 33.123 43.3572H39.373C43.0397 43.3572 45.8105 40.5864 45.8105 36.9197V30.6697C45.8314 27.0031 43.0605 24.2322 39.3939 24.2322Z" fill="#2B3342" />
        </svg>

    );
}

export default QuoteIcon;