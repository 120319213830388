import Backendtools from "../svg/Backendtools"
import Frontendtools from "../svg/Frontendtools"
import Designingtools from "../svg/Designtools"
import Mobilitytools from "../svg/Mobilitytools"
import Databasetools from "../svg/Databasetools"
import Testingtools from "../svg/Testingtools"
import Cloudtools from "../svg/Cloudtools"
import Devopstools from "../svg/Devopstools"
import Technologiestools from "../svg/Technologiestools"
import Wireframeprototyptools from "../svg/Wireframeprototypetools"
import Visualdesigntools from "../svg/Visualdesigntools"
import Designasseststools from "../svg/Designassesttools" 

const data = [
    {
        image : <Backendtools/>,
        id : "backendtools"
    },
    {
        image : <Frontendtools/>,
        id : "frontendtools"
    },
    {
        image : <Designingtools/>,
        id : "designingtools"
    },
    {
        image : <Mobilitytools/>,
        id : "mobilitytools"
    },
    {
        image : <Databasetools/>,
        id : "databasetools"
    },
    {
        image : <Testingtools/>,
        id : "testingtools",
    },
    {
        image : <Cloudtools/>,
        id : "cloudtools"
    },
    {
        image : <Devopstools/>,
        id : "devopstools",
    },
    {
        image : <Technologiestools/>,
        id : "technologiestools"
    },
    {
        image : <Wireframeprototyptools/>,
        id : "wireframeprototypetools",
    },
    {
        image : <Visualdesigntools/>,
        id : "visualdesigntools",
    },
    {
        image : <Designasseststools/>,
        id : "designasseststools"
    },  
  ];


  export default data