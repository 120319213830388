import React from 'react';
import './ourprocedure.scss';
import { useEffect, useRef } from 'react';

function OurProcedure({ heading, subHeading, mainSvg, procedureData, endText, processDescription }) {
    const containerRef = useRef(null);

    useEffect(() => {
        if (window.location.pathname.includes('aboutus') && containerRef.current) {
            containerRef.current.style.backgroundImage = 'none';
        }
    }, [window.location.pathname]);

    return (
        <div className="container-our-procedure" ref={containerRef}>
            <div className="section-header">
                <p>{heading}</p>
                <h1>{subHeading}</h1>
            </div>

            <div className="aboutUs-section3">
                <div className="section3-part1">
                    <img src={mainSvg} alt={heading} />
                </div>

                <div className="section3-part2">
                    <p>{heading}</p>
                    <h1>{subHeading}</h1>
                    {processDescription && (<p id='process-description'>{processDescription}</p>)}
                    <ol>
                        {procedureData.map((item, index) => {
                            return (<li
                                key={index}
                                dangerouslySetInnerHTML={{ __html: item }}
                            >
                            </li>)
                        })}
                    </ol>

                    <p
                        dangerouslySetInnerHTML={{ __html: endText }}
                    >
                    </p>
                </div>
            </div>
        </div>
    )
}

export default OurProcedure