import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import reactSvg from '../../assets/TechnologiesSvg/ReactSvg.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessReactn from '../../assets/TechnologiesSvg/ourProcessReactn.svg';
import HireUsNow from '../../components/HireUsNow/HireUsNow';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';


function ReactNative() {
  const reactNativeData =
  {
    title: 'React Native',
    titleDescription: ['Using JavaScript and React, developers may create mobile applications using the open-source framework React Native. React Native offers a quick, effective, and affordable solution to create high-quality, cross-platform mobile apps by utilising a single codebase.',

      `Our IT company employs a group of skilled React Native developers who are committed to providing our customers with cutting-edge solutions. Our staff has the knowledge and experience to support you in achieving your objectives, whether you're wanting to create a brand-new React Native application or update an already-existing one.`],
    mainSvg: '',
  };

  const processDataReactN = [
    `<span className="li-header">Cross-platform Development:</span> React Native allows developers to build applications for both iOS and Android using a single codebase, reducing development time and costs.`,

    `<span className="li-header">Fast Performance:</span> React Native uses native components, providing fast, smooth, and responsive performance for users.`,

    `<span className="li-header">Robust Community:</span> React Native has a large, active community of developers who contribute to the framework and provide ongoing support and resources.`,

    `<span className="li-header">Reusable Code:</span> React Native enables developers to write code once and use it across multiple platforms, making it easy to maintain and update apps.`,

  ];

  const processDescription = `Some of the benefits of developing with React Native include:`;

  const GeneralOurServicesData = [
    {
      icon: null,
      title: "Custom App Development",
      description: null
    },
    {
      icon: null,
      title: "App Modernization",
      description: null
    },
    {
      icon: null,
      title: "API Development & Integration",
      description: null
    },
    {
      icon: null,
      title: "Custom UI/UX Design",
      description: null
    },
    {
      icon: null,
      title: "Maintenance & Support",
      description: null
    }
  ];

  return (
    <div className="container-technology">
      <Imagedescription
        image={reactSvg}
        title={reactNativeData.title}
        subtitle={reactNativeData.title}
        description={reactNativeData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={'React Native Development Services'}
        subtitle={'Services'}
        description={[`Our IT company provides a range of professional React Native development services to help clients build, maintain, and enhance their mobile applications. Our team of experienced React Native developers has a deep understanding of the framework and is dedicated to delivering high-quality, innovative solutions that meet the specific needs of our clients.`]}
        buttonText={null}
        buttonURl={null}
        cartListItem={GeneralOurServicesData}
      />

      <OurProcedure
        heading="REACT NATIVE DEVELOPMENT"
        subHeading="Why choose React Native?"
        mainSvg={ourProcessReactn}
        procedureData={processDataReactN}
        endText={`
            Our team of React Native developers has extensive experience in developing custom solutions for a wide range of clients, from small businesses to large enterprises. Whether you're looking to build a new app or modernize an existing one, our team has the expertise and experience to help you achieve your goals. Contact us today to learn more about how we can help you with your React Native development needs.`}
        processDescription={processDescription}
      />

      <HireUsNow
        techName='React Native'
      />
    </div>
  );
}

export default ReactNative;