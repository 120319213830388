import React, { useEffect, useState } from 'react'
import './dashboardmain.scss';
import { ReactComponent as ProfileSvg } from '../../assets/Dashboard/profile.svg';
import { ReactComponent as DeleteSvg } from '../../assets/Dashboard/delete.svg';
import { ReactComponent as EditSvg } from '../../assets/Dashboard/edit.svg';
import { Drawer, Form, Input, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function DashboardMain({ selectedTab }) {
    const [addDrawerOpen, setAddDrawerOpen] = useState(false);
    const [editDrawerOpen, setEditDrawerOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isResumeDeleteOpen, setIsResumeDeleteOpen] = useState(false);
    const [hiringData, setHiringData] = useState([]);
    const [hiringDeleteIndex, setHiringDeleteIndex] = useState(null);
    const [hiringInfoId, setHiringInfoId] = useState(null);
    const [deleteData, setDeleteData] = useState(null);
    const [resumeData, setResumeData] = useState([]);
    const [resumeDeleteId, setResumeDeleteId] = useState(null);
    const [currResumeDeleteData, setCurrResumeDeleteData] = useState({});

    const [form] = Form.useForm();
    const [formEdit] = Form.useForm();

    const navigate = useNavigate();

    const hiringInfoApi = async () => {
        try {
            const res = await fetch('https://tiny.diadna.com/admin/hiring/paginate', {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            });

            const resUp = await res.json();

            if (resUp.code === 'OK' && resUp.data.length) {
                setHiringData(resUp.data);
            }
            else {
                setHiringData([]);
            }

        } catch (e) {
            console.log(e);
        }
    }

    const addHiringInfoApi = async (reqBody) => {
        try {
            const res = await fetch('https://tiny.diadna.com/admin/add/banner', {
                method: 'POST',
                body: JSON.stringify(reqBody),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            });

            const resUp = await res.json();

            toast.success(`${resUp.msg}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            onAddEditCancel();
            hiringInfoApi();

        } catch (e) {
            console.log(e);
        }
    }

    const editHiringInfoApi = async (reqBody) => {
        try {
            const res = await fetch('https://tiny.diadna.com/admin/hiring/banner/update', {
                method: 'PUT',
                body: JSON.stringify({ ...reqBody, id: hiringInfoId }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            });

            const resUp = await res.json();
            toast.success(`${resUp.msg}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            onAddEditCancel();
            hiringInfoApi();

        } catch (e) {
            console.log(e);
        }
    }

    const deleteHiringInfoApi = async (reqBody) => {
        try {
            const res = await fetch('https://tiny.diadna.com/admin/hiring/banner/delete', {
                method: 'PUT',
                body: JSON.stringify({ id: reqBody }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            });

            const resUp = await res.json();

            toast.success(`${resUp.msg}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            setIsDeleteModalOpen(false);
            hiringInfoApi();

        } catch (e) {
            console.log(e);
        }
    }

    const resumeInfoApi = async () => {
        try {
            const res = await fetch('https://tiny.diadna.com/api/resume/paginate', {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            });

            const resUp = await res.json();

            //Resume Response Structure Update here
            if (resUp.code === 'OK' && resUp.data.length) {
                setResumeData(resUp.data);
            }
            else {
                setResumeData([]);
            }

        } catch (e) {
            console.log(e);
        }
    }

    const resumeDeleteApi = async () => {
        try {
            const res = await fetch('https://tiny.diadna.com/api/resume/delete', {
                method: 'PUT',
                body: JSON.stringify({ id: resumeDeleteId }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            });

            const resUp = await res.json();

            toast.success(`${resUp.msg}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });

            resumeInfoApi();

            setIsResumeDeleteOpen(false);
        } catch (e) {
            console.log(e);
        }
    }

    const resumeDownloadApi = async (reqId) => {
        const res = await fetch('https://tiny.diadna.com/api/download', {
            method: 'POST',
            body: JSON.stringify({ id: reqId }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const resUp = await res.json();

        //Generating Download Data
        // const blob = new Blob([resUp.data], { type: 'application/pdf' });
        // const url = URL.createObjectURL(blob);

        // const link = document.createElement('a');
        // link.href = url;
        // link.download = 'document.pdf'; // Set the default filename for download

        // // Trigger a click event on the link to initiate download
        // link.click();

        // // Clean up by revoking the URL
        // URL.revokeObjectURL(url);
    }

    //Form Submission Handlers
    const onFinish = (data) => {
        addHiringInfoApi(data);
    }

    const onEditFinish = (data) => {
        editHiringInfoApi(data);
    }

    //Form Cancel Handler
    const onAddEditCancel = () => {
        setAddDrawerOpen(false);
        setEditDrawerOpen(false);
        formEdit.resetFields();
        form.resetFields();
    }

    //Edit Form Handler
    const handleEdit = (index) => {
        setHiringInfoId(hiringData[index]._id);
        formEdit.setFieldsValue({ ...hiringData[index], requirements: hiringData[index].requirements.join('.'), responsibility: hiringData[index].responsibility.join('.') });
        setEditDrawerOpen(true);
    }

    //Delete Hiring Card Handler
    const handleHiringDeleteIndex = (index) => {
        setDeleteData(hiringData[index]);
        setHiringDeleteIndex(hiringData[index]._id);
        setIsDeleteModalOpen(true);
    }

    const handleHiringDelete = () => {
        deleteHiringInfoApi(hiringDeleteIndex);
    }

    const handleResuleDeleteId = (index) => {
        setResumeDeleteId(resumeData[index]._id);
        setCurrResumeDeleteData(resumeData[index]);
        setIsResumeDeleteOpen(true);
    }

    const handleResumeDelete = () => {
        resumeDeleteApi();
    }

    const handleSignOut = () => {
        localStorage.removeItem('username');
        navigate('/login-hr-dept');
    }

    const handleDownloadId = (index) => {
        resumeDownloadApi(resumeData[index]._id);
    }

    useEffect(() => {
        if (selectedTab === 'info') {
            hiringInfoApi();
        }
        else if (selectedTab === 'resumes') {
            resumeInfoApi();
        }
    }, [selectedTab]);

    return (
        <div className='main-dashboard'>
            <div className='section-info'>
                <div className='user-info'>
                    <ProfileSvg />
                    <p>{`Kajal Pandya`}</p>
                    <button onClick={handleSignOut}>Sign Out</button>
                </div>

                <div className='view-info'>
                    <p>{selectedTab === 'info' ? 'Hiring Positions' : 'Resumes'}</p>
                    {selectedTab === 'info' ? <button onClick={() => setAddDrawerOpen(true)}>+ Add New Card</button> : null}
                </div>
            </div>

            {selectedTab === 'info' ? <div className='container-info'>
                {
                    hiringData.length > 0 ?
                        hiringData.map((item, index) =>
                            <div className='info-box'>
                                <div className='hiring-details'>
                                    <h3>{item.jobRole}</h3>
                                    <div className='details-bottom'>
                                        <p>{`Job Type: `}<span>{item.jobType}</span></p>
                                        <p>{`Experience: `}<span>{item.experience}</span></p>
                                        <p>{`No. of Openings: `}<span>{item.noOfOpenings}</span></p>
                                    </div>
                                </div>

                                <div className='action-btns'>
                                    <DeleteSvg onClick={() => handleHiringDeleteIndex(index)} />
                                    <EditSvg onClick={() => handleEdit(index)} />
                                </div>

                            </div>
                        ) : <h2>No Data to Show!</h2>
                }
            </div> : null}
            {selectedTab === 'resumes' ? <div className='container-resumes'>
                {resumeData.length > 0 ?
                    resumeData.map((item, index) =>
                        <div className='candidate-info-box'>
                            <div className='candidate-info'>
                                <span className='item-box'>
                                    <span>Name:</span>
                                    <span>{item.name}</span>
                                </span>

                                <span className='item-box'>
                                    <span>Email:</span>
                                    <span>{item.email}</span>
                                </span>

                                <span className='item-box'>
                                    <span>Contact:</span>
                                    <span>{item.contact}</span>
                                </span>

                                {/* <span className='item-box'>
                                    <span>Resume:</span>
                                    <button onClick={() => handleDownloadId(index)}>{`Download`}</button>
                                </span> */}
                            </div>

                            <span>
                                <DeleteSvg onClick={() => handleResuleDeleteId(index)} />
                            </span>
                        </div>
                    ) : <h2>No Data to Show!</h2>
                }
            </div> : null}
            <Drawer title="Add New Position" onClose={() => onAddEditCancel()} open={addDrawerOpen} size="large">
                <div className='container-new-pos-draw'>
                    <Form
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        // onFinishFailed={}
                        autoComplete="off"
                        layout='vertical'
                        form={form}
                    >

                        <div className='outside-info'>
                            <h2>Outside Info</h2>
                            <div className='input-row-1'>
                                <Form.Item
                                    label="Job Role"
                                    name="jobRole"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Job Role!',
                                            pattern: /^[\s\S]*\S[\s\S]*$/
                                        },
                                    ]}
                                    key='jobRole'
                                >
                                    <Input classNames='' />
                                </Form.Item>

                                <Form.Item
                                    label="Job Type"
                                    name="jobType"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Job Type!',
                                            pattern: /^[\s\S]*\S[\s\S]*$/
                                        },
                                    ]}
                                    key='jobType'
                                >
                                    <Input classNames='' />
                                </Form.Item>
                            </div>

                            <div className='input-row-2'>
                                <Form.Item
                                    label="Experience"
                                    name="experience"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Experience!',
                                            pattern: /^[\s\S]*\S[\s\S]*$/
                                        },
                                    ]}
                                    key='experience'
                                >
                                    <Input classNames='' />
                                </Form.Item>

                                <Form.Item
                                    label="No. of Openings"
                                    name="noOfOpenings"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input No. of Openings!',
                                            pattern: /^[\s\S]*\S[\s\S]*$/
                                        },
                                    ]}
                                    key='noOfOpenings'
                                >
                                    <Input classNames='' />
                                </Form.Item>
                            </div>
                        </div>

                        <div className='inside-info'>
                            <h2>Inside Info</h2>
                            <div className='input-row-3'>
                                <Form.Item
                                    label="Job Location"
                                    name="jobLocation"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Job Location!',
                                            pattern: /^[\s\S]*\S[\s\S]*$/
                                        },
                                    ]}
                                    key='jobLocation'
                                >
                                    <Input classNames='' />
                                </Form.Item>
                            </div>

                            <div className='input-row-4'>
                                <Form.Item
                                    label="About the Role"
                                    name="aboutTheRole"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input info About the Role!',
                                            pattern: /^[\s\S]*\S[\s\S]*$/
                                        },
                                    ]}
                                    key='aboutTheRole'
                                >
                                    <TextArea autoSize={{ minRows: 5 }} classNames='' />
                                </Form.Item>

                                <div className='input-row-5'>
                                    <Form.Item
                                        label="Responsibility"
                                        name="responsibility"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input Job Responsibility!',
                                                pattern: /^[\s\S]*\S[\s\S]*$/
                                            },
                                        ]}
                                        key='responsibility'
                                    >
                                        <TextArea autoSize={{ minRows: 5 }} classNames='' />
                                    </Form.Item>
                                </div>

                                <div className='input-row-6'>
                                    <Form.Item
                                        label="Requirements"
                                        name="requirements"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input Job Requirements!',
                                                pattern: /^[\s\S]*\S[\s\S]*$/
                                            },
                                        ]}
                                        key='requirements'
                                    >
                                        <TextArea autoSize={{ minRows: 5 }} classNames='' />
                                    </Form.Item>
                                </div>

                                <div className='btn-draw'>
                                    <button type="reset" onClick={() => onAddEditCancel()}>Close</button>
                                    <button type="submit">Add</button>
                                </div>

                            </div>
                        </div>

                    </Form>
                </div>
            </Drawer>

            <Drawer title="Edit Position" onClose={() => onAddEditCancel()} open={editDrawerOpen} size="large">
                <div className='container-new-pos-draw'>
                    <Form
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onEditFinish}
                        // onFinishFailed={}
                        autoComplete="off"
                        layout='vertical'
                        form={formEdit}
                    >

                        <div className='outside-info'>
                            <h2>Outside Info</h2>
                            <div className='input-row-1'>
                                <Form.Item
                                    label="Job Role"
                                    name="jobRole"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Job Role!',
                                            pattern: /^[\s\S]*\S[\s\S]*$/
                                        },
                                    ]}
                                    key='jobRole'
                                >
                                    <Input classNames='' />
                                </Form.Item>

                                <Form.Item
                                    label="Job Type"
                                    name="jobType"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Job Type!',
                                            pattern: /^[\s\S]*\S[\s\S]*$/
                                        },
                                    ]}
                                    key='jobType'
                                >
                                    <Input classNames='' />
                                </Form.Item>
                            </div>

                            <div className='input-row-2'>
                                <Form.Item
                                    label="Experience"
                                    name="experience"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Experience!',
                                            pattern: /^[\s\S]*\S[\s\S]*$/
                                        },
                                    ]}
                                    key='experience'
                                >
                                    <Input classNames='' />
                                </Form.Item>

                                <Form.Item
                                    label="No. of Openings"
                                    name="noOfOpenings"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input No. of Openings!',
                                            pattern: /^[\s\S]*\S[\s\S]*$/
                                        },
                                    ]}
                                    key='noOfOpenings'
                                >
                                    <Input classNames='' />
                                </Form.Item>
                            </div>
                        </div>

                        <div className='inside-info'>
                            <h2>Inside Info</h2>
                            <div className='input-row-3'>
                                <Form.Item
                                    label="Job Location"
                                    name="jobLocation"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Job Location!',
                                            pattern: /^[\s\S]*\S[\s\S]*$/
                                        },
                                    ]}
                                    key='jobLocation'
                                >
                                    <Input classNames='' />
                                </Form.Item>
                            </div>

                            <div className='input-row-4'>
                                <Form.Item
                                    label="About the Role"
                                    name="aboutTheRole"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input info About the Role!',
                                            pattern: /^[\s\S]*\S[\s\S]*$/
                                        },
                                    ]}
                                    key='aboutTheRole'
                                >
                                    <TextArea autoSize={{ minRows: 5 }} classNames='' />
                                </Form.Item>

                                <div className='input-row-5'>
                                    <Form.Item
                                        label="Responsibility"
                                        name="responsibility"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input Job Responsibility!',
                                                pattern: /^[\s\S]*\S[\s\S]*$/
                                            },
                                        ]}
                                        key='responsibility'
                                    >
                                        <TextArea autoSize={{ minRows: 5 }} classNames='' />
                                    </Form.Item>
                                </div>

                                <div className='input-row-6'>
                                    <Form.Item
                                        label="Requirements"
                                        name="requirements"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input Job Requirements!',
                                                pattern: /^[\s\S]*\S[\s\S]*$/
                                            },
                                        ]}
                                        key='requirements'
                                    >
                                        <TextArea autoSize={{ minRows: 5 }} classNames='' />
                                    </Form.Item>
                                </div>

                                <div className='btn-draw'>
                                    <button type="reset" onClick={() => onAddEditCancel()}>Close</button>
                                    <button type="submit">Add</button>
                                </div>

                            </div>
                        </div>

                    </Form>
                </div>
            </Drawer>

            <Modal centered title="Delete Hiring Position" open={isDeleteModalOpen} onOk={() => handleHiringDelete()} onCancel={() => setIsDeleteModalOpen(false)}>
                <p>Delete Position: <b>{deleteData?.jobRole}</b>?</p>
            </Modal>

            <Modal centered title="Delete Resume" open={isResumeDeleteOpen} onOk={() => handleResumeDelete()} onCancel={() => setIsResumeDeleteOpen(false)}>
                <p>Delete Resume: <b>{currResumeDeleteData?.name}</b>?</p>
            </Modal>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                limit={0}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                theme="light"
            />
        </div>
    );
}

export default DashboardMain;