import React from 'react'

import Imagedescription from '../../components/imagedescription/Imagedescription';
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';
import ProcessServices from '../../components/processServices/ProcessServices';
import Recentreviews from '../../components/recentReviews/Recentreviews';
import ContactForm from '../../components/contactus/ContactForm';

import mainImage from "../../assets/services/Testing & QA/Testing & QA.svg";

import Icons1 from "../../assets/services/Testing & QA/Our Services/Frame 3466398.svg"
import Icons2 from "../../assets/services/Testing & QA/Our Services/Frame 3466398-1.svg"
import Icons3 from "../../assets/services/Testing & QA/Our Services/Frame 3466398-2.svg"
import Icons4 from "../../assets/services/Testing & QA/Our Services/Frame 3466398-3.svg"
import Icons5 from "../../assets/services/Testing & QA/Our Services/Frame 3466398-4.svg"
import Icons6 from "../../assets/services/Testing & QA/Our Services/Frame 3466398-5.svg"


import processImage from '../../assets/services/processSection/testing.png'

const ImageDescriptiondata = {
  title: "Testing & QA",
  subtitle: "Testing & QA",
  description: [
    `Software Testing and Quality Assurance (QA) play a crucial role in ensuring the reliability, compatibility, and performance of software products. They are essential components of the software development process and help to identify and resolve any issues before the product is released to the market.`
    ,
    `
    At InFineIT Solutions, we offer comprehensive software testing and QA services that are designed to meet the specific needs of our clients. Our team of experts is well-versed in the latest testing methodologies and tools and has extensive experience in software testing and QA.
    `
  ],
  image: mainImage,

};
const ourServicesData = {
  subtitle: "What we Do",
  title: "Our Services",
  description: [
    `Ensuring the quality and reliability of your software is crucial for success. Our team of experts provides comprehensive testing and quality assurance services to help you deliver high-quality products to your customers. Contact us today to learn more about our web development services and how we can help you grow your business online.`
  ],
  button: {
    title: "Book A Free Consultation",
    url: "/contactus",
  },
  cardList: [
    {
      icon: Icons1,
      title: "Functional Testing:",
      description: `Confirming that the software system complies with all functional requirements of the application produces the desired results. Functional testing is used by our skilled QA testing team to concentrate mostly on user interface, database, security, APIs, client or server application, and related functionalities of the programmes.`
    },
    {
      icon: Icons2,
      title: "Performance Testing:",
      description: "Performance testing can help you get rid of issues like instability, slow responsiveness, and longer load times. Our extensive performance testing expertise ensures faultless, dependable, and intelligent applications by carrying out quality checks and software behavior analysis under load and stress."
    },

    {
      icon: Icons3,
      title: "Security Testing:",
      description: `By enabling security testing with each release, you may identify potential flaws in your running application. A skilled group of test engineers who work on our QA testing services do penetration testing and risk assessments to protect apps from hackers and other malicious threats.`
    },
    {
      icon: Icons4,
      title: "Usability Testing:",
      description: "When several application functions are being used simultaneously, observe the behavior of the application. Our software testing services include a thorough examination of user behavior trends to spot any usability problems early on and produce a flawless end result."
    },

    {
      icon: Icons5,
      title: "Compatibility Testing:",
      description: `With InFineIT Solutions software testing services, you can investigate the possibilities of compatibility testing, where an application is painstakingly evaluated across a variety of browsers, databases, hardware, operating systems, server infrastructures, resolution displays, and devices.`
    },
    {
      icon: Icons6,
      title: "Mobile App Testing:",
      description: "A mobile app's three main building blocks are scalability, security, and support for multiple platforms. For a variety of mobile applications, InFineIT Solutions offers cross-platform, functional/non-functional testing using both human testing and automated testing methods."
    },
  ]
};

const Testingqa = () => {
  return (
    <>
      <Imagedescription
        image={ImageDescriptiondata.image}
        title={ImageDescriptiondata.title}
        subtitle={ImageDescriptiondata.subtitle}
        description={ImageDescriptiondata.description}
        listItem={ImageDescriptiondata.listItem}
      />

      <Toolstouse
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
        isBackend={false}
        isDatabase={false}
        isDesigning={false}
        isCloud={false}
        isDevops={false}
        isFrontend={false}
        isMobility={false}
        isTechnologies={false}
      />

      <GeneralOurServices
        title={ourServicesData.title}
        subtitle={ourServicesData.subtitle}
        description={ourServicesData.description}
        buttonText={ourServicesData.button.title}
        buttonURl={ourServicesData.button.url}
        cartListItem={ourServicesData.cardList}
      />

      <ProcessServices
        title={'Testing & QA Process'}
        img={processImage}
      />

      {/* <Recentreviews /> */}

      <ContactForm
        descriptionText={"Fill out the information below. To get more information about Testing & QA <br/> and its services."}
      />

    </>
  );
}

export default Testingqa
