import React, { useState } from 'react';
import './dashboardsidebar.scss';
import { ReactComponent as InfoSvg } from '../../assets/Dashboard/info.svg';
import { ReactComponent as GroupSvg } from '../../assets/Dashboard/Group.svg';
import { Link } from 'react-router-dom';

function DashboardTopInfo({ handleSelectionState }) {
    const [selectedTab, setSelectedTab] = useState('info');

    //Lifting Selection State Up
    const handleSelection = (e) => {
        if (e.currentTarget.lastChild.textContent === 'Hiring Info') {
            setSelectedTab('info');
            handleSelectionState('info');
        }
        else if (e.currentTarget.lastChild.textContent === 'Resumes') {
            setSelectedTab('resumes');
            handleSelectionState('resumes');
        }
    }

    return (
        <div className='container-dashboard-top'>
            <Link to='/'>
                <img src={require('../../assets/Dashboard/logoInfineit.png')} alt="Logo" />
            </Link>

            <ul>
                <li onClick={handleSelection} className={selectedTab === 'info' ? 'selectedTab' : undefined}>
                    <InfoSvg />
                    <p>Hiring Info</p>
                </li>
                <li onClick={handleSelection} className={selectedTab === 'resumes' ? 'selectedTab' : undefined}>
                    <GroupSvg />
                    <p>Resumes</p>
                </li>
            </ul>
        </div>
    );
}

export default DashboardTopInfo;