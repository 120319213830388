import React, { useEffect, useState } from "react";
import "./completepackage.scss";

import CompletePackageMobileCard from "./CompletePackageMobileCard";
import CompletePackageDesktop from "./CompletePackageDesktop";

import backgroundImage from "../../assets/completepackage/completepackage.svg"
import data from "./data"


const Completepackage = () => {
  


  return (
    <section className="completepackage">

    {/*
      <div className="backgroundImage">
         <img src={backgroundImage}  />
      </div>
  */}

      <div className="container">
        <p className="subtitle">WITH US, YOU GET</p>
        <h2 className="title">Complete package</h2>
          <CompletePackageMobileCard data={data}/>
          <CompletePackageDesktop data={data}/>
      </div>
    </section>
  );
};

export default Completepackage;
