import React, { useState } from "react";
import "./hero.scss";
import mobileFrame from "../../assets/home/iPhone.svg";
import mobileImage from "../../assets/home/mobile.svg";
import mobileCamera from "../../assets/home/Mobile-Camera.svg";

import { S3URL } from "../../constant/common";

const Hero = () => {


  return (
    <section className="hero" >

      <div className="backgroundVideo">
        <video src={require('../../assets/home/bg.mp4')} muted autoPlay loop></video>
      </div>


      <div className="container">
        <h1>The Technology Partner You Can Trust</h1>
        <div className="linear-animation-container">
          <p className="subtitle">We bring a together experience in </p>
          <ul className="linear-animation">
            <li>Software Development</li>
            <li>App Development</li>
            <li>Web Development</li>
            <li>Product Development</li>
            <li>UI/UX Development</li>
            <li>Dedicated Team Development</li>
            <li>Testing & QA</li>
            <li>Utility Development</li>
          </ul>
        </div>

        <div className="linear-mobile-animation-Container">
          {/* <li>Software Development</li> */}
          {/*     <li>App Development</li>
                <li>Web Development</li>
                <li>Product Development</li>
                <li>UI/UX Development</li>
                <li>Dedicated Team Development</li>
                <li>Testing & QA</li>
                <li>Utility Development</li>
            */}
          <ul className="linear-animation-mobile">
            <li>Software Development</li>
            <li>App Development</li>
            <li>Web Development</li>
            <li>Product Development</li>
            <li>UI/UX Development</li>
            <li>Dedicated Team Development</li>
            <li>Testing & QA</li>
            <li>Utility Development</li>
          </ul>
        </div>

        <div className="image-container">
          <div className="image-mobile">
            <img src={mobileFrame} loading="lazy" />
            <div className="img">
              <img src={mobileImage} loading="lazy" />

              <div className="camera">
                <img src={mobileCamera} loading="lazy" />
              </div>
            </div>
          </div>
          <div className="image-desktop">
            <img src={`${S3URL}/home/desktop.svg`} loading="lazy" />
            <div className="img">
              <img src={`${S3URL}/home/laptop.svg`} loading="lazy" />

              <div className="camera">
                <img src={`${S3URL}/home/laptop-Camer.svg`} loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
