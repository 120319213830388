import React from 'react'
import "./wearehiring.scss"
import JoinOurTeam from '../../components/JoinOurTeam/JoinOurTeam'
import OurVancancies from '../../components/ourvancancies/OurVancancies'
import OurHiringProcess from '../../components/OurHiringProcess/OurHiringProcess'
import EmployeeBenefits from '../../components/OurHiringProcess/EmployeeBenefits/EmployeeBenefits'
import ContactForm from '../contactus/ContactUs';

const Wearehiring = () => {
  return (
    <div className = "container-hiring">
      <JoinOurTeam />
      <OurVancancies />
      <OurHiringProcess />
      <EmployeeBenefits />
      <ContactForm/>
    </div>
  );
}

export default Wearehiring;
