import React from 'react'

const SimpleimplementIcon = () => {
  return (
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_29_3)">
    <path d="M44.5418 0.580078H13.4575C11.621 0.580078 10.127 2.07412 10.127 3.91054V54.0896C10.127 55.926 11.621 57.4201 13.4575 57.4201H44.5418C46.3783 57.4201 47.8722 55.926 47.8722 54.0896V3.91054C47.8722 2.07412 46.3783 0.580078 44.5418 0.580078ZM45.652 54.0896C45.652 54.7017 45.1539 55.1997 44.5418 55.1997H13.4575C12.8453 55.1997 12.3472 54.7017 12.3472 54.0896V47.8727H45.652V54.0896ZM45.652 45.6525H12.3472V12.3477H45.652V45.6525ZM45.652 10.1274H12.3472V3.91054C12.3472 3.29841 12.8453 2.80039 13.4575 2.80039H44.5418C45.1539 2.80039 45.652 3.29841 45.652 3.91054V10.1274Z" fill="#D1AB4A"/>
    <path d="M32.9926 20.2998C29.9319 20.2998 27.4417 22.7899 27.4417 25.8506V32.5115C27.4417 34.3458 25.9564 35.8419 24.1114 35.8419C22.2749 35.8419 20.7809 34.348 20.7809 32.5115C20.7809 31.8984 20.2837 31.4014 19.6707 31.4014C19.0577 31.4014 18.5605 31.8984 18.5605 32.5115C18.5605 35.5722 21.0506 38.0623 24.1114 38.0623C27.1748 38.0623 29.6621 35.5812 29.6621 32.5115V25.8506C29.6621 24.0141 31.1562 22.5202 32.9926 22.5202C34.8342 22.5202 36.323 24.0128 36.323 25.8506V28.0709C36.323 28.6841 36.8202 29.181 37.4332 29.181C38.0462 29.181 38.5434 28.6841 38.5434 28.0709V25.8506C38.5434 22.7861 36.0624 20.2998 32.9926 20.2998Z" fill="#D1AB4A"/>
    <path d="M32.1304 5.35352H25.4695C24.8566 5.35352 24.3594 5.85054 24.3594 6.46368C24.3594 7.07681 24.8566 7.57384 25.4695 7.57384H32.1304C32.7435 7.57384 33.2406 7.07681 33.2406 6.46368C33.2406 5.85054 32.7435 5.35352 32.1304 5.35352Z" fill="#D1AB4A"/>
    <path d="M33.4406 50.4258H24.5595C23.9464 50.4258 23.4492 50.9228 23.4492 51.5359C23.4492 52.1491 23.9464 52.6461 24.5595 52.6461H33.4406C34.0537 52.6461 34.5509 52.1491 34.5509 51.5359C34.5509 50.9228 34.0537 50.4258 33.4406 50.4258Z" fill="#D1AB4A"/>
    <path d="M37.4324 31.4014C36.8197 31.4014 36.3223 31.8988 36.3223 32.5115C36.3223 33.1243 36.8197 33.6217 37.4324 33.6217C38.0452 33.6217 38.5426 33.1243 38.5426 32.5115C38.5426 31.8988 38.0452 31.4014 37.4324 31.4014Z" fill="#D1AB4A"/>
    </g>
    <defs>
    <clipPath id="clip0_29_3">
    <rect width="58" height="58" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export default SimpleimplementIcon
