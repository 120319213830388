import React from 'react'
import visualdesigntoolsImage from "../../assets/services/Tools/visualdesign.svg"

const Visualdesigntools = () => {
  return (
    <img src={visualdesigntoolsImage} loading='lazy' />

  )
}

export default Visualdesigntools
