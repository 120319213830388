import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import vueSvg from '../../assets/TechnologiesSvg/VueSvg.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessVue from '../../assets/TechnologiesSvg/ourProcessVue.svg';
import HireUsNow from '../../components/HireUsNow/HireUsNow';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';

function VueJs() {
  const vueData =
  {
    title: 'VueJs',
    titleDescription: ['A well-liked open-source JavaScript framework called vue.js is used to create dynamic, user-friendly single-page applications. Since it is a progressive framework, it is an excellent option for companies of all sizes because it enables developers to construct applications incrementally.',

      'Our IT company employs a group of skilled Vue.js developers who are dedicated to providing our customers with high-quality solutions that are tailored to their individual demands. Our team can produce apps that are both useful and aesthetically pleasing thanks to a thorough understanding of the framework and its capabilities.'],
    mainSvg: '',
  };

  const processDataVue = [
    `<span className="li-header">Lightweight and Fast:</span> Vue.js is a lightweight framework that is designed to be fast and efficient, making it a great choice for businesses that need to build applications that perform well even on low-end devices.`,

    `<span className="li-header">Reactive and Computed Properties:</span> Vue.js provides reactive and computed properties that make it easier for developers to build dynamic, real-time applications that respond to changes in data.`,

    `<span className="li-header">Vue CLI:</span> The Vue CLI provides a set of tools and templates for building Vue.js applications, making it easier for businesses to get started with the framework.`,

    `<span className="li-header">Integration with Other Libraries and Tools:</span> Vue.js can be easily integrated with other libraries and tools, making it a great choice for businesses that need to build applications that integrate with existing systems.`,

    `<span className="li-header">Large Community of Developers:</span> Vue.js has a large and active community of developers, which means that there is a wealth of knowledge and resources available to businesses and organizations that are using the framework.`,

  ];

  const processDescription = `Some of the key benefits of using Vue.js for  include:`;

  const GeneralOurServicesData = [
    {
      icon: null,
      title: "Custom App Development",
      description: null
    },
    {
      icon: null,
      title: "Web App Modernization",
      description: null
    },
    {
      icon: null,
      title: "API Development & Integration",
      description: null
    },
    {
      icon: null,
      title: "Custom UI/UX Design using VUE.JS",
      description: null
    },
    {
      icon: null,
      title: "Staff Augmentation",
      description: null
    },
    {
      icon: null,
      title: "Maintenance & Support",
      description: null
    }
  ];
  return (
    <div className="container-technology">
      <Imagedescription
        image={vueSvg}
        title={vueData.title}
        subtitle={vueData.title}
        description={vueData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={'VUE.JS Development Services'}
        subtitle={'Services'}
        description={[`Our IT company provides a variety of Python development services, including machine learning, data analysis and visualization, web application development, and custom software development. Our team of skilled developers can offer solutions that are optimized for performance, scalability, and reliability by utilizing all of Python's features.`]}
        buttonText={null}
        buttonURl={null}
        cartListItem={GeneralOurServicesData}
      />

      <OurProcedure
        heading=" VUE.JS DEVELOPMENT"
        subHeading="Why Choose VUE.JS?"
        mainSvg={ourProcessVue}
        procedureData={processDataVue}
        endText={`           
            Our team of Vue.js developers is dedicated to delivering high-quality, custom solutions that meet the specific needs of our clients. Whether you're looking to build a new application or modernize an existing one, our team has the expertise and experience to deliver a solution that meets your needs. Contact us today to learn more about how we can help you achieve your goals with Vue.js.`}
        processDescription={processDescription}
      />

      <HireUsNow
        techName='VueJS'
      />

    </div>
  );
}

export default VueJs;