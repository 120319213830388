import React from 'react'
import devopstoolsImage from "../../assets/services/Tools/DevOps.svg"

const Devopstools = () => {
  return (
    <img src={devopstoolsImage} loading='lazy'/>

  )
}

export default Devopstools
