import React, { useState } from 'react'
import "./whychooseus.scss"
import { S3URL } from '../../constant/common'

const data = [
  {
    img : `${S3URL}/home/Abouts-US.svg`,
    title : "We believe in your quality and profitability",
    desc : `Our team is made up of experienced professionals who specialize in providing comprehensive business solutions for web development, mobile app     
            development, custom application development and software development, all with a service-oriented mindset. Over the years we have made it a priority 
            to provide our customers with innovative solutions with care and diligence. Today, it is safe to say that we are one of the best custom application 
            and web development companies out there!`,
  },
  {
    img : `${S3URL}/home/Our-Mission-2.svg`,
    title : "Mission",
    desc : `Our mission is to empower businesses and organizations of all sizes with innovative and reliable technology solutions that drive efficiency, 
            productivity, and growth. We strive to deliver exceptional customer service, cutting-edge technology, and unparalleled expertise to our clients,    
            enabling them to stay competitive in an ever-evolving digital landscape.`,
  },
  {
    img : `${S3URL}/home/Our-Vision.svg`,
    title : "Vision",
    desc : `Our vision is to be the leading provider of the innovative and reliable technology solutions that help businesses and organizations transform and   
            thrive in the digital age. We strive to be the go-to partner for businesses looking to stay ahead of the curve in technology and drive growth and   
            success in their operations. `,
  }
]

const Whychooseus = () => {
  const [display,setDisplay] = useState({
    img : data[0].img,
    desc : data[0].desc,
    title : data[0].title,
    active : 0
  })

  const handleActiveBtn = (active)=>{
    setDisplay({
      img : data[active].img,
      desc : data[active].desc,
      title : data[active].title,
      active : active,
    })
  }
  return (
    <section className='whychooseus_container'>
          <div className='mobile backgroundImage'>
            { display.active === 0  &&  <img src={`${S3URL}/home/Abouts-US.svg`} alt='Abouts-US' loading='lazy' />}
            { display.active === 1  &&  <img src={`${S3URL}/home/Our-Mission-2.svg` } alt='Our-Mission' loading='lazy' />}
            { display.active === 2  &&  <img src={`${S3URL}/home/Our-Vision.svg`} alt='Our-Vision.svg' loading='lazy' /> }
          </div>  


        <div className='container'>
          <p className='subtitle'>About US</p>
          <div className='top'>
            <h2 className='title'>Why Choose Us</h2>
            <div className='btn-container'>
              <button onClick={()=>handleActiveBtn(0)} className={display.active === 0 ? 'active' : ""}>About Us</button>
              <button onClick={()=>handleActiveBtn(1)} className={display.active === 1 ? 'active' : ""}>Our Mission</button>
              <button onClick={()=>handleActiveBtn(2)} className={display.active === 2 ? 'active' : ""}>Our Vision</button>
            </div>
          </div>

          <div className='content'>
            <div className='image-container'>
                <div className='image'>
                  <img src={display.img} loading='lazy'/>
                </div>
            </div>
            <div className='desc'>
              <div className='title-desc'>
              <h3>{display.title}</h3>
              <p>
                 {display.desc}
              </p>
              </div>
            </div>
          </div>
        </div>
    </section>
  )
}

export default Whychooseus
