import React from 'react'

const Moderntechnology = () => {
  return (
    <svg width="65" height="60" viewBox="0 0 65 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_29_3136)">
    <mask id="mask0_29_3136" style={{"maskType":"luminance"}} maskUnits="userSpaceOnUse" x="2" y="0" width="61" height="60">
    <path d="M62.5 0H2.5V60H62.5V0Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_29_3136)">
    <path d="M32.4767 60C27.3983 60 23.0078 53.4842 21.0234 42.9845L23.4766 42.5158C25.18 51.4842 28.7889 57.5002 32.4767 57.5002C37.2109 57.5002 42.4765 46.2031 42.4765 30C42.4765 13.7969 37.2109 2.49984 32.4767 2.49984C28.7889 2.49984 25.18 8.51578 23.4766 17.4842L21.0234 17.0155C23.0078 6.5158 27.3983 0 32.4767 0C39.4924 0 44.9764 13.1717 44.9764 30C44.9764 46.8283 39.4924 60 32.4767 60Z" fill="#D1AB4A"/>
    <path d="M15.3665 48.7498C10.1475 48.7498 7.94431 46.7654 7.0068 45.0936C3.58502 38.9999 12.0697 27.5777 26.335 19.1092C36.6635 12.9687 47.3037 10.0624 53.4291 11.703C55.554 12.2656 57.0697 13.3437 57.9444 14.906C58.4759 15.8435 58.7259 16.9215 58.7259 18.0933C58.7104 21.1402 57.4601 24.0937 55.0381 26.8746L53.1632 25.2341C55.2103 22.8903 56.2255 20.5465 56.2415 18.0778C56.2415 17.328 56.085 16.6716 55.7884 16.125C55.2569 15.1715 54.2571 14.4999 52.8038 14.109C47.3664 12.656 37.2572 15.5312 27.6163 21.2497C13.6476 29.5466 6.81958 39.6403 9.1789 43.8746C10.2881 45.859 13.1788 46.2184 15.4444 46.25C16.3818 46.25 17.2726 46.2344 18.4289 46.1405L18.6477 48.6249C17.3975 48.7342 16.4134 48.7498 15.4603 48.7498C15.4287 48.7498 15.3976 48.7498 15.3665 48.7498Z" fill="#D1AB4A"/>
    <path d="M49.5853 48.75C43.3665 48.75 34.7413 45.8908 26.3194 40.8906C12.0697 32.422 3.58507 20.9999 7.00686 14.9062C7.94435 13.2344 10.1631 11.25 15.3665 11.25H15.5381C16.6006 11.25 17.5226 11.2967 18.6166 11.375L18.4446 13.8748C17.3977 13.7969 16.5229 13.7655 15.5226 13.7655C13.2727 13.7969 10.3197 14.1408 9.19451 16.1407C6.81963 20.3595 13.6476 30.4532 27.6007 38.7502C37.2257 44.4686 47.3509 47.3438 52.7882 45.8908C54.2257 45.4998 55.2414 44.8283 55.7569 43.8748C56.0695 43.3127 56.2256 42.6251 56.2101 41.8593C56.179 39.9687 55.3508 34.9374 48.3978 29.0158L50.023 27.1092C55.4602 31.7345 58.6321 37.094 58.7259 41.8125C58.7415 43.0311 58.4915 44.1408 57.9445 45.0938C57.0698 46.6406 55.5541 47.7186 53.4291 48.2968C52.3039 48.5939 51.0071 48.75 49.5853 48.75Z" fill="#D1AB4A"/>
    <path d="M32.4749 36.2497C29.0216 36.2497 26.2246 33.4527 26.2246 29.9998C26.2246 26.547 29.0216 23.75 32.4749 23.75C35.9278 23.75 38.7248 26.547 38.7248 29.9998C38.7248 33.4527 35.9278 36.2497 32.4749 36.2497ZM32.4749 26.2498C30.4122 26.2498 28.7249 27.9371 28.7249 29.9998C28.7249 32.0626 30.4122 33.7498 32.4749 33.7498C34.5372 33.7498 36.2249 32.0626 36.2249 29.9998C36.2249 27.9371 34.5372 26.2498 32.4749 26.2498Z" fill="#D1AB4A"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_29_3136">
    <rect width="64" height="60" fill="white" transform="translate(0.5)"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export default Moderntechnology
