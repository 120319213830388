import React from 'react';

function LinkedinIcon() {
    return (
        <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_125_1153)">
                <path d="M5.88936 10.2399H2.8009C2.66398 10.2403 2.55321 10.3515 2.55321 10.4884V20.4099C2.55321 20.5476 2.66398 20.6584 2.8009 20.6584H5.88936C6.02658 20.6584 6.13782 20.5471 6.13782 20.4099V10.4884C6.13782 10.4225 6.11165 10.3593 6.06505 10.3127C6.01846 10.2661 5.95526 10.2399 5.88936 10.2399ZM4.34706 5.30762C3.22232 5.30804 2.31029 6.21904 2.30859 7.34377C2.31029 8.46851 3.22232 9.3795 4.34706 9.37993C5.47046 9.37823 6.38075 8.46795 6.38244 7.34454C6.38117 6.22023 5.46983 5.30923 4.34552 5.30839L4.34706 5.30762ZM13.7455 9.99377C12.5047 9.99377 11.5878 10.5268 11.0317 11.1322V10.4884C11.0317 10.3515 10.9209 10.2403 10.784 10.2399H7.82629C7.76039 10.2399 7.69719 10.2661 7.6506 10.3127C7.604 10.3593 7.57782 10.4225 7.57782 10.4884V20.4099C7.57782 20.5476 7.68859 20.6584 7.82629 20.6584H10.9078C11.0447 20.658 11.1555 20.5468 11.1555 20.4099V15.5015C11.1555 13.8468 11.6055 13.2022 12.7586 13.2022C14.014 13.2022 14.114 14.2353 14.114 15.5868V20.4099C14.114 20.5476 14.2247 20.6584 14.3624 20.6584H17.4447C17.582 20.6584 17.6932 20.5471 17.6932 20.4099V14.9676C17.6932 12.5084 17.224 9.99377 13.7455 9.99377Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_125_1153">
                    <rect width="20" height="26" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default LinkedinIcon;