import React from 'react'

const Pinmaterialleft = () => {
  return (
    <svg width="77" height="82" viewBox="0 0 77 82" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29.3536 0.852998C27.9908 1.32404 27.2358 2.91165 27.6676 4.39899C28.0993 5.88634 29.5542 6.70989 30.917 6.23885C32.2798 5.76781 33.0348 4.18044 32.6031 2.6931C32.1713 1.20576 30.7164 0.38196 29.3536 0.852998Z" fill="#D1AB4A"/>
    <path d="M19.1444 37.3131C18.5135 37.1459 17.878 37.5683 17.7247 38.2566C17.5715 38.9448 17.9585 39.6385 18.5894 39.8057C19.2203 39.973 19.8558 39.5503 20.009 38.8622C20.1623 38.1738 19.7752 37.4804 19.1444 37.3131Z" fill="#D1AB4A"/>
    <path d="M19.6457 39.4223L37.9214 47.6194L37.9642 47.5986L37.9466 47.5509L19.6705 39.3542L19.6279 39.3745L19.6457 39.4223Z" fill="#4E494B"/>
    <path d="M41.785 52.3308L41.6086 52.2334L25.2583 18.9317C25.2919 18.9131 25.3253 18.8936 25.3581 18.8734L41.785 52.3308Z" fill="#4E494B"/>
    <path d="M46.5135 39.9343L46.3371 39.8369L29.9868 6.53519C30.0204 6.51663 30.0538 6.49716 30.0866 6.47692L46.5135 39.9343Z" fill="#4E494B"/>
    <path d="M70.7449 81.9756L69.4367 81.1115L69.662 80.8612L70.9068 81.6835C70.8494 81.7776 70.7952 81.8749 70.7449 81.9756ZM69.3515 81.0553L5.42832 38.8398L5.78554 38.6768L69.5772 80.8052L69.3515 81.0553Z" fill="#B1A5AA"/>
    <path d="M26.0389 16.321C24.7601 15.8563 23.3783 16.6103 22.9525 18.0054C22.5267 19.4005 23.2183 20.908 24.4971 21.3727C25.7756 21.8373 27.1569 21.0832 27.5827 19.6881C28.0085 18.2929 27.3174 16.7857 26.0389 16.321Z" fill="#D1AB4A"/>
    <path d="M51.4634 40.0556L51.2772 40.1283L17.8838 30.1512C17.8946 30.1118 17.9046 30.072 17.9137 30.0321L51.4634 40.0556Z" fill="#4E494B"/>
    <path d="M16.6858 27.7368C15.4967 28.4293 15.0469 30.042 15.6814 31.3393C16.3159 32.6366 17.794 33.1266 18.9831 32.4342C20.172 31.7419 20.6213 30.1293 19.9869 28.8321C19.3524 27.5348 17.8746 27.0445 16.6858 27.7368Z" fill="#D1AB4A"/>
    <path d="M7.36717 36.779C6.05745 36.4317 4.73785 37.3083 4.41972 38.7372C4.10159 40.1661 4.90552 41.6057 6.21524 41.9529C7.52472 42.3 8.84385 41.4233 9.16198 39.9944C9.48011 38.5655 8.67665 37.1261 7.36717 36.779Z" fill="#D1AB4A"/>
    </svg>
    
  )
}

export default Pinmaterialleft
