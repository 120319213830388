import React from "react";
import { Link } from "react-router-dom";
import "./generalourservices.scss";
import scrollToTop from "../../helper/scrollToTop";

const GeneralOurServices = ({
  title,
  subtitle,
  description,
  buttonText,
  buttonURl,
  cartListItem = [],
}) => {
  return (
    <section className="generalOurServices_container">
      <div className="container">
        <div className="left_GeneralOurServices">
          <div className="subtitle">{subtitle}</div>
          <div className="title">{title}</div>
          {
            description.map((el,index)=>{
              return <p className="description" key={el} >{el}</p>
            })
          }
          {buttonText && (
            <Link to={buttonURl} className="button" onClick={()=>scrollToTop()}>
              {buttonText}
            </Link>
          )}
        </div>
        <div className="right_GeneralOurServices">
          {cartListItem.map((el, index) => {
            return (
              <div className="card">
                <div className="top">
                  {el?.icon && (
                    <div className="icons">
                      <img src={el.icon} alt="" className="src" />
                    </div>
                  )}
                  {el?.title && <div className="title">{el.title}</div>}
                </div>
                {el.description && <div className="description">{el.description}</div>}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default GeneralOurServices;
