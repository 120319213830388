import React, { useState } from 'react'
import "./sidebar.scss"
import logo from "../../assets/logo.png"
import CancelIcon from '../svg/CancelIcon'
import { Link, NavLink } from 'react-router-dom'
import LinkedinIcon from '../Footer/LinkedInIcon'
import FbIcon from '../Footer/FbIcon'
import SkypeIcon from '../Footer/SkypeIcon'
import WhatsAppIcon from '../Footer/WhatsAppIcon'
import TwitterIcon from '../Footer/TwitterIcon'
import InstagramIcon from '../Footer/InstagramIcon'
import Linkedin from '../svg/Linkedin'
import arrowDown from "../../assets/header/dropdownarrow.svg"

import { servicenavData } from './Servicesnav'

import socialmedia from "../../assets/Social/BrandLogo.svg"
import scrollToTop from '../../helper/scrollToTop'

const Sidebar = ({onClose}) => {
    const [serviceOpen,setServiceOpen] = useState(false)


    const handleClose = (e) => {
        
    }

    const handleOpenServiceSectin = (e)=>{
        e.stopPropagation()

        setServiceOpen(preve => !preve)

    }


  return (
    <aside className='sidebar' >
        <div className='content'>
            <div className='top_header'>
                <div className='logo'>
                    <img src={logo}/>
                </div>
                <button className='close' onClick={onClose} >
                    <CancelIcon/>
                </button>
            </div>
            <div className='body' >
                <nav className='nav'>
                    <NavLink  to={""}  onClick={onClose}>Home</NavLink>
                    {/* <NavLink to={"/services"} onClick={onClose}>Services</NavLink> */}
                    <div className='btn' onClick={handleOpenServiceSectin}>
                        <div className='title'>Services</div>
                        <div className={`icon ${serviceOpen && 'active'}`}>
                            <img src={arrowDown}/>
                        </div>
                    </div>
                    {
                        serviceOpen && (
                            <ul className='serviceSubNavMobile'onClick={()=>scrollToTop()}>
                                {
                                    servicenavData.map((el,index)=>{
                                        return <Link to={`services/${el.link}`} onClick={onClose}>{el.title}</Link>
                                    })
                                }
                            </ul>
                        )
                    }
                   
                    <NavLink to={"technologies"} onClick={onClose}>Technologies</NavLink>
                    <NavLink to={"aboutus"}  onClick={onClose}>About Us</NavLink>
                    <NavLink to={"we-are-hiring"} onClick={onClose}>We are hiring</NavLink>
                    <NavLink to={"contactus"}  onClick={onClose}>Contact Us</NavLink>
                </nav>

                <div className='social'>
                <img src={socialmedia}/>
               </div>
            </div> 

          
        </div>

        <div className=''>
            dskgljsdkl
        </div>
    </aside>
  )
}

export default Sidebar
