import React from 'react';
import './aboutus.scss';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import QuoteIcon from './QuoteIcon';
import 'react-phone-number-input/style.css'
import Whychooseus from '../../components/whychooseus/Whychooseus';
import ContactForm from "../../components/contactus/ContactForm";
import { Link } from 'react-router-dom';
import { S3URL } from '../../constant/common';

function AboutUs() {

  const aboutUsProcedureData = [
    `<span className="li-header">Consultation:</span> We start by understanding your business<br /> needs, goals, and challenges. We listen closely to your<br /> requirements and offer expert advice and recommendations.`,

    `<span className="li-header">Analysis and Planning:</span> We analyse your current technology<br /> systems and develop a plan to achieve your goals, taking into<br /> account budget, timeline, and other constraints.`,

    `<span className="li-header">Design and Development:</span> We design and develop custom<br /> technology solutions tailored to your specific requirements,<br /> ensuring that they are efficient, reliable, and secure.`,

    `<span className="li-header">Testing and Deployment:</span> Before deployment, we thoroughly<br /> test our solutions to ensure they meet the highest quality<br /> standards.`,

    `<span className="li-header">Maintenance and Support:</span> We provide ongoing maintenance<br /> and support to ensure that your technology systems remain<br /> up-to-date and functioning at their best.`,

    `<span className="li-header">Continuous Improvement:</span> We continuously monitor and<br /> improve our solutions to ensure that they meet your evolving<br /> needs and deliver long-term value.`
  ];


  return (
    <>
      <div className="aboutUs-Container">
        <div className="aboutUs-section1">
          <div className="section1-part1">
            <p>ABOUT INFINEIT</p>
            <h1>Who We Are?</h1>
            <p>
              We are a leading IT company dedicated to delivering innovative<br id="remove" /> technology solutions to businesses of all sizes. With a team of<br id="remove" /> highly skilled professionals, we offer a range of services<br id="remove" /> including Software Development, App & Web Development,<br id="remove" />
              UI/UX Designing, API Integration, Data Analysis and Custom<br id="remove" /> Developments.<br /><br />

              Our goal is to help our clients stay ahead of the technology<br id="remove" /> curve and meet their unique business need. We believe in<br id="remove" /> building long-term partnerships and delivering results that<br id="remove" /> exceed expectations.<br /><br />

              We are proud of our track record of successful projects and<br id="remove" /> satisfied clients, and we are committed to continuing to provide<br id="remove" /> top-notch service to our customers.<br /><br />

              Let us help you to take your business to the next level with<br id="remove" /> cutting-edge technology. <span>< Link to="/contactus">Contact Us</Link></span> today to learn more about<br id="remove" /> our services and how we can help you to achieve your goals.
            </p>
          </div>

          <div className="section1-part2">
            <img src={require('./HeroAboutUs.png')} alt="About Us Hero" />
          </div>
        </div>

        <div className="aboutUs-section2">
          <div className="center">
            <h1>&#x201C;We Believe In Your Quality And Profitability&rdquo;</h1>
            <div className="quote-InFine">
              <p>&#126;InFine<span>IT Global Services</span></p>
            </div>
          </div>
        </div>

        <OurProcedure
          heading="OUR PROCEDURE"
          subHeading="How We Work?"
          mainSvg = {`${S3URL}/AboutUs/AboutUsProcessChart.svg`}
          procedureData = {aboutUsProcedureData}
          endText = {`&#x201C;Our approach is collaborative, transparent, and focused on<br /> delivering results that exceed your expectations.&rdquo;`}
        />

        <Whychooseus />

        <div className="aboutUs-section5">
          <div className="section5-part1">
            <p><span id="quoteIcon" ><QuoteIcon /></span>InFineIT is your innovation partner, delivering sustainable impact around the world. We believe in delivering true value to our customers through our capabilities, commitment,<br /> and culture.</p>
            <div className="section5-part2">
              <div className="quote-author">
                <div className="quote-author-info">
                  <p>&#126; Jayesh Prajapati </p>
                  <p>CEO| <span>InFine</span>IT Global Services LLP</p>
                </div>
                <div className="quote-img-container">
                  <img src={require('./JPFinal.png')} alt="Author" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactForm descriptionText={`We'd love to hear from you! Whether you have a question, suggestion, or<br /> just want to say hello, we're here to help.`} />
      </div>
    </>
  );
}

export default AboutUs
