import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import oracleSvg from '../../assets/TechnologiesSvg/oracleSvg.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessOracle from '../../assets/TechnologiesSvg/ourProcessOracle.svg';
import HireUsNow from '../../components/HireUsNow/HireUsNow';


function Oracle() {
  const oracleData =
  {
    title: 'Oracle',
    titleDescription: ['Enterprise systems management software and hardware are provided by Oracle, a top international computer technology company. It provides a complete set of tools and programmes for commercial applications, cloud computing, and database management.',

      `The Oracle Database, a relational database management system used by businesses of all kinds to store, manage, and retrieve data, is Oracle's flagship product. A cloud-based platform is also provided by the business, enabling users to benefit from scalable infrastructure and fast deployment while running apps, databases, and middleware.`],
    mainSvg: '',
  };

  const processDataOracle = [
    `<span className="li-header">Scalability and Reliability:</span> Oracle databases are designed to handle the largest and most complex workloads, ensuring that businesses have the resources they need to grow and succeed.`,

    `<span className="li-header">Innovation:</span> Oracle invests heavily in research and development to bring new features and capabilities to its customers, helping them stay ahead of the curve in a rapidly changing tech landscape.`,

    `<span className="li-header">Comprehensive Solution:</span> Oracle offers a complete suite of integrated cloud applications, databases, and infrastructure services to meet the diverse needs of organizations across industries.`,

    `<span className="li-header">Strong Support Network:</span> With a large and dedicated community of users, partners, and developers, Oracle offers robust support for its products and services, helping customers get the most out of their investment.`,

    `<span className="li-header">Enterprise-Grade Security:</span> Oracle takes security seriously and provides robust security features and certifications to help organizations protect their data and systems from threats.`,

    `<span className="li-header">Proven Track Record:</span> Oracle has been a trusted provider of database and technology solutions for over 40 years, with a long history of serving some of the world's largest and most complex organizations.`,
  ];

  const processDescription = `Oracle provides a number of key features that make it an attractive choice for IT-based companies:`;

  return (
    <div className="container-technology">
      <Imagedescription
        image={oracleSvg}
        title={oracleData.title}
        subtitle={oracleData.title}
        description={oracleData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <OurProcedure
        heading="ORACLE"
        subHeading="Why Choose Oracle?"
        mainSvg={ourProcessOracle}
        procedureData={processDataOracle}
        endText={`
            Overall, Oracle is a versatile and powerful database management system that can meet the needs of IT-based companies of all sizes. Whether you need to support transactional workloads, business intelligence and analytics, or a combination of both, Oracle provides the tools and features you need to succeed.`}
        processDescription={processDescription}
      />

      <HireUsNow
        techName='Oracle'
      />
    </div>
  );
}

export default Oracle;