import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import flutterSvg from '../../assets/TechnologiesSvg/flutterSvg.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessFlutter from '../../assets/TechnologiesSvg/ourProcessFlutter.svg';
import HireUsNow from '../../components/HireUsNow/HireUsNow';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';


function Flutter() {
  const flutterData =
  {
    title: 'Flutter',
    titleDescription: ['A well-liked open-source framework for creating top-notch, cross-platform mobile apps is called Flutter. Flutter has gained popularity among developers for creating stunning and responsive mobile apps for both iOS and Android thanks to its quick development cycle and customizable UI.',

      'Our IT company employs a group of skilled Flutter developers who are committed to providing our customers with cutting-edge solutions. Whether you want to create a brand-new Flutter app or update an existing one, our team has the knowledge and experience to support you in reaching your objectives.'],
    mainSvg: '',
  };

  const processDataFlutter = [
    `<span className="li-header">Improved Efficiency:</span> Figma's cloud-based platform and collaboration features allow teams to work together more efficiently and get designs done faster.`,

    `<span className="li-header">Increased Productivity:</span> Figma's intuitive interface and powerful tools make it easier for designers to create and refine designs, increasing productivity and reducing the need for revisions.`,

    `<span className="li-header">Enhanced Collaboration:</span> Figma's collaboration features allow designers, developers, and stakeholders to work together more effectively and get feedback in real-time, improving the design review process.`,

    `<span className="li-header">Seamless Integration:</span> Figma integrates with a variety of tools and platforms, making it easier for customers to move their work from design to development.`,

    `<span className="li-header">Improved Design Quality:</span> Figma's robust design tools and resources help designers create high-quality designs that meet the needs of their users.`,

    `<span className="li-header">Reduced Costs:</span> Figma's cloud-based platform eliminates the need for expensive hardware and software, reducing costs and improving affordability.`,
  ];

  const processDescription = `Some of the benefits of developing with Flutter include:`;

  const GeneralOurServicesData = [
    {
      icon: null,
      title: "Custom App Development",
      description: null
    },
    {
      icon: null,
      title: "App Modernization",
      description: null
    },
    {
      icon: null,
      title: "API Development & Integration",
      description: null
    },
    {
      icon: null,
      title: "Custom UI/UX Design",
      description: null
    },
    {
      icon: null,
      title: "Maintenance & Support",
      description: null
    }
  ];

  return (
    <div className="container-technology">
      <Imagedescription
        image={flutterSvg}
        title={flutterData.title}
        subtitle={flutterData.title}
        description={flutterData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={'Flutter Development Services'}
        subtitle={'Services'}
        description={[`Our IT company provides professional Flutter development services to help clients build high-quality, cross-platform mobile applications. Our team of experienced Flutter developers has a deep understanding of the framework and is dedicated to delivering innovative solutions that meet the specific needs of our clients.`]}
        buttonText={null}
        buttonURl={null}
        cartListItem={GeneralOurServicesData}
      />

      <OurProcedure
        heading="FIGMA DEVELOPMENT"
        subHeading="Why Choose Flutter?"
        mainSvg={ourProcessFlutter}
        procedureData={processDataFlutter}
        endText={`
            Our team of Flutter developers has extensive experience in developing custom solutions for a wide range of clients, from small businesses to large enterprises. Whether you're looking to build a new app or modernize an existing one, our team has the expertise and experience to help you achieve your goals. Contact us today to learn more about how we can help you with your Flutter development needs.`}
        processDescription={processDescription}
      />

      <HireUsNow
        techName='Flutter'
      />
    </div>
  );
}

export default Flutter;