import { Outlet, useLocation } from 'react-router-dom';
import './App.css';
import './styles/Global.scss';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { useEffect, useState } from 'react';
import Cookies from './components/cookies/Cookies';


function App() {
  const location = useLocation()
  const [displayBackground, setDisplayBackground] = useState(false)

  const backgroundNotDisplay = [
    "/"
  ]
  useEffect(() => {
    setDisplayBackground(backgroundNotDisplay.includes(location.pathname))
  }, [])


  return (
    <>
      <Header />
      <main className='mainApp' style={{ paddingTop: location.pathname === "/" && "0" }}>
        <Outlet />
      </main>
      {/* <Cookies /> */}
      <Footer />
    </>
  );
}

export default App;
