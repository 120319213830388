import React from 'react'

const DirectcommunicationIcon = () => {
  return (
    <svg width="65" height="63" viewBox="0 0 65 63" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_29_3390)">
<mask id="mask0_29_3390" style={{"maskType":"luminance"}} maskUnits="userSpaceOnUse" x="1" y="0" width="63" height="63">
<path d="M63.5 0.5H1.5V62.5H63.5V0.5Z" fill="white"/>
</mask>
<g mask="url(#mask0_29_3390)">
<path d="M32.5 15.7578C31.8316 15.7578 31.2891 16.3004 31.2891 16.9687C31.2891 17.6372 31.8316 18.1797 32.5 18.1797C33.1683 18.1797 33.7109 17.6372 33.7109 16.9687C33.7109 16.3004 33.1683 15.7578 32.5 15.7578Z" fill="#D1AB4A"/>
<path d="M12.3984 37.5547C13.0668 37.5547 13.6094 37.0122 13.6094 36.3437C13.6094 35.6754 13.0668 35.1328 12.3984 35.1328C11.7301 35.1328 11.1875 35.6754 11.1875 36.3437C11.1875 37.0122 11.7301 37.5547 12.3984 37.5547Z" fill="#D1AB4A"/>
<path d="M42.1874 3.64844C31.2858 3.64844 22.3381 11.1648 21.6461 20.6323C11.2637 21.1531 1.5 28.6397 1.5 38.7656C1.5 42.9973 3.17449 47.0974 6.22265 50.3461C6.82576 52.8324 6.09352 55.4676 4.27664 57.2844C3.93039 57.6307 3.8268 58.1515 4.01411 58.6042C4.20143 59.0563 4.64277 59.3515 5.13281 59.3515C8.58635 59.3515 11.9145 57.9973 14.3875 55.6109C16.7965 56.4321 19.9398 56.9297 22.8126 56.9297C33.7128 56.9297 42.6595 49.4152 43.3535 39.9496C45.889 39.837 48.526 39.3687 50.6125 38.6578C53.0855 41.0442 56.4137 42.3985 59.8672 42.3985C60.3572 42.3985 60.7986 42.1033 60.9858 41.651C61.1732 41.1983 61.0696 40.6776 60.7234 40.3313C58.9064 38.5145 58.1742 35.8792 58.7774 33.393C61.8255 30.1444 63.5 26.0441 63.5 21.8126C63.5 11.2911 52.9843 3.64844 42.1874 3.64844ZM22.8126 54.5078C19.9545 54.5078 16.6977 53.9468 14.5162 53.0788C14.0488 52.8924 13.5148 53.015 13.1747 53.3863C11.7126 54.9846 9.81148 56.0991 7.74011 56.6114C8.88152 54.4246 9.17289 51.8466 8.47851 49.404C8.42363 49.2114 8.32194 49.0354 8.18239 48.8921C5.43508 46.0624 3.92187 42.466 3.92187 38.7656C3.92187 30.2323 12.5725 23.0235 22.8126 23.0235C32.482 23.0235 40.9765 29.7474 40.9765 38.7656C40.9765 47.446 32.8282 54.5078 22.8126 54.5078ZM56.8177 31.9389C56.6781 32.0828 56.5764 32.2587 56.5215 32.4508C55.8271 34.8934 56.1185 37.4715 57.2599 39.6582C55.1886 39.1464 53.2875 38.0315 51.8253 36.4332C51.4853 36.0623 50.9512 35.9393 50.4838 36.1257C48.5932 36.8778 45.896 37.3986 43.3497 37.5244C42.9958 32.9451 40.7117 28.641 36.8063 25.4453H52.6015C53.2704 25.4453 53.8126 24.9032 53.8126 24.2344C53.8126 23.5655 53.2704 23.0235 52.6015 23.0235H33.0861C30.3373 21.6243 27.2509 20.8083 24.0741 20.6371C24.7699 12.5039 32.6282 6.07031 42.1874 6.07031C52.4274 6.07031 61.0781 13.2792 61.0781 21.8126C61.0781 25.513 59.5649 29.1093 56.8177 31.9389Z" fill="#D1AB4A"/>
<path d="M32.5 35.1328H17.2421C16.5733 35.1328 16.0312 35.6749 16.0312 36.3437C16.0312 37.0126 16.5733 37.5547 17.2421 37.5547H32.5C33.1688 37.5547 33.7109 37.0126 33.7109 36.3437C33.7109 35.6749 33.1688 35.1328 32.5 35.1328Z" fill="#D1AB4A"/>
<path d="M32.5 42.3984H12.3984C11.7296 42.3984 11.1875 42.9405 11.1875 43.6093C11.1875 44.2782 11.7296 44.8202 12.3984 44.8202H32.5C33.1688 44.8202 33.7109 44.2782 33.7109 43.6093C33.7109 42.9405 33.1688 42.3984 32.5 42.3984Z" fill="#D1AB4A"/>
<path d="M52.6015 15.7578H37.3437C36.6749 15.7578 36.1328 16.2999 36.1328 16.9687C36.1328 17.6376 36.6749 18.1797 37.3437 18.1797H52.6015C53.2704 18.1797 53.8126 17.6376 53.8126 16.9687C53.8126 16.2999 53.2704 15.7578 52.6015 15.7578Z" fill="#D1AB4A"/>
</g>
</g>
<defs>
<clipPath id="clip0_29_3390">
<rect width="65" height="62" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>

  )
}

export default DirectcommunicationIcon
