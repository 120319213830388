import React, { useState } from 'react';
import "./technologies.scss";
import { S3URL } from '../../constant/common';

const technology = [
    `${S3URL}/TechnologiesLogo/netlogo.svg`,
    `${S3URL}/TechnologiesLogo/React.svg`,
    `${S3URL}/TechnologiesLogo/Angular.svg`,
    `${S3URL}/TechnologiesLogo/Nodejs.svg`,
    `${S3URL}/TechnologiesLogo/Mysqlserver.svg`,
    `${S3URL}/TechnologiesLogo/Mongodb.svg`,
    `${S3URL}/TechnologiesLogo/python.svg`,
    `${S3URL}/TechnologiesLogo/Flutter.svg`,
    `${S3URL}/TechnologiesLogo/AWS.svg`,
    `${S3URL}/TechnologiesLogo/Ionic.svg`,
    `${S3URL}/TechnologiesLogo/Liferay.svg`,
    `${S3URL}/TechnologiesLogo/Java.svg`,
    `${S3URL}/TechnologiesLogo/VUE.svg`,
    `${S3URL}/TechnologiesLogo/Reddiamond.svg`,
    `${S3URL}/TechnologiesLogo/PHP.svg`,
    `${S3URL}/TechnologiesLogo/Selenium.svg`,
];

const Technologies = () => {
    const [techLogo, setTechLogo] = useState(technology);

    return (
        <section className='technologies-container'>
            <div className='container'>
                <h1 className='title'>Technologies</h1>
                <p className='subtitle'>we work with</p>
                <div className='animation-container'>

                    <div className='slider'>
                        <div className='icons'>
                            <img src={techLogo[0]} alt='tech'/>
                        </div>
                        <div className='icons'>
                            <img src={techLogo[1]} alt='tech' />
                        </div>
                        <div className='icons'>
                            <img src={techLogo[2]} alt='tech' />
                        </div>
                        <div className='icons'>
                            <img src={techLogo[3]} alt='tech' />
                        </div>
                    </div>

                    <div className='slider'>
                        <div className='icons'>
                            <img src={techLogo[4]} alt='tech' />
                        </div>
                        <div className='icons'>
                            <img src={techLogo[5]} alt='tech' />
                        </div>
                        <div className='icons'>
                            <img src={techLogo[6]} alt='tech' />
                        </div>
                        <div className='icons'>
                            <img src={techLogo[7]} alt='tech' />
                        </div>
                    </div>

                    <div className='slider'>
                        <div className='icons'>
                            <img src={techLogo[8]} alt='tech' />
                        </div>
                        <div className='icons'>
                            <img src={techLogo[9]} alt='tech' />
                        </div>
                        <div className='icons'>
                            <img src={techLogo[10]} alt='tech' />
                        </div>
                        <div className='icons'>
                            <img src={techLogo[11]} alt='tech' />
                        </div>
                    </div>

                    <div className='slider'>
                        <div className='icons'>
                            <img src={techLogo[12]} alt='tech' />
                        </div>
                        <div className='icons'>
                            <img src={techLogo[13]} alt='tech' />
                        </div>
                        <div className='icons'>
                            <img src={techLogo[14]} alt='tech' />
                        </div>
                        <div className='icons'>
                            <img src={techLogo[15]} alt='tech' />
                        </div>
                    </div>


                </div>
            </div>
        </section>
    );
};

export default Technologies;

