import React from 'react'
import designassestsImage from "../../assets/services/Tools/designassets.svg"

const Designassesttools = () => {
  return (
    <img src={designassestsImage} loading='lazy'/>

  )
}

export default Designassesttools
