import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import netSvg from '../../assets/TechnologiesSvg/netSvg.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessNCore from '../../assets/TechnologiesSvg/ourProcessNCore.svg';
import HireUsNow from '../../components/HireUsNow/HireUsNow';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';


function NetCore() {
  const netData =
  {
    title: '.Net Core',
    titleDescription: ['.NET Core is a free, open-source and cross-platform framework for building modern applications. Developed by Microsoft, .NET Core is designed for building applications for Windows, Linux and macOS platforms. It is a high-performance framework that provides developers with a set of tools and libraries to create applications that are scalable, reliable and secure.',

      '.NET Core supports a wide range of programming languages including C#, F# and Visual Basic .NET. It provides a robust set of libraries and tools that enable developers to create applications for cloud, desktop, web and mobile platforms. The framework also supports multiple platforms, which means that applications can be built for a range of devices including IoT devices, embedded devices and gaming consoles.'],
    mainSvg: '',
  };

  const processDataNetCore = [
    `<span className="li-header">Expertise in .NET Core:</span> Our team of developers have extensive experience with .NET Core and have a deep understanding of its capabilities and limitations. We stay up-to-date with the latest developments in the technology and are able to provide cutting-edge solutions that are optimized for performance and scalability.`,

    `<span className="li-header">Cross-Platform Compatibility:</span> .NET Core is a cross-platform framework, and our team is skilled in leveraging this to build applications that run seamlessly on multiple platforms, including Windows, macOS, and Linux.`,

    `<span className="li-header">Cost-Effective Solutions:</span> Our team is able to use .NET Core's modular architecture to create solutions that are tailored to your specific needs, resulting in more efficient development and lower costs.`,

    `<span className="li-header">Robust Security:</span> The .NET Core framework is designed with security in mind, and our team is skilled in implementing the best practices for secure software development to ensure the protection of your data and applications.`,

    `<span className="li-header">Excellent Support:</span> We provide comprehensive support throughout the development process and are always available to answer any questions or address any concerns you may have. We also provide ongoing maintenance and support for the life of your application to ensure that it continues to perform at its best.`,

  ];

  const processDescription = `As a top-tier IT company, we are dedicated to providing our clients with the best possible solutions for their software development needs. If you're considering using .NET Core for your next project, here are some reasons why you should choose us:`;

  const GeneralOurServicesData = [
    {
      icon: null,
      title: "Custom software development using .NET Core",
      description: null
    },
    {
      icon: null,
      title: "Integration of .NET Core applications with other systems",
      description: null
    },
    {
      icon: null,
      title: "Maintenance and support for existing .NET Core applications",
      description: null
    },
    {
      icon: null,
      title: "Migration of existing applications to .NET Core",
      description: null
    },
    {
      icon: null,
      title: "Consulting and advising on .NET Core best practices and technology roadmap.",
      description: null
    },
    {
      icon: null,
      title: "SaaS based Application Development",
      description: null
    },
    {
      icon: null,
      title: "Re-engineering Legacy Application with .NET",
      description: null
    },
    {
      icon: null,
      title: "CRM, POS, CMS & other product development",
      description: null
    }
  ];

  return (
    <div className="container-technology net-core">
      <Imagedescription
        image={netSvg}
        title={netData.title}
        subtitle={netData.title}
        description={netData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={'.Net Development Services'}
        subtitle={'Services'}
        description={[`.NET Core is an open-source, cross-platform framework for building modern applications. Development services for .NET Core typically include designing, coding, testing, and deploying applications built using the framework. This can encompass a wide range of application types, including web, mobile, desktop, and IoT applications. Some specific services offered within the scope of .NET Core development may include:`]}
        buttonText={null}
        buttonURl={null}
        cartListItem={GeneralOurServicesData}
      />

      <OurProcedure
        heading=".NET DEVELOPMENT"
        subHeading="Why choose .Net Core?"
        mainSvg={ourProcessNCore}
        procedureData={processDataNetCore}
        endText={``}
        processDescription={processDescription}
      />

      <HireUsNow
        techName='.NET'
      />
    </div>
  );
}

export default NetCore;