import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import javaSvg from '../../assets/TechnologiesSvg/javaSvg.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessJava from '../../assets/TechnologiesSvg/ourProcessJava.svg';
import HireUsNow from '../../components/HireUsNow/HireUsNow';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';


function Java() {
  const javaData =
  {
    title: 'Java',
    titleDescription: ['Java is a well-liked class-based, object-oriented programming language that enterprises and organizations of all sorts have embraced in large numbers. It is a fantastic option for designing a wide variety of apps, including web, mobile, desktop, and enterprise applications, because to its robust features and simplicity of use.',

      'Our IT company employs a group of skilled Java developers that are professionals at providing tailored solutions that are tailored to the individual requirements of our clients. Our team can create high-quality, scalable solutions since we have years of experience creating Java-based apps.'],
    mainSvg: '',
  };

  const processDataJava = [
    `<span className="li-header">Object-Oriented Programming:</span> Java is built on an object-oriented programming paradigm, which allows for a more organized and modular approach to software development.`,

    `<span className="li-header">Cross-Platform Compatibility:</span> Java applications can run on any platform that has a Java Virtual Machine (JVM) installed, making it a versatile choice for businesses that need to run their applications on multiple platforms.`,

    `<span className="li-header">Large Community of Developers:</span> Java has a large and active community of developers, which means that there is a wealth of knowledge and resources available to businesses and organizations that are using the language.`,

    `<span className="li-header">Scalability and Performance:</span> Java is designed to be a scalable language, and its architecture allows for the efficient handling of large amounts of data and transactions.`,

    `<span className="li-header">Robust Security:</span> Java has built-in security features and is designed with security in mind, making it a safe choice for businesses that need to protect sensitive data and transactions.`,
  ];

  const GeneralOurServicesData = [
    {
      icon: null,
      title: "Custom application development using Java",
      description: null
    },
    {
      icon: null,
      title: "Building scalable and high-performance enterprise applications",
      description: null
    },
    {
      icon: null,
      title: "Integration of Java applications with other systems and APIs",
      description: null
    },
    {
      icon: null,
      title: "Maintenance and support for existing Java applications",
      description: null
    },
    {
      icon: null,
      title: "Consulting and advising on Java best practices and technology roadmap",
      description: null
    },
    {
      icon: null,
      title: "Full-stack development using Java, JavaScript, and related technologies",
      description: null
    }
  ];

  return (
    <div className="container-technology">
      <Imagedescription
        image={javaSvg}
        title={javaData.title}
        subtitle={javaData.title}
        description={javaData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={'Java Development Services'}
        subtitle={'Services'}
        description={[`Java is a multi-platform, object-oriented, and network-centric language that can be used as a platform in itself. It is a fast, secure, reliable programming language for coding everything from mobile apps and enterprise software to big data applications and server-side technologies.`]}
        buttonText={null}
        buttonURl={null}
        cartListItem={GeneralOurServicesData}
      />

      <OurProcedure
        heading="JAVA DEVELOPMENT"
        subHeading="Why Choose Java?"
        mainSvg={ourProcessJava}
        procedureData={processDataJava}
        endText={`
            Our team of Java developers is dedicated to delivering high-quality, custom solutions that meet the specific needs of our clients. Whether you're looking to build a new application or modernize an existing one, our team has the expertise and experience to deliver a solution that meets your needs. Contact us today to learn more about how we can help you achieve your goals with Java.`}
        processDescription={false}
      />

      <HireUsNow
        techName='Java'
      />
    </div>
  );
}

export default Java;