import React from "react";
import Imagedescription from "../../components/imagedescription/Imagedescription";
import mainImage from "../../assets/services/Software Development/Main Software.svg";
import Toolstouse from "../../components/Toolstouse/Toolstouse";
import GeneralOurServices from "../../components/ourServicesInServices/GeneralOurServices";
import bespokeSoftwareIcon from "../../assets/services/Software Development/Our Services/icon1.svg"
import applicationIcon from "../../assets/services/Software Development/Our Services/icon2.svg"
import softwareapplicationIcons from "../../assets/services/Software Development/Our Services/icons3.svg"
import saasIcons from "../../assets/services/Software Development/Our Services/icons4.svg"
import applicationIntegrationIcons from "../../assets/services/Software Development/Our Services/icons5.svg"
import maintencesupportIcons from "../../assets/services/Software Development/Our Services/icons6.svg"
import ProcessServices from "../../components/processServices/ProcessServices";
import Recentreviews from "../../components/recentReviews/Recentreviews";
import ContactForm from "../../components/contactus/ContactForm";
import processImage from '../../assets/services/processSection/softwaredevelopment.png'


const ImageDescriptiondata = {
  title: "Software Development",
  subtitle: "Software Development",
  description: [
    ` The foundation of today's technology-driven businesses is software development. To stay competitive, businesses must stay up to date on the most recent developments in software technology. The appropriate software may streamline operations, boost output, and improve customer satisfaction, all of which can boost revenue and business   expansion.`,
    `
    We at InFineIT Services  provide a broad variety of software development services to assist companies take advantage of the newest technologies. The most recent software development approaches and coding languages, such as Java,.NET, Python, and others, are all familiar to our team of skilled engineers.
    `,
  ],
  image: mainImage,
};

const ourServicesData = {
  subtitle: "What we Do",
  title: "Our Services",
  description:[
    `At InFineIT Solutions, we understand the importance of delivering high-quality software solutions that meet business needs. Our developers work closely with clients to understand their requirements, delivering customized software solutions that are efficient, scalable, and reliable.`,
    `If you're looking for a trusted partner to help you harness the power of software technology, look no further. Contact us today to schedule a consultation and find out how we can help your business grow.`
  ],
    button: {
    title: "Book A Free Consultation",
    url: "/contactus",
  },
  cardList : [
    {
      icon : bespokeSoftwareIcon,
      title : "Bespoke Software Development:",
      description : `Stronger overall business strategy is achieved by combining excellent bespoke software development services with knowledgeable resources and technical prowess. We build custom applications from scratch for mobile, online, and cloud platforms using a strong custom development strategy that ensures seamless integration, flexibility, and commercial profitability.`
    },
    {
      icon : applicationIcon,
      title : "Application Migration & re-engineering:",
      description : "Redesign, remodel, or migrate current software solutions to fully customised, functionally dense apps to modernize them and improve performance. Our software experts guarantee error-free software re-engineering with sophisticated additions of new features, enhanced UI/UX, and contemporary security based on cutting-edge technology."
    },
    {
      icon : softwareapplicationIcons,
      title : "Software Application Maintenance:",
      description : "Utilize your software application's full potential by optimizing its performance and doing routine software maintenance. With monthly software upgrades and immediate problem resolution, our bespoke software development services give you the ability to transparently handle all the post-development tasks and easily create high-performing apps."
    },
    {
      icon : saasIcons,
      title : "SaaS Application:",
      description : "The process of developing cloud-based software solutions that can be downloaded and utilized online is known as SaaS (Software as a Service) Application Development. Our team of professionals specializes in creating scalable and secure SaaS apps that are tailored to your company's unique requirements. Custom SaaS application development, system integration, cloud migration, and ongoing maintenance and support are a few of the services we offer."
    },
    {
      icon : applicationIntegrationIcons,
      title : "Application Integration:",
      description : "Services for end-to-end application integration to improve communication across various business sources that impede business growth. Our software engineers provide cutting-edge services between on-premise cloud or offline data environments with customization to provide more efficient and adaptable corporate operations at lower integration costs."
    },
    {
      icon : maintencesupportIcons,
      title : "Maintenance & Support:",
      description : "By implementing transformative changes as part of software upgrade and maintenance that is targeted to particular client needs, you can upscale application performance. For seamless business continuity, we assist clients with custom software support at several levels, including infrastructure changes, operating system integration changes, web services support, and cloud support."
    }

  ]
};

const Softwaredevelopement = () => {
  return (
    <>
      <Imagedescription
        image={ImageDescriptiondata.image}
        title={ImageDescriptiondata.title}
        subtitle={ImageDescriptiondata.subtitle}
        description={ImageDescriptiondata.description}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices 
        title={ourServicesData.title}
        subtitle={ourServicesData.subtitle}
        description={ourServicesData.description}
        buttonText={ourServicesData.button.title}
        buttonURl={ourServicesData.button.url}
        cartListItem={ourServicesData.cardList}
      />

      <ProcessServices
         title={'Software Development Process'}
         img={processImage}
      />

      {/* <Recentreviews/> */}

      <ContactForm
        descriptionText={"Fill out the information below. To get more information about Software <br/> Development and its services."}
      />
      
    </>
  );
};

export default Softwaredevelopement;
