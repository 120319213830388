import React from 'react'
import technologiestoolsImage from "../../assets/services/Tools/technologies.svg"

const Technologiestools = () => {
  return (
    <img src={technologiestoolsImage} loading='lazy'/>

  )
}

export default Technologiestools
