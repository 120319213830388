import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import mongoSvg from '../../assets/TechnologiesSvg/mongoSvg.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessMongo from '../../assets/TechnologiesSvg/ourProcessMongo.svg';
import HireUsNow from '../../components/HireUsNow/HireUsNow';


function MongoDb() {
  const mongoData =
  {
    title: 'MongoDB',
    titleDescription: ['For web, mobile, and cloud-based applications, MongoDB is a well-liked open-source NoSQL database management system. It is the best option for applications that need high-performance, adaptable data storage and retrieval capabilities due to its flexible and scalable data model.',

      `Developers can store and retrieve data in MongoDB's document-based data model in a flexible and scalable way. This eliminates the need to standardize the data into a predetermined schema and enables the simple storage and retrieval of complicated data structures, such as arrays and nested documents.`],
    mainSvg: '',
  };

  const processDataMongo = [
    `<span className="li-header">Document-Oriented:</span> MongoDB uses a document-oriented model, which allows for more flexible and scalable data storage compared to traditional relational databases.`,

    `<span className="li-header">Scalability:</span> MongoDB is designed to scale horizontally across multiple servers, making it a good choice for large-scale, high-traffic applications.`,

    `<span className="li-header">High Performance:</span> MongoDB provides fast and efficient performance for both reads and writes, making it a good choice for real-time applications.`,

    `<span className="li-header">Easy to use:</span> MongoDB has a simple and intuitive query language that makes it easy for developers to work with and integrate into their applications.`,

    `<span className="li-header">Cloud-Native:</span> MongoDB has a strong focus on cloud computing and can be easily deployed on cloud platforms like AWS, Google Cloud, and Microsoft Azure.`,

    `<span className="li-header">Flexible Data Model:</span> MongoDB allows for flexible and dynamic data modeling, making it easy to store and retrieve data in various forms.`,

    `<span className="li-header">Strong Community:</span> MongoDB has a large and active community of developers and users who provide support and contribute to the development of the software.`,

    `<span className="li-header">Rich Features:</span> MongoDB offers a variety of features, including indexing, aggregation, and geospatial data support, that make it a comprehensive and versatile database solution.`,
  ];

  const processDescription = `MongoDB provides a number of key features that make it an attractive choice for IT-based companies:`;

  return (
    <div className="container-technology">
      <Imagedescription
        image={mongoSvg}
        title={mongoData.title}
        subtitle={mongoData.title}
        description={mongoData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <OurProcedure
        heading="MONGODB"
        subHeading="Why Choose MongoDB?"
        mainSvg={ourProcessMongo}
        procedureData={processDataMongo}
        endText={`
        Overall, MongoDB is a versatile and powerful database management system that can meet the needs of IT-based companies of all sizes. Whether you need to support transactional workloads, business intelligence and analytics, or a combination of both, MongoDB provides the tools and features you need to succeed.`}
        processDescription={processDescription}
      />

      <HireUsNow
        techName='MongoDB'
      />
    </div>
  );
}

export default MongoDb;