import React from 'react'
import Imagedescription from '../../components/imagedescription/Imagedescription';
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';
import ProcessServices from '../../components/processServices/ProcessServices';
import Recentreviews from '../../components/recentReviews/Recentreviews';
import ContactForm from '../../components/contactus/ContactForm';

import mainImage from "../../assets/services/Product Development/Main Product.svg";

import Icons1 from "../../assets/services/Product Development/Our Services/Frame 3466398.svg"
import Icons2 from "../../assets/services/Product Development/Our Services/Frame 3466398-1.svg"
import Icons3 from "../../assets/services/Product Development/Our Services/Frame 3466398-2.svg"
import Icons4 from "../../assets/services/Product Development/Our Services/Frame 3466398-3.svg"
import Icons5 from "../../assets/services/Product Development/Our Services/Frame 3466398-4.svg"
import Icons6 from "../../assets/services/Product Development/Our Services/Frame 3466398-5.svg"

import processImage from '../../assets/services/processSection/productDevelopment.png'

const ImageDescriptiondata = {
  title: "Product Development",
  subtitle: "Product Development",
  description: [
    ` Our IT company is dedicated to helping businesses bring their ideas to life with our comprehensive product development services. Our process involves a multidisciplinary approach that covers all aspects of product development, from ideation to launch and is executed with precision and excellence.`,
  ],
  image: mainImage,
};
const ourServicesData = {
  subtitle: "What we Do",
  title: "Our Services",
  description:[
    `At InFineIT Services, we understand the importance of delivering high-quality product solutions that meet business needs. Our developers work closely with clients to understand their requirements, delivering customized product development solutions that are efficient, scalable, and reliable. `,
    `If you're looking for a trusted partner to help you harness the power of software technology, look no further. Contact us today to schedule a consultation and find out how we can help your business grow.`,
  ],
  button: {
    title: "Book A Free Consultation",
    url: "/contactus",
  },
  cardList: [
    {
      icon: Icons1,
      title: "Prototyping & MVP Development:",
      description: `Stronger overall business strategy is achieved by combining excellent bespoke software development services with knowledgeable resources and technical prowess. We build custom applications from scratch for mobile, online, and cloud platforms using a strong custom development strategy that ensures seamless integration, flexibility, and commercial profitability.`
    },
    {
      icon: Icons2,
      title: "Product Architecture Design Service:",
      description: "Redesign, remodel, or migrate current software solutions to fully customised, functionally dense apps to modernize them and improve performance. Our software experts guarantee error-free software re-engineering with sophisticated additions of new features, enhanced UI/UX, and contemporary security based on cutting-edge technology."
    },
    {
      icon: Icons3,
      title: "UI/UX Design Service:",
      description: "Utilize your software application's full potential by optimizing its performance and doing routine software maintenance. With monthly software upgrades and immediate problem resolution, our bespoke software development services give you the ability to transparently handle all the post-development tasks and easily create high-performing apps."
    },
    {
      icon: Icons4,
      title: "Product Development:",
      description: `The process of developing cloud-based software solutions that can be downloaded and utilized online is known as SaaS (Software as a Service) Application Development. Our team of professionals specializes in creating scalable and secure SaaS apps that are tailored to your company's unique requirements. Custom SaaS application development, system integration, cloud migration, and ongoing maintenance and support are a few of the services we offer.`
    },
    {
      icon: Icons5,
      title: "Product Testing:",
      description: "Services for end-to-end application integration to improve communication across various business sources that impede business growth. Our software engineers provide cutting-edge services between on-premise cloud or offline data environments with customization to provide more efficient and adaptable corporate operations at lower integration costs."
    },
    {
      icon: Icons6,
      title: "Maintenance & Support Services:",
      description: "By implementing transformative changes as part of software upgrade and maintenance that is targeted to particular client needs, you can upscale application performance. For seamless business continuity, we assist clients with custom software support at several levels, including infrastructure changes, operating system integration changes, web services support, and cloud support."
    },
  ]
};


const Productdevelopment = () => {
  return (
    <>
      <Imagedescription
        image={ImageDescriptiondata.image}
        title={ImageDescriptiondata.title}
        subtitle={ImageDescriptiondata.subtitle}
        description={ImageDescriptiondata.description}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={ourServicesData.title}
        subtitle={ourServicesData.subtitle}
        description={ourServicesData.description}
        buttonText={ourServicesData.button.title}
        buttonURl={ourServicesData.button.url}
        cartListItem={ourServicesData.cardList}
      />

      <ProcessServices
        title={'Product Development Process'}
        img={processImage}
      />

      {/* <Recentreviews /> */}

      <ContactForm
        descriptionText={"Fill out the information below. To get more information about Product <br/> Development and its services."}
      />

    </>
  );
}

export default Productdevelopment
