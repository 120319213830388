import { createBrowserRouter, Route, createRoutesFromElements } from "react-router-dom";
import App from "../App";
import Home from "../pages/home/Home";
import AboutUs from "../pages/aboutus/AboutUs";
import ContactUs from "../pages/contactus/ContactUs";
import Services from "../pages/services/Services";
import Technologies from "../pages/technologies/Technologies";
import Wearehiring from "../pages/wearehiring/Wearehiring";
import Softwaredevelopement from "../pages/services/Softwaredevelopement";
import Applicationdevelopment from "../pages/services/Applicationdevelopment";
import Productdevelopment from "../pages/services/Productdevelopment";
import Testingqa from "../pages/services/Testingqa";
import Uiuxdesign from "../pages/services/Uiuxdesign";
import Utilitydevelopment from "../pages/services/Utilitydevelopment";
import Webdevelopment from "../pages/services/Webdevelopment";
import Dediccateddevelopmentteam from "../pages/services/Dediccateddevelopmentteam";
import NetCore from "../pages/technologies/NetCore";
import NodeJs from "../pages/technologies/NodeJs";
import Java from "../pages/technologies/Java";
import Python from "../pages/technologies/Python";
import ReactJs from "../pages/technologies/React";
import VueJs from "../pages/technologies/VueJs";
import Angular from "../pages/technologies/Angular";
import Ios from "../pages/technologies/Ios";
import Android from "../pages/technologies/Android";
import Flutter from "../pages/technologies/Flutter";
import ReactNative from "../pages/technologies/ReactNative";
import Xamarin from "../pages/technologies/Xamarin";
import SqlServer from "../pages/technologies/SqlServer";
import Oracle from "../pages/technologies/Oracle";
import MySql from "../pages/technologies/MySql";
import PostGres from "../pages/technologies/PostGres";
import MongoDb from "../pages/technologies/MongoDb";
import Figma from "../pages/technologies/Figma";
import Adobe from "../pages/technologies/Adobe";
import Sketch from "../pages/technologies/Sketch";
import Cloud from "../pages/technologies/Cloud";
import Login from "../pages/Login/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/" element={<App />}>
                <Route path="" element={<Home />} />
                <Route path="aboutus" element={<AboutUs />} />
                <Route path="contactus" element={<ContactUs />} />
                <Route path="technologies" element={<Technologies />} />
                <Route path="we-are-hiring" element={<Wearehiring />} />

                <Route path="/services" element={<Services />}>
                    <Route path="software-development" element={<Softwaredevelopement />} />
                    <Route path="application-development" element={<Applicationdevelopment />} />
                    <Route path="dedicated-development-team" element={<Dediccateddevelopmentteam />} />
                    <Route path="product-development" element={<Productdevelopment />} />
                    <Route path="testing-qa" element={<Testingqa />} />
                    <Route path="uiux-design" element={<Uiuxdesign />} />
                    <Route path="utility-development" element={<Utilitydevelopment />} />
                    <Route path="web-development" element={<Webdevelopment />} />
                </Route>

                <Route path="/technologies" element={<Technologies />}>
                    <Route path="net-core" element={<NetCore />} />
                    <Route path="nodejs" element={<NodeJs />} />
                    <Route path="java" element={<Java />} />
                    <Route path="python" element={<Python />} />
                    <Route path="react" element={<ReactJs />} />
                    <Route path="vuejs" element={<VueJs />} />
                    <Route path="angular" element={<Angular />} />
                    <Route path="ios" element={<Ios />} />
                    <Route path="android" element={<Android />} />
                    <Route path="flutter" element={<Flutter />} />
                    <Route path="reactnative" element={<ReactNative />} />
                    <Route path="xamarin" element={<Xamarin />} />
                    <Route path="sqlserver" element={<SqlServer />} />
                    <Route path="oracle" element={<Oracle />} />
                    <Route path="postgresql" element={<PostGres />} />
                    <Route path="mysql" element={<MySql />} />
                    <Route path="mongodb" element={<MongoDb />} />
                    <Route path="figma" element={<Figma />} />
                    <Route path="adobe" element={<Adobe />} />
                    <Route path="sketch" element={<Sketch />} />
                    <Route path="cloud" element={<Cloud />} />
                    <Route path="testing" element={<Testingqa />} />
                </Route>

            </Route>

            <Route path="login-hr-dept" element={<Login />} />


            <Route path='/dashboard-hr-dept' element={
                <ProtectedRoute>
                    <Dashboard />
                </ProtectedRoute>}
            />
        </>
    )
);


export default router;