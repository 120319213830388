import React from 'react';
import './joinourteam.scss';
import OurTeamHero from '../svg/OurTeamHero';

function JoinOurTeam() {
    return (
        <div className="container-join-our-team">
            <section className="join-our-team">
                <div className="section1-info">
                    <p>JOIN OUR TEAM</p>
                    <h1>"Join Our Tech-Savvy Team And<br />
                        Shape The Future Of Digital Solutions"
                    </h1>
                </div>

                <div className = "section1-hero">
                    <OurTeamHero />
                </div>
            </section>
        </div>
    );
}

export default JoinOurTeam;