import React from 'react'
import backendImage from "../../assets/services/Tools/backend.svg"

const Backendtools = () => {
  // <rect x="0.5" y="0.5" width="282" height="221" rx="9.5" stroke="#CBCBCB"/>
  return (
    <img src={backendImage}/>
     
  )
}

export default Backendtools
