import React from "react";
import "./completePackageDesktop.scss";
import data from "./data";
import Pinmaterialleft from "../svg/Pinmaterialleft";
import Pinmaterialright from "../svg/Pinmaterialright"

const CompletePackageDesktop = () => {
  return (
    <div className="completePackageDesktop">
      <div className="boxContainer">
        {data.map((el, index) => {
          return (
            <div className="box" key={el.heading + index + "destop"}>
              <span className="pin-material">
                <Pinmaterialleft />
              </span>
                <span className="pin-material-botoom">
                  <Pinmaterialright />
                </span>
              <div className="contentContainer">
                <div className={`content`}>
                  <div className="icons">{el.icon}</div>
                  <div className="heading">{el.heading}</div>
                  <p>{el.desc}</p>
                </div>
                <span className="border-top-left hover"></span>
                <span className="border-top-right hover"></span>
                <span className="border-bottom-right hover"></span>
                <span className="border-bottom-left hover"></span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CompletePackageDesktop;
