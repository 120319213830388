import React from 'react';
import './login.scss';
import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const loginApi = async (reqBody) => {

        try {
            const res = await fetch('https://tiny.diadna.com/admin/login', {
                method: 'POST',
                body: JSON.stringify(reqBody),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            });

            const resUp = await res.json();
            localStorage.setItem('username', resUp.email);
            navigate('/dashboard-hr-dept');
        }
        catch (e) {
            console.log(e);
        }
    }

    const onFinish = (data) => {
        //Call LOGIN API Here
        loginApi(data);
        form.resetFields();
    }

    const onFinishFailed = (data) => {
        console.log('Error---->', data);
    }

    return (
        <div className='container-login'>
            <div className='login-box'>
                <Form
                    name="login"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout='vertical'
                    form={form}
                >
                    <h1>Login</h1>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input a valid Email!',
                                pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input a valid password!',
                                pattern: /^[ A-Za-z0-9_@./#&+-]*$/
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Login
                        </Button>
                    </Form.Item>
                    <p>Can't remember credentials? <span>Contact Web Team.</span></p>
                </Form>
            </div>
        </div>
    )
}

export default Login;