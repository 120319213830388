import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import nodeSvg from '../../assets/TechnologiesSvg/nodeSvg.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessNode from '../../assets/TechnologiesSvg/ourProcessNode.svg';
import HireUsNow from '../../components/HireUsNow/HireUsNow';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';



function NodeJs() {
  const nodeData =
  {
    title: 'Node.JS',
    titleDescription: ['Node.js is a JavaScript server-side framework that gives programmers the tools to create responsive, scalable, and effective applications. Node.js was created in 2009, but due to its capacity to handle large levels of traffic and real-time data, it has swiftly grown to become one of the most popular platforms for developing online applications.',

      `The quick performance of Node.js is one of its main advantages. As a result of the platform's event-driven, non-blocking I/O strategy, which enables it to handle numerous concurrent connections, real-time applications like online games and chat programmed thrive on it.`],
    mainSvg: '',
  };

  const processDataNode = [
    `<span className="li-header">Expertise in Node.js:</span> Our team of developers have extensive experience with Node.js and have a deep understanding of its capabilities and limitations. We stay up-to-date with the latest developments in the technology and are able to provide cutting-edge solutions that are optimized for performance and scalability.`,

    `<span className="li-header">Cross-Platform Compatibility:</span> Node.js is a cross-platform runtime environment, and our team is skilled in leveraging this to build applications that run seamlessly on multiple platforms, including Windows, macOS, and Linux.`,

    `<span className="li-header">Scalable and High-Performance Solutions:</span> Node.js is well-known for its ability to build fast and scalable applications, and our team is experienced in using its asynchronous event-driven architecture to deliver high-performance solutions.`,

    `<span className="li-header">Robust Security:</span> Our team is skilled in implementing the best practices for secure software development, ensuring the protection of your data and applications.`,

    `<span className="li-header">Excellent Support:</span>: We provide comprehensive support throughout the development process and are always available to answer any questions or address any concerns you may have. We also provide ongoing maintenance and support for the life of your application to ensure that it continues to perform at its best.`,

  ];

  const processDescription = `As a top-tier IT company, we are dedicated to providing our clients with the best possible solutions for their software development needs. If you're considering using Node.js for your next project, here are some reasons why you should choose us:`;

  const GeneralOurServicesData = [
    {
      icon: null,
      title: "Custom server-side development using Node.js",
      description: null
    },
    {
      icon: null,
      title: "Web App Modernization",
      description: null
    },
    {
      icon: null,
      title: "API Development & Integration",
      description: null
    },
    {
      icon: null,
      title: "Custom UI/UX Design using Angular",
      description: null
    },
    {
      icon: null,
      title: "Staff Augmentation",
      description: null
    },
    {
      icon: null,
      title: "Maintenance & Support",
      description: null
    }
  ];


  return (
    <div className="container-technology">
      <Imagedescription
        image={nodeSvg}
        title={nodeData.title}
        subtitle={nodeData.title}
        description={nodeData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={'Node.jS Development Services'}
        subtitle={'Services'}
        description={[`.NET Core is an open-source, cross-platform framework for building modern applications. Development services for .NET Core typically include designing, coding, testing, and deploying applications built using the framework. This can encompass a wide range of application types, including web, mobile, desktop, and IoT applications. Some specific services offered within the scope of .NET Core development may include:`]}
        buttonText={null}
        buttonURl={null}
        cartListItem={GeneralOurServicesData}
      />

      <OurProcedure
        heading="NODE.JS DEVELOPMENT"
        subHeading="Why choose Node.JS?"
        mainSvg={ourProcessNode}
        procedureData={processDataNode}
        endText={``}
        processDescription={processDescription}
      />

      <HireUsNow
        techName='NodeJS'
      />
    </div>
  );
}

export default NodeJs;