import React from "react";
import "./toolstouse.scss";
import data from "./data";
import Pinmaterialleft from "../svg/Pinmaterialleft";
import Pinmaterialright from "../svg/Pinmaterialright";
import Toolsmobile from "./Toolsmobile";

const Toolstouse = ({
  isBackend = true,
  isFrontend = true,
  isDesigning = true,
  isMobility = true,
  isDatabase = true,
  isTesting = true,
  isCloud = true,
  isDevops = true,
  isTechnologies = true,
  isWireframe = true,
  isVisualDesign = true,
  isDesignAssests = true,
  testingClass,
  maxWidth
}) => {
  const activeTools = {
    backendtools: isBackend,
    frontendtools: isFrontend,
    designingtools: isDesigning,
    mobilitytools: isMobility,
    databasetools: isDatabase,
    testingtools: isTesting,
    cloudtools: isCloud,
    devopstools: isDevops,
    technologiestools: isTechnologies,
    wireframeprototypetools: isWireframe,
    visualdesigntools: isVisualDesign,
    designasseststools: isDesignAssests,
  };

  return (
    <div className="toolstouse">

      <div className="container" style={{ maxWidth: maxWidth }}>
        <div className="subtitle">Technologies</div>
        <h2 className="title">Tools We Use</h2>

        <div className={`boxContainer desktopDisplay ${testingClass}`}>

          <span className="pin-material">
            <Pinmaterialleft />
          </span>
          <span className="pin-material-botoom">
            <Pinmaterialright />
          </span>


          <div className="items">
            {data.map((el) => {
              return (
                <div className={`item ${activeTools[el.id] && "active"}`} key={el.id}>
                  {el.image}
                </div>
              );
            })}
          </div>
        </div>


        <Toolsmobile />




      </div>
    </div>
  );
};

export default Toolstouse;
