import React from 'react'

const Arrowupicon = () => {
  return (
  <div style={{transform : "rotate(90deg)"}}>
    <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.4175 6.2875C13.5675 6.4375 13.6375 6.6275 13.6375 6.8175C13.6375 7.0075 13.5675 7.2075 13.4175 7.3475C13.1275 7.6375 12.6475 7.6375 12.3575 7.3475L7.56641 2.55641L7.56641 17.748C7.56641 18.158 7.22641 18.498 6.81641 18.498C6.40641 18.498 6.06641 18.158 6.06641 17.748L6.06641 2.55859L1.2775 7.3475C0.9875 7.6375 0.5075 7.6375 0.2175 7.3475C-0.0725 7.0575 -0.0725 6.5775 0.2175 6.2875L6.2875 0.2175C6.5775 -0.0725 7.0575 -0.0725 7.3475 0.2175L13.4175 6.2875Z" fill="white"/>
    </svg>
  </div>

  )
}

export default Arrowupicon
