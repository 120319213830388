import React, { useEffect, useState } from 'react'
import logo from "../../assets/logo.png"
import "./header.scss"
import { NavLink, useLocation, useParams } from 'react-router-dom'
import MenuIcon from '../svg/MenuIcon'
import Sidebar from './Sidebar'
import { Link } from 'react-router-dom'
import scrollToTop from '../../helper/scrollToTop'
import Subnavigation from './Subnavigation'
import Servicesnav from './Servicesnav'
import useOnScroll from '../../hooks/useOnScroll'
import TechnologiesNav from './TechnologiesNav'


const Header = () => {
  const [toggle, setToggle] = useState(false)
  const [scroll] = useOnScroll()
  const location = useLocation()
  const [menu, setMenu] = useState({
    services: false,
    technologies: false
  })





  const handleToggleServices = () => {
    // scrollToTop()
    setMenu((preve) => {
      return {
        ...preve,
        services: !preve.services,
        technologies : false
      }
    })
  }

  const handleToggleTechnologies = () => {
    // scrollToTop();
    setMenu((preve) => {
      return ({
        ...preve,
        services : false,
        technologies: !preve.technologies
      });
    })
  }

  const onMouseLeaveToggleServices = () => {
    // scrollToTop()
    setMenu((preve) => {
      return {
        ...preve,
        services: false
      }
    })
  }

  const onMouseLeaveToggleTechnologies = () => {
    setMenu((preve) => {
      return({
        ...preve,
        technologies : false
      });
    });
  }

  const handleCloseServices = () => {
    // scrollToTop()
    setMenu((preve) => {
      return {
        ...preve,
        services: false
      }
    })
  }

  const handleCloseTechnologies = () => {
    setMenu((preve) => {
      return({
        ...preve,
        technologies : false
      });
    });
  }



  const handleHome = () => {
    scrollToTop()
    setMenu((preve) => {
      return {
        ...preve,
        services: false,
        technologies : false
      }
    })
  }

  const handelTechnologies = () => {
    scrollToTop()
    setMenu((preve) => {
      return {
        ...preve,
        technologies: true
      }
    })
  }

  const handleAboutus = () => {
    scrollToTop()
    setMenu((preve) => {
      return {
        ...preve,
        services: false,
        technologies : false
      }
    })
  }

  const handleweareHiring = () => {
    scrollToTop()
    setMenu((preve) => {
      return {
        ...preve,
        services: false,
        technologies : false
      }
    })
  }

  const handlecontact = () => {
    scrollToTop()
    setMenu((preve) => {
      return {
        ...preve,
        services: false,
        technologies : false
      }
    })
  }

  // style={{background : scroll < 60 && displayBackground &&   "transparent" , borderColor : scroll > 60 && displayBackground ? "#565656" : "transparent"}}
  const conditionHeaderScroll = scroll < 45 && location.pathname === "/" && (!menu.services && !menu.technologies)  ? true : false

  return (
    <>
      <header className='header' style={{ background: conditionHeaderScroll && "transparent", border: conditionHeaderScroll && "none" }}>
        <div className='container'>
          <div className='logo'>
            <Link to="/"><img src={logo} /></Link>
          </div>
          <nav className='nav'>
            <NavLink to={""} onClick={handleHome} className={"link"}>Home</NavLink>
            <button onClick={handleToggleServices} className={"link"} >
              Services
              {menu.services && (
                <div className='triangleContainer'>
                  <div className='triangle'></div>
                </div>
              )}
            </button>

            <button onClick={handleToggleTechnologies} className={"link"} >
              Technologies
              {menu.technologies && (
                <div className='triangleContainer'>
                  <div className='triangle'></div>
                </div>
              )}
            </button>
            {/* <NavLink to={"technologies"} onClick={handelTechnologies} className={"link"}>Technologies</NavLink> */}
            <NavLink to={"aboutus"} onClick={handleAboutus} className={"link"}>About Us</NavLink>
            <NavLink to={"we-are-hiring"} onClick={handleweareHiring} className={"link"}>We are hiring</NavLink>
            <NavLink to={"contactus"} onClick={handlecontact}>Contact Us</NavLink>
          </nav>

          <div className='Mobilemenu' onClick={() => setToggle(true)}>
            <MenuIcon />
          </div>

        </div>
        {
          menu.services && (
            <>
              <Subnavigation onMouseLeave={onMouseLeaveToggleServices} >
                <Servicesnav linkClickClose={handleCloseServices} />
              </Subnavigation>
            </>
          )
        }

        {
          menu.technologies && (
            <>
              <Subnavigation onMouseLeave={onMouseLeaveToggleTechnologies} >
                {/* <Servicesnav linkClickClose={handleCloseTechnologies} /> */}
                <TechnologiesNav linkClickClose={handleCloseTechnologies} />
              </Subnavigation>
            </>
          )
        }
      </header>

      {
        toggle && <Sidebar onClose={() => setToggle(false)} />
      }


    </>
  )
}

export default Header
