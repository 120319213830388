import React from 'react';
import './contactform.scss';
import { useState } from 'react';
import ContactPhoneIcon from '../Footer/ContactPhoneIcon';
import PhoneInput from 'react-phone-number-input';
import EmailSvg from '../../pages/contactus/EmailSvg';
import HelpDeskSvg from '../../pages/aboutus/HelpDeskSvg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function ContactForm({ descriptionText }) {
    const [nameInput, setNameInput] = useState('');
    const [emailInput, setEmailInput] = useState('');
    const [numInput, setNumInput] = useState('');
    const [textAreaInput, setTextAreaInput] = useState('');


    function handleNameChange(event) {
        setNameInput(event.target.value);
    }

    function handleEmailChange(event) {
        setEmailInput(event.target.value);
    }

    function handleNumChange(value) {
        setNumInput(value);
    }

    function handleTextareaChange(event) {
        setTextAreaInput(event.target.value);
    }

    const saveContactInfoApi = async () => {
        try {
            const res = await fetch('https://tiny.diadna.com/api/send-contact-mail', {
                method: 'POST',
                body: JSON.stringify({
                    name: nameInput,
                    email: emailInput,
                    phoneNo: numInput,
                    message: textAreaInput
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            });

            const resUp = res.json();

        } catch (e) {

        }
    }

    function handleFormSubmit(event) {
        event.preventDefault();

        if (!/^[a-zA-Z]+$/.test(nameInput) || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailInput) || !/^\d{10}$/.test(numInput)) {

            if (nameInput === '' || emailInput === '' || !numInput) {
                toast.error('Please fill all the required fields.', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            }
            else {
                toast.info('Please provide valid data.', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
        else {
            //Call Contact Submit API Here
            const reqBody = {
                name: nameInput,
                email: emailInput,
                phoneNo: numInput,
                brief: textAreaInput,
            };
            saveContactInfoApi();

            toast.success('Thank You for Contacting Us!', {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            setNameInput('');
            setEmailInput('');
            setNumInput('');
            setTextAreaInput('');
        }

    }

    return (
        <div className="aboutUs-section7-container">
            <div className="aboutUs-section7">
                <div className="section7-part1">
                    <div className="part1-item1">
                        <h1><span>Get In</span>Touch</h1>
                        <p
                            dangerouslySetInnerHTML={{ __html: descriptionText }}
                        ></p>
                    </div>

                    <div className="part1-item2">
                        <form>

                            <div className="formElement">
                                <label htmlFor="name">Name <span id="asterisk"> &#42;</span></label>
                                <input
                                    type="text"
                                    id="name"
                                    value={nameInput}
                                    onChange={handleNameChange}
                                    placeholder="Enter Your Name"
                                />
                            </div>

                            <div className="formElement">
                                <label htmlFor="email">Email <span id="asterisk"> &#42;</span></label>
                                <input type="email" id="email" value={emailInput} onChange={handleEmailChange} placeholder="info@email.com" />
                            </div>


                            <div className="formElement">
                                <label htmlFor="number">Mobile Phone <span id="asterisk"> &#42;</span></label>
                                {/* <span>
                                    <PhoneInput
                                        placeholder=""
                                        value={numInput}
                                        onChange={handleNumChange}
                                        defaultCountry="IN"
                                        countries={['IN']}
                                        // international = {false}
                                        addInternationalOption={false}
                                    // required={true}
                                    // rules={{ required: true }}
                                    />
                                </span> */}
                                <input type="text" id="number" value={numInput} onChange={(e) => setNumInput(e.target.value)} placeholder="9999999999" />
                            </div>


                            <div className="formElement">
                                <label htmlFor="description">Brief about the project</label>
                                <textarea value={textAreaInput} onChange={handleTextareaChange} placeholder="Brief about the project" id="description" />
                            </div>


                            <button onClick={handleFormSubmit} >SUBMIT</button>

                        </form>
                        <ToastContainer
                            position="top-center"
                            autoClose={2000}
                            limit={0}
                            hideProgressBar={true}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss={false}
                            draggable={false}
                            pauseOnHover={false}
                            theme="light"
                        />
                    </div>

                    <div className="part1-item3">
                        <div className="social-info-1">
                            <ContactPhoneIcon />
                            <div className="social-info-1-text">
                                <p>PHONE</p>
                                <p>+91-98253-44145</p>
                            </div>
                        </div>

                        <div className="social-info-2">
                            <EmailSvg />
                            <div className="social-info-2-text">
                                <p>EMAIL</p>
                                <p>Hr@infineit.in</p>
                            </div>
                        </div>

                        {/* <div className="social-info-3">
                            <HelpDeskSvg />
                            <div className="social-info-3-text">
                                <p>HELPDESK</p>
                                <p><a href="/">https://helpdesk.com</a></p>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="section7-part2">
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1607.3489712901455!2d72.73934813853678!3d21.184744098332967!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04d5b4664366f%3A0x443111a69e97d9c9!2sFinestar%20Jewellary%20%26%20Diamonds%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1682937109932!5m2!1sen!2sin" height="591px" style={{ border: 'none', boxShadow: '1px 1px 15px 1px #2B334240' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14880.699857242518!2d72.7402047!3d21.1852074!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04da46762dec3%3A0x837bd8480eb07378!2sInFineIT%20Global%20Services%20LLP!5e0!3m2!1sen!2sin!4v1684991402828!5m2!1sen!2sin" height="591px" style={{ border: 'none', boxShadow: '1px 1px 15px 1px #2B334240' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>

        </div>
    );
}

export default ContactForm;