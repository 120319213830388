import React from "react";
import "./technologiesnav.scss"
import { Link } from "react-router-dom";
import InfineItlogo from "../svg/InfineItlogo";
import scrollToTop from "../../helper/scrollToTop";

import netCoreImage from "../../assets/technologyMobileNavigation/net-core.svg"
import AdobeImage from "../../assets/technologyMobileNavigation/adobe.svg"
import androidImage from "../../assets/technologyMobileNavigation/android.svg"
import angularImage from "../../assets/technologyMobileNavigation/angular.svg"
import cloudImage from "../../assets/technologyMobileNavigation/cloud.svg"
import figmaImage from "../../assets/technologyMobileNavigation/figma.svg"
import flutterImage from "../../assets/technologyMobileNavigation/flutter.svg"
import iosImage from "../../assets/technologyMobileNavigation/ios.svg"
import javaImage from "../../assets/technologyMobileNavigation/java.svg"
import mongodbImage from "../../assets/technologyMobileNavigation/mongodb.svg"
import mysqlImage from "../../assets/technologyMobileNavigation/mysql.svg"
import nodejsImage from "../../assets/technologyMobileNavigation/nodejs.svg"
import pythonImage from "../../assets/technologyMobileNavigation/python.svg"
import oracleImage from "../../assets/technologyMobileNavigation/oracle.svg"
import postgresqlImage from "../../assets/technologyMobileNavigation/postgresql.svg"
import reactImage from "../../assets/technologyMobileNavigation/react.svg"
import reactnativeImage from "../../assets/technologyMobileNavigation/reactnative.svg"
import sketchImage from "../../assets/technologyMobileNavigation/sketch.svg"
import sqlserverImage from "../../assets/technologyMobileNavigation/sqlserver.svg"
import testingImage from "../../assets/technologyMobileNavigation/testing.svg"
import vueImage from "../../assets/technologyMobileNavigation/vuejs.svg"
import xamarinImage from "../../assets/technologyMobileNavigation/xamarin.svg"


const TechnologiesNav = ({ linkClickClose }) => {

    return (
        <div className="technologyNav">
            <div className="logo">
                <InfineItlogo />
            </div>

            {
                technologiesData.map((el, index) => {
                    return (
                        <div className="navContainer" key={el.title + index}>
                            {/* <Link to={`services/${el.link}`} className={"link"} > */}
                            <div className="heading">{el.title}</div>
                            <ul className="subNav">
                                {
                                    el.data.map((el, index) => {
                                        return (
                                            <li key={el.title + index} onClick={() => scrollToTop()}>
                                                <Link to={`/technologies/${el.sublink}`} onClick={linkClickClose} >{el.title}</Link>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            {/* </Link> */}
                        </div>
                    )
                })
            }
        </div>
    );
};

export default TechnologiesNav;

const technologiesData = [
    {
        title: "Backend",
        // link : "software-development",
        data: [
            {
                title: ".NET Core",
                sublink: 'net-core',
                img : netCoreImage
            },
            {
                title: "Node.JS",
                sublink: 'nodejs',
                img : nodejsImage
            },
            {
                title: "Java",
                sublink: 'java',
                img : javaImage
            },
            {
                title: "Python",
                sublink: 'python',
                img : pythonImage
            
            }
        ]
    },
    {
        title: "Frontend",
        // link : "application-development",
        data: [
            {
                title: 'React',
                sublink: 'react',
                img : reactImage
            },
            {
                title: "Vue.JS",
                sublink: 'vuejs',
                img : vueImage
            },
            {
                title: 'Angular',
                sublink: 'angular',
                img : angularImage
            }
        ]
    },
    {
        title: "Mobility",
        // link : "web-development",
        data: [
            {
                title: "iOS",
                sublink: 'ios',
                img : iosImage
            },
            {
                title: "Android",
                sublink: 'android',
                img : androidImage
            },
            {
                title: "Flutter",
                sublink: 'flutter',
                img : flutterImage
            },
            {
                title: "React Native",
                sublink: 'reactnative',
                img : reactnativeImage
            },
            {
                title: "Xamarin",
                sublink: 'xamarin',
                img : xamarinImage
            },
        ]
    },
    {
        title: "Database",
        // link : "product-development",
        data: [
            {
                title: "SQL Server",
                sublink: 'sqlserver',
                img : sqlserverImage
            },
            {
                title: "Oracle",
                sublink: 'oracle',
                img : oracleImage
            },
            {
                title: "PostgreSQL",
                sublink: 'postgresql',
                img : postgresqlImage
            },
            {
                title: "MySQL",
                sublink: 'mysql',
                img : mysqlImage
            },
            {
                title: "MongoDB",
                sublink: 'mongodb',
                img : mongodbImage
            }
        ]
    },
    {
        title: "Designing",
        // link : "uiux-design",
        data: [
            {
                title: "Figma",
                sublink: 'figma',
                img : figmaImage
            },
            {
                title: "Adobe XD",
                sublink: 'adobe',
                img : AdobeImage
            },
            {
                title: "Sketch",
                sublink: 'sketch',
                img : sketchImage
            }
        ]
    },
    {
        title: "Other",
        // link : "dedicated-development-team",
        data: [
            {
                title: "Cloud & DevOps",
                sublink: 'cloud',
                img : cloudImage
            },
            {
                title: "Testing",
                sublink: 'testing',
                img : testingImage
            }
        ]
    }
]

export { technologiesData }
