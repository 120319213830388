import React from 'react'

import Imagedescription from '../../components/imagedescription/Imagedescription';
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';
import ProcessServices from '../../components/processServices/ProcessServices';
import Recentreviews from '../../components/recentReviews/Recentreviews';
import ContactForm from '../../components/contactus/ContactForm';

import mainImage from "../../assets/services/Dedicated Development Team/Main Dedicated Development Team.svg";

import Icons1 from "../../assets/services/Dedicated Development Team/Our Services/Frame 3466398.svg"
import Icons2 from "../../assets/services/Dedicated Development Team/Our Services/Frame 3466398-1.svg"


import processImage from '../../assets/services/processSection/dedicatedTEam.png'

const ImageDescriptiondata = {
  title: "Dedicated Development Team",
  subtitle: "Dedicated Development Team",
  description: [
    `At InFineIT Solutions, we offer dedicated team development services to help businesses achieve their goals. Our dedicated teams consist of skilled software developers, project managers, and quality assurance specialists who work exclusively on your project. This approach offers several benefits:`
  ],
  listItem: [
    "Customization",
    "Flexibility",
    "Improved communication",
    "Cost-effectiveness",
    "Expertise",
    "Transparency"
  ],
  image: mainImage,

};
const ourServicesData = {
  subtitle: "What we Do",
  title: "Our Services",
  description: [
    `Our Dedicated Development Team services are ideal for businesses that require long-term, complex software development projects. Contact us today to learn more about how our dedicated teams can help you achieve your goals.`
  ],
  button: {
    title: "Book A Free Consultation",
    url: "/contactus",
  },
  cardList: [
    {
      icon: Icons1,
      title: "IT Staff Augmentation:",
      description: `To bridge the gap between the demand and supply for technical talent, make use of our client-focused, world-class IT staff augmentation services. Choose one or more devoted software developers from our qualified pool of experts to manage the project duties in line with the organization's changing needs. Experience the best in IT with InFineIT Solutions, a company with the experience of completing several projects across industries, skill-specific hiring, and a high client retention rate.`
    },
    {
      icon: Icons2,
      title: "Dedicate Development Team:",
      description: "By giving customers the opportunity to employ a specialized development team, business analysts, DevOps engineers, testers, quality assurance specialists, and project managers to oversee the daily operations of the core team, InFineIT Solution enables customers to focus on their core business skills. The main values of InFineIT are transparency, smooth reporting, and scalable options. The company's technical experts find business gaps and recommend long-term solutions that are advantageous to clients."
    },
  ]
};

const Dediccateddevelopmentteam = () => {
  return (
    <>
      <Imagedescription
        image={ImageDescriptiondata.image}
        title={ImageDescriptiondata.title}
        subtitle={ImageDescriptiondata.subtitle}
        description={ImageDescriptiondata.description}
        listItem={ImageDescriptiondata.listItem}
      />

      <Toolstouse
        isTesting={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={ourServicesData.title}
        subtitle={ourServicesData.subtitle}
        description={ourServicesData.description}
        buttonText={ourServicesData.button.title}
        buttonURl={ourServicesData.button.url}
        cartListItem={ourServicesData.cardList}
      />

      <ProcessServices
        title={'Dedicated Development Team process'}
        img={processImage}
      />

      {/* <Recentreviews /> */}

      <ContactForm
        descriptionText={"Fill out the information below. To get more information about Product <br/> Development and its services."}
      />

    </>
  );
}

export default Dediccateddevelopmentteam
