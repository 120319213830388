import React from 'react'
import testingSVG from "../../assets/services/Tools/Testin.svg"

const Testingtools = () => {
    
  return (
    <img src={testingSVG}/>
  )
}

export default Testingtools
