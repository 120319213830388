import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import mySqlSvg from '../../assets/TechnologiesSvg/mySqlSvg.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessMySql from '../../assets/TechnologiesSvg/ourProcessMySql.svg';
import HireUsNow from '../../components/HireUsNow/HireUsNow';


function MySql() {
  const mySqlData =
  {
    title: 'MySQL',
    titleDescription: ['Open-source relational database management system (RDBMS) MySQL is a common choice for business, mobile, and web applications. Small websites to expansive enterprise applications can all benefit greatly from its simplicity of use, adaptability, and scalability.',

      'MySQL is the best option for applications that need quick and effective data management since it has strong data storage and retrieval capabilities. With its support for SQL, developers can deal with data using a familiar syntax and create intricate database structures that satisfy the needs of their applications.'],
    mainSvg: '',
  };

  const processDataMySQL = [
    `<span className="li-header">Open-Source:</span> MySQL is open-source software, which means that it is free to use and can be modified according to the user's needs.`,

    `<span className="li-header">Reliability:</span> MySQL is a highly reliable and robust database management system that is used by some of the largest companies and websites in the world.`,

    `<span className="li-header">Scalability:</span> MySQL can easily handle large amounts of data and can scale to accommodate increasing amounts of data and users.`,

    `<span className="li-header">Ease of use:</span> MySQL is user-friendly and easy to set up, making it accessible to users of all technical skill levels.`,

    `<span className="li-header">Widely used:</span> MySQL is one of the most widely used database management systems in the world, with a large community of developers and users who can provide support and advice.`,

    `<span className="li-header">Integration:</span> MySQL can easily integrate with various programming languages and frameworks, making it a versatile option for a variety of applications.`,

    `<span className="li-header">Widely used:</span> MySQL is designed for high performance and can handle complex queries and transactions quickly and efficiently.`,

  ];

  const processDescription = `MySQL provides a number of key features that make it an attractive choice for IT-based companies:`;

  return (
    <div className="container-technology">
      <Imagedescription
        image={mySqlSvg}
        title={mySqlData.title}
        subtitle={mySqlData.title}
        description={mySqlData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <OurProcedure
        heading="MYSQL"
        subHeading="Why choose MySQL?"
        mainSvg={ourProcessMySql}
        procedureData={processDataMySQL}
        endText={`
        Overall, MySQL is a versatile and powerful database management system that can meet the needs of IT-based companies of all sizes. Whether you need to support transactional workloads, business intelligence and analytics, or a combination of both, Oracle provides the tools and features you need to succeed.`}
        processDescription={processDescription}
      />

      <HireUsNow
        techName='SQL'
      />
    </div>
  );
}

export default MySql;