import React from 'react';
import './technologies.scss';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import adobeSvg from '../../assets/TechnologiesSvg/adobe.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessAdobe from '../../assets/TechnologiesSvg/ourProcessAdobe.svg'
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';


function Adobe() {
  const figmaData =
  {
    title: 'Adobe XD',
    titleDescription: ['User experience design software by Adobe Systems is called Adobe XD (Experience Design). It is an effective tool for creating user interface prototypes for websites, mobile applications, and other digital products.',

      'In order to build user interfaces, Adobe XD offers a simple and user-friendly interface that enables designers to quickly produce wireframes, prototypes, and design specifications. It provides a range of design materials and tools that make it simple to generate and improve designs, such as grids, restrictions, and vector networks.',

      'Additionally, Adobe XD offers strong prototype and collaboration tools that let designers share their work with other parties and receive feedback in real time. This improves the effectiveness of the design process by hastening the design review procedure and decreasing the need for changes.'
    ],
    mainSvg: '',
  };

  const processDataAdobe = [
    `<span className="li-header">Improved Design Process: </span> Adobe XD's streamlined interface and powerful design tools make it easier for designers to create wireframes, prototypes, and design specs, improving the efficiency of the design process.`,

    `<span className="li-header">Enhanced Collaboration:</span> Adobe XD's collaboration features allow designers, developers, and stakeholders to work together more effectively and get feedback in real-time, speeding up the design review process and reducing the need for revisions.`,

    `<span className="li-header">Seamless Integration:</span> Adobe XD integrates seamlessly with other Adobe products and other design tools, making it easy to move designs from design to development and streamlining the product development process.`,

    `<span className="li-header">Access to Adobe's Creative Tools and Resources:</span> Adobe XD is available as a part of Adobe Creative Cloud, which provides access to all of Adobe's creative tools and resources, including Photoshop, Illustrator, and more.`,

    `<span className="li-header">Improved User Experience:</span> Adobe XD's focus on user experience design makes it an excellent choice for companies looking to create high-quality digital products that meet the needs of their users.`,
  ];

  const processDescription = `Offering Adobe XD development services through your IT-based company website can provide several benefits to your customers, including:`;

  const GeneralOurServicesData = [
    {
      icon: null,
      title: "Custom software development using .NET Core",
      description: null
    },
    {
      icon: null,
      title: "Integration of .NET Core applications with other systems",
      description: null
    },
    {
      icon: null,
      title: "Maintenance and support for existing .NET Core applications",
      description: null
    },
    {
      icon: null,
      title: "Migration of existing applications to .NET Core",
      description: null
    },
    {
      icon: null,
      title: "Consulting and advising on .NET Core best practices and technology roadmap.",
      description: null
    },
    {
      icon: null,
      title: "SaaS based Application Development",
      description: null
    },
    {
      icon: null,
      title: "Re-engineering Legacy Application with .NET",
      description: null
    },
    {
      icon: null,
      title: "CRM, POS, CMS & other product development",
      description: null
    }

  ]

  return (
    <div className="container-technology">
      <Imagedescription
        image={adobeSvg}
        title={figmaData.title}
        subtitle={figmaData.title}
        description={figmaData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={'.Net Development Services'}
        subtitle={'Services'}
        description={
          [`.NET Core is an open-source, cross-platform framework for building modern applications. Development services for .NET Core typically include 
         designing, coding, testing, and deploying applications built using the framework. This can encompass a wide range of application types, including web, mobile, 
         desktop, and IoT applications. Some specific services offered within the scope of .NET Core development may include:`]}
        buttonText={null}
        buttonURl={null}
        cartListItem={GeneralOurServicesData}
      />

      <OurProcedure
        heading="ADOBE XD"
        subHeading="Why choose AdobeXD?"
        mainSvg={ourProcessAdobe}
        procedureData={processDataAdobe}
        endText={
          `We offer Adobe XD development services, we can help your customers take advantage of these benefits and improve their digital product development process. We can 
           provide the support and expertise they need to get the most out of Adobe XD and ensure the success of their projects.`}
        processDescription={processDescription}
      />

    </div>
  );
}

export default Adobe;