import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import angSvg from '../../assets/TechnologiesSvg/angSvg.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessAngular from '../../assets/TechnologiesSvg/ourProcessAngular.svg';
import HireUsNow from '../../components/HireUsNow/HireUsNow';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';

function Angular() {

  const angData =
  {
    title: 'Angular',
    titleDescription: ['A well-liked open-source JavaScript framework for creating dynamic single-page applications is called Angular. It is utilized by enterprises and organizations of all sizes for a range of tasks, including creating intricate, customer-facing apps as well as internal tools.',

      'Our IT company has a group of skilled Angular developers who are experts in providing tailored solutions that are tailored to the individual requirements of our clients. Our team can provide top-notch, scalable solutions because we have a thorough understanding of the framework and its capabilities.'],
    mainSvg: '',
  };

  const processDataAngular = [
    `<span className="li-header">Two-Way Data Binding:</span> Angular's two-way data binding makes it easier for developers to build dynamic applications that respond to changes in real-time.`,

    `<span className="li-header">Reusable Components:</span>  Angular provides a set of reusable components that can be used to build complex applications, making it easier for businesses to add new features and functionality over time.`,

    `<span className="li-header">Scalable Architecture:</span> Angular's architecture is designed for scalability, making it a great choice for businesses that need to build applications that can grow and change over time.`,

    `<span className="li-header">Improved User Experience:</span> Angular provides a rich set of tools and features that make it easier for businesses to build applications with a responsive and user-friendly interface.`,

    `<span className="li-header">Large Community of Developers:</span> Angular has a large and active community of developers, which means that there is a wealth of knowledge and resources available to businesses and organizations that are using the framework.`
  ];

  const processDescription = `Some of the key benefits of using Angular include:`;

  const GeneralOurServicesData = [
    {
      icon: null,
      title: "Custom App Development",
      description: null
    },
    {
      icon: null,
      title: "Web App Modernization",
      description: null
    },
    {
      icon: null,
      title: "API Development & Integration",
      description: null
    },
    {
      icon: null,
      title: "Custom UI/UX Design using Angular",
      description: null
    },
    {
      icon: null,
      title: "Staff Augmentation",
      description: null
    },
    {
      icon: null,
      title: "Maintenance & Support",
      description: null
    }
  ];

  return (
    <div className="container-technology">
      <Imagedescription
        image={angSvg}
        title={angData.title}
        subtitle={angData.title}
        description={angData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={'Angular Development Services'}
        subtitle={'Services'}
        description={[`Our IT company offers a range of Angular development services, including custom software development, web application development, and user interface and experience (UI/UX) design. Our team of experienced Angular developers is able to leverage the full capabilities of the framework to deliver high-quality, scalable solutions that meet the specific needs of our clients.`]}
        buttonText={null}
        buttonURl={null}
        cartListItem={GeneralOurServicesData}
      />

      <OurProcedure
        heading="ANGULAR DEVELOPMENT"
        subHeading="Why choose Angular?"
        mainSvg={ourProcessAngular}
        procedureData={processDataAngular}
        endText={`
        Our team of Angular developers is dedicated to delivering high-quality, custom solutions that meet the specific needs of our clients. Whether you're looking to build a new application or modernize an existing one, our team has the expertise and experience to deliver a solution that meets your needs. Contact us today to learn more about how we can help you achieve your goals with Angular.`}
        processDescription={processDescription}
      />

      <HireUsNow
        techName='Angular'
      />
    </div>
  );
}

export default Angular;