import React from 'react'
import { Link } from 'react-router-dom'
import './hireusnow.scss'
import scrollToTop from '../../helper/scrollToTop';

function HireUsNow({ techName }) {
  return (
    <div className="container-hireusnow">
        <div className='section1-hireusnow'>
            <h1>Hire A Dedicated Team Of {techName} Developers</h1>
            <p>Get access to a specialized set of skilled developers for your unique requirements on {techName} projects</p>
        </div>
        <div className='section2-hireusnow'>
            <Link to = '/contactus'><button onClick={() => scrollToTop()} type='button'>Hire Us Now!</button></Link> 
        </div>
    </div>
  )
}

export default HireUsNow