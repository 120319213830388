import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import sqlServerSvg from '../../assets/TechnologiesSvg/sqlServerSvg.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessSqlServer from '../../assets/TechnologiesSvg/ourProcessSqlServer.svg';
import HireUsNow from '../../components/HireUsNow/HireUsNow';


function SqlServer() {
  const sqlServerData =
  {
    title: 'SQL Server',
    titleDescription: ['Microsoft created the relational database management system known as Microsoft SQL Server. It is a software product known as a database server, and its main job is to store and retrieve data when other software applications, which may operate on the same computer or on a different computer over a network, require it (including the Internet).',

      'Microsoft offers at least a dozen different editions of SQL Server that are targeted at various clienteles and can handle workloads ranging from modest single-machine programmes to huge internet-facing programmes with several concurrent users.'],
    mainSvg: '',
  };

  const processDataSqlSever = [
    `<span className="li-header">Scalability and Performance:</span> SQL Server can scale from small, single-user databases to large, multi-terabyte enterprises, providing high performance and reliability even as the size of your data grows.`,

    `<span className="li-header">Security:</span> SQL Server includes advanced security features such as transparent data encryption, row-level security, and dynamic data masking, allowing you to secure your data and meet regulatory requirements.`,

    `<span className="li-header">Data Integration and Management:</span> SQL Server provides a range of data integration and management tools that make it easy to connect to a variety of data sources, including Excel, Access, and Big Data sources. It also includes tools for data quality, data profiling, and data cleansing, making it easy to maintain high-quality data in your databases..`,

    `<span className="li-header">Business Intelligence and Analytics:</span> SQL Server includes a range of Business Intelligence (BI) and analytics tools that allow you to analyze data, create reports, and share insights with others. These tools include Power BI, SQL Server Analysis Services, and SQL Server Reporting Services, making it easy to visualize and understand your data.`,

    `<span className="li-header">Development and Management Tools:</span> SQL Server provides a range of development and management tools, including SQL Server Management Studio, SQL Server Data Tools, and Visual Studio, making it easy to develop, deploy, and manage your databases.`,

  ];

  const processDescription = `SQL Server provides a number of key features that make it an attractive choice for IT-based companies:`;

  return (
    <div className="container-technology">
      <Imagedescription
        image={sqlServerSvg}
        title={sqlServerData.title}
        subtitle={sqlServerData.title}
        description={sqlServerData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <OurProcedure
        heading="SQL SERVER"
        subHeading="Why Choose SQL Server?"
        mainSvg={ourProcessSqlServer}
        procedureData={processDataSqlSever}
        endText={`
            Overall, SQL Server is a versatile and powerful database management system that can meet the needs of IT-based companies of all sizes. Whether you need to support transactional workloads, business intelligence and analytics, or a combination of both, SQL Server provides the tools and features you need to succeed.`}
        processDescription={processDescription}
      />

      <HireUsNow
        techName='Sql Server'
      />
    </div>
  );
}

export default SqlServer;