import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import iosSvg from '../../assets/TechnologiesSvg/iosSvg.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessIos from '../../assets/TechnologiesSvg/ourProcessIos.svg';
import HireUsNow from '../../components/HireUsNow/HireUsNow';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';


function Ios() {
  const iOSData =
  {
    title: 'iOS',
    titleDescription: [`The iPhone and iPad, two of Apple's well-known mobile devices, run on the iOS operating system. iOS has grown to be one of the most widely used mobile platforms in the world because to its user-friendly interface, strong hardware, and diverse software ecosystem.`,

      `Our IT company has a group of skilled iOS developers who are committed to offering our customers cutting-edge, high-quality solutions. Our staff has the knowledge and experience to support you in achieving your objectives, whether you're looking to create a brand-new iOS app or update an existing one.`],
    mainSvg: '',
  };

  const processDataIos = [
    `<span className="li-header">Access to a Wide User Base:</span> With over 1 billion active iOS devices around the world, developing for iOS provides access to a large and growing user base.`,

    `<span className="li-header">High-Quality Hardware:</span> Apple's commitment to high-quality hardware and design ensures that iOS devices are fast, reliable, and offer a great user experience.`,

    `<span className="li-header">Robust App Ecosystem:</span> The iOS App Store is home to millions of high-quality apps, providing users with access to a wide range of content and services.`,

    `<span className="li-header">Strong Development Tools:</span> Apple provides a suite of powerful development tools, including XCode and Swift, that make it easy for developers to create high-quality iOS apps.`,
  ];

  const processDescription = `Some of the benefits of developing for iOS include:`;

  const GeneralOurServicesData = [
    {
      icon: null,
      title: "Custom App Development",
      description: null
    },
    {
      icon: null,
      title: "App Modernization",
      description: null
    },
    {
      icon: null,
      title: "API Development & Integration",
      description: null
    },
    {
      icon: null,
      title: "Custom UI/UX Design",
      description: null
    },
    {
      icon: null,
      title: "Staff Augmentation",
      description: null
    },
    {
      icon: null,
      title: "Maintenance & Support",
      description: null
    }
  ];

  return (
    <div className="container-technology">
      <Imagedescription
        image={iosSvg}
        title={iOSData.title}
        subtitle={iOSData.title}
        description={iOSData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={'iOS Development Services'}
        subtitle={'Services'}
        description={[`Our IT company offers a comprehensive range of iOS development services, including custom app development, app modernization, and UI/UX design. Our team of experienced iOS developers has a deep understanding of the iOS platform and is dedicated to delivering high-quality solutions that meet the specific needs of our clients.`]}
        buttonText={null}
        buttonURl={null}
        cartListItem={GeneralOurServicesData}
      />

      <OurProcedure
        heading="IOS DEVELOPMENT"
        subHeading="Why Choose iOS?"
        mainSvg={ourProcessIos}
        procedureData={processDataIos}
        endText={`
            Our team of iOS developers has extensive experience in developing custom solutions for a wide range of clients, from small businesses to large enterprises. Whether you're looking to build a new app or modernize an existing one, our team has the expertise and experience to help you achieve your goals. Contact us today to learn more about how we can help you with your iOS development needs.`}
        processDescription={processDescription}
      />

      <HireUsNow
        techName='iOS'
      />
    </div>
  );
}

export default Ios;