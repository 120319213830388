import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import androidSvg from '../../assets/TechnologiesSvg/androidSvg.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessAndroid from '../../assets/TechnologiesSvg/ourProcessAndroid.svg';
import HireUsNow from '../../components/HireUsNow/HireUsNow';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';


function Android() {
  const androidData =
  {
    title: 'Android',
    titleDescription: ['Over 2 billion active devices worldwide are running the most widely used mobile operating system, Android. Android, which was created by Google, provides a vast array of features and capabilities, making it the perfect platform for a range of mobile applications.',

      'Our IT company has a group of skilled Android developers who are committed to offering our customers cutting-edge, high-quality solutions. Whether you want to create a brand-new Android app or update an existing one, our team has the knowledge and experience to support you in reaching your objectives.'],
    mainSvg: '',
  };

  const processDataAndroid = [
    `<span className="li-header">Wide Reach:</span> With over 2 billion active Android devices, developing for Android provides access to a massive user base.`,

    `<span className="li-header">Customizable:</span> Android is an open-source platform, allowing for greater customization and flexibility compared to other mobile operating systems.`,

    `<span className="li-header">Robust Development Tools:</span> Google provides a suite of powerful development tools, including Android Studio, that make it easy for developers to create high-quality Android apps.`,

    `<span className="li-header">Access to Google Services:</span> Android devices come pre-loaded with popular Google services such as Gmail, Google Maps, and Google Assistant, providing users with access to a wide range of content and services.`,
  ];

  const processDescription = `Some of the benefits of developing for Android include:`;

  const GeneralOurServicesData = [
    {
      icon: null,
      title: "Custom App Development",
      description: null
    },
    {
      icon: null,
      title: "App Modernization",
      description: null
    },
    {
      icon: null,
      title: "API Development & Integration",
      description: null
    },
    {
      icon: null,
      title: "Custom UI/UX Design",
      description: null
    },
    {
      icon: null,
      title: "Maintenance & Support",
      description: null
    }
  ];

  return (
    <div className="container-technology">
      <Imagedescription
        image={androidSvg}
        title={androidData.title}
        subtitle={androidData.title}
        description={androidData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={'Android Development Services'}
        subtitle={'Services'}
        description={
          [`Our IT company offers a range of professional Android development services to help clients build, maintain and enhance their mobile applications. Our team of experienced Android developers has a deep understanding of the platform and is dedicated to delivering high-quality, innovative solutions that meet the specific needs of our clients.`]}
        buttonText={null}
        buttonURl={null}
        cartListItem={GeneralOurServicesData}
      />

      <OurProcedure
        heading="ANDROID DEVELOPMENT"
        subHeading="Why choose android?"
        mainSvg={ourProcessAndroid}
        procedureData={processDataAndroid}
        endText={`
            Our team of Android developers has extensive experience in developing custom solutions for a wide range of clients, from small businesses to large enterprises. Whether you're looking to build a new app or modernize an existing one, our team has the expertise and experience to help you achieve your goals. Contact us today to learn more about how we can help you with your Android development needs.`}
        processDescription={processDescription}
      />

      <HireUsNow
        techName='Android'
      />
    </div>
  );
}

export default Android;