import React from "react";

const MenuIcon = () => {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.75 1.5C0.75 0.80965 1.30965 0.25 2 0.25H22C22.6904 0.25 23.25 0.80965 23.25 1.5C23.25 2.19035 22.6904 2.75 22 2.75H2C1.30965 2.75 0.75 2.19035 0.75 1.5ZM0.75 9C0.75 8.30962 1.30965 7.75 2 7.75H22C22.6904 7.75 23.25 8.30962 23.25 9C23.25 9.69037 22.6904 10.25 22 10.25H2C1.30965 10.25 0.75 9.69037 0.75 9ZM0.75 16.5C0.75 15.8096 1.30965 15.25 2 15.25H22C22.6904 15.25 23.25 15.8096 23.25 16.5C23.25 17.1904 22.6904 17.75 22 17.75H2C1.30965 17.75 0.75 17.1904 0.75 16.5Z"
        fill="#2B3342"
      />
    </svg>
  );
};

export default MenuIcon;
