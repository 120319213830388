import React from 'react';
import Imagedescription from '../../components/imagedescription/Imagedescription';
import sketchSvg from '../../assets/TechnologiesSvg/sketch.svg'
import Toolstouse from '../../components/Toolstouse/Toolstouse';
import OurProcedure from '../../components/ourProcedure/OurProcedure';
import ourProcessSketch from '../../assets/TechnologiesSvg/ourProcessSketch.svg';
import GeneralOurServices from '../../components/ourServicesInServices/GeneralOurServices';


function Sketch() {
  const sketchData =
  {
    title: 'Sketch',
    titleDescription: ['User interfaces, websites, and other digital goods can be designed with the digital tool Sketch. Sketch, which was created especially for Macs, offers designers a simple and user-friendly interface for creating and developing user experiences.',

      `Vector networks, grids, and limits are just a few of the design tools and resources that Sketch provides, all of which make it simple to create and improve designs. Additionally, it offers strong prototyping and collaboration tools, enabling designers to share their work with stakeholders and get immediate feedback. This improves the effectiveness of the design process by hastening the design review procedure and decreasing the need for changes.`],
    mainSvg: '',
  };

  const processDataSketch = [
    `<span className="li-header">Streamlined Design Process:</span> Sketch's intuitive interface and powerful design tools make it easier for designers to create wireframes, prototypes, and design specs, improving the efficiency of the design process.`,

    `<span className="li-header">Enhanced Collaboration:</span> Sketch's collaboration features allow designers, developers, and stakeholders to work together more effectively and get feedback in real-time, speeding up the design review process and reducing the need for revisions.`,

    `<span className="li-header">Seamless Integration:</span> Sketch integrates seamlessly with other design tools and platforms, making it easy to move designs from design to development and streamlining the product development process`,

    `<span className="li-header">Affordable Option:</span> Sketch is available as a standalone product, making it an affordable option for small businesses and independent designers.`,

    `<span className="li-header">Mac-Specific Tool:</span> As a Mac-specific tool, Sketch is an excellent choice for companies that primarily use Apple products and prefer to use tools that are native to their operating system.`,

  ];

  const GeneralOurServicesData = [
    {
      icon: null,
      title: "UI/UX Designing",
      description: null
    },
    {
      icon: null,
      title: "Prototype Development",
      description: null
    },
    {
      icon: null,
      title: "Integration Service",
      description: null
    },
    {
      icon: null,
      title: "Application Designing",
      description: null
    },
    {
      icon: null,
      title: "Website Designing",
      description: null
    },
    {
      icon: null,
      title: "Software Designing",
      description: null
    }
  ];

  return (
    <div className="container-technology">
      <Imagedescription
        image={sketchSvg}
        title={sketchData.title}
        subtitle={sketchData.title}
        description={sketchData.titleDescription}
        styleImage={'technology-image'}
      />

      <Toolstouse
        isTechnologies={false}
        isWireframe={false}
        isVisualDesign={false}
        isDesignAssests={false}
      />

      <GeneralOurServices
        title={'Adobe XD Service'}
        subtitle={'Services'}
        description={[`Our IT company provides professional Sketch development services to help clients build high-quality, cross-platform mobile , Web, & Software Design. Our team of experienced Sketch developers has a deep understanding of the Designing and is dedicated to delivering innovative solutions that meet the specific needs of our clients.`]}
        buttonText={null}
        buttonURl={null}
        cartListItem={GeneralOurServicesData}
      />

      <OurProcedure
        heading="SKETCH"
        subHeading="Why Choose Sketch?"
        mainSvg={ourProcessSketch}
        procedureData={processDataSketch}
        endText={`
            We offer Sketch development services, we can help your customers take advantage of these benefits and improve their digital product development process. We can provide the support and expertise they need to get the most out of Sketch and ensure the success of their projects.`}
        processDescription={false}
      />

    </div>
  );
}

export default Sketch;