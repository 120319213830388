import React from 'react'
import Hero from '../../components/hero/Hero';
import Ourservices from '../../components/ourServices/Ourservices';
import Whychooseus from '../../components/whychooseus/Whychooseus';
import Technologies from '../../components/technologies/Technologies';
import ContactForm from '../../components/contactus/ContactForm';
import Completepackage from '../../components/completePackage/Completepackage';
import Recentreviews from '../../components/recentReviews/Recentreviews';
import './home.scss';

const Home = () => {
  return (
    <>
      <div className="container-home">
        <Hero />
        <Ourservices />
        <Whychooseus />
        <Technologies />
        <Completepackage />
        {/* <Recentreviews /> */}
        <ContactForm descriptionText={`We'd love to hear from you! Whether you have a question, suggestion, or<br /> just want to say hello, we're here to help.`} />
      </div>
    </>
  )
}

export default Home;
