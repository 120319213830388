import React, { useState, useEffect, useRef } from 'react';
import './ourvacancies.scss';
import { vacancyInfoD } from './ourvacancyinfo';
import TextField from '@mui/material/TextField'
import Confetti from 'react-confetti'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


function OurVancancies() {

    const [openCard, setOpenCard] = useState('');
    const [openModal, setOpenModal] = useState('');
    const [fName, setFName] = useState('');
    const [lName, setLName] = useState('');
    const [eMail, setEMail] = useState('');
    const [mNumber, setMNumber] = useState('');
    const [userResume, setUserResume] = useState();
    const [successModal, setSuccessModal] = useState('');
    const [fNameError, setFNameError] = useState(false);
    const [lNameError, setLNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [fileError, setFileError] = useState(true);
    const [uploadRes, setUploadRes] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [hiringPosData, setHiringPosData] = useState([]);

    //Handles When User closes the Hiring Modal Without Submitting
    function handleHiringModal(e, index) {
        e.stopPropagation();
        if (openModal === index) {
            setOpenModal('');
        }
        else {
            setOpenModal(index);
        }

        setFName('');
        setLName('');
        setEMail('');
        setMNumber('');

        setFNameError(false);
        setLNameError(false);
        setEmailError(false);
        setPhoneError(false);
    }

    function handleCancelClick(e, index) {
        e.stopPropagation();
        if (openModal === index) {
            setOpenModal('');
        }
        else {
            setOpenModal(index);
        }


    }

    //Handles when user submits the form successfully
    function handleSuccessModal(e, index) {
        e.stopPropagation();
        if (openModal === index) {
            setOpenModal(false);
        }
        else {
            setOpenModal(true);
        }
        if (successModal === index) {
            setSuccessModal('');
        }
        else {
            setSuccessModal(index);
        }

    }

    //Handles Hiring Form Validation Check
    function handleApplySubmitClick(e, index) {
        e.stopPropagation();

        if (fName === '' || lName === '' || eMail === '' || mNumber === '') {
            toast.info('Please fill all the fields.', {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
        }
        else {
            if ((!fNameError && !lNameError && !emailError && !phoneError && !fileError)) {

                if (openModal === index) {
                    setOpenModal(false);
                }
                else {
                    setOpenModal(true);
                }

                if (successModal === index) {
                    setSuccessModal('');
                }
                else {
                    //Call Hiring Form Submit API Here
                    const reqBody = {
                        firstName: fName,
                        lastName: lName,
                        email: eMail,
                        phoneNo: mNumber,
                        filePath: 'path received by the Resume Upload API',
                    }
                    handleApplyApi(reqBody, index);
                    setSuccessModal(index);
                }

                setFName('');
                setLName('');
                setEMail('');
                setMNumber('');
                setUploadRes({});
                setFileError(true);

            }
            else {
                toast.info('Please provide valid data.', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    }
    const handleApplyApi = async (reqBody, index) => {
        try {
            setIsLoading(true);
            const response = await fetch('https://tiny.diadna.com/api/send-mail', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...reqBody, filename: uploadRes?.filename, filePath: uploadRes?.path })
            });

            setIsLoading(false);
            const responseUp = await response.json();

            if (responseUp?.code === 'OK') {
                setSuccessModal(index);
            }
            else {
                setSuccessModal('');
                toast.error('Resume could not be sent due to some server error.', {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
            }

        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }

    const hiringInfoApi = async () => {
        const res = await fetch('https://tiny.diadna.com/admin/hiring/paginate', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        });

        const resUp = await res.json();

        if (resUp.code === 'OK' && resUp.data.length) {
            setHiringPosData(resUp.data);
        }
        else {
            setHiringPosData([]);
        }

    }

    //Handles Modal Closing when clicked outside the form
    function handleCardClick(index) {
        if (index === openCard) {
            setOpenCard('');
        } else {
            setOpenCard(index);
        }

    }

    function handleChildClick(event) {
        event.stopPropagation();
    }

    // Form Handler Function
    function handleFormSubmit(e) {
        e.preventDefault();
    }

    function handleFirstName(e) {
        setFName(e.target.value);
    }

    function handleLastName(e) {
        setLName(e.target.value);
    }

    function handleEmail(e) {
        setEMail(e.target.value);
    }

    function handleMobNumber(e) {
        setMNumber(e.target.value);
    }


    function handleUserResume(e) {
        if (e.target.files[0].name) {
            setFileError(false);
            const file = e.target.files[0];
            const formData = new FormData();
            formData.append('file', file);

            fetch('https://tiny.diadna.com/api/upload', {
                method: "POST",
                body: formData
            }).then((response) => {
                return response.json();
            }).then((resUp) => {
                setUploadRes(resUp)
            }).catch((error) => {
                setIsLoading(false);
                console.log(error);
            })
        }
        else {
            setFileError(false);
        }
    }

    function handleFormPropagation(e) {
        e.stopPropagation();
    }


    //Form Validations Functions for Individual Field Validations
    function fNameCheck(e) {
        if (!/^[a-zA-Z]+$/.test(e.target.value)) {
            setFNameError(true);
        }
        else {
            setFNameError(false);
        }
    }

    function lNameCheck(e) {
        if (!/^[a-zA-Z]+$/.test(e.target.value)) {
            setLNameError(true);
        }
        else {
            setLNameError(false)
        }
    }

    function emailCheck(e) {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/gi;
        if (e.target.value === '' || !(emailRegex.test(e.target.value))) {
            setEmailError(true);
        }
        else {
            setEmailError(false);
        }
    }

    function phoneNumCheck(e) {
        const regexExp = /^[0-9]{10}$/;
        if (e.target.value === '' || !regexExp.test(e.target.value)) {
            setPhoneError(true);
        }
        else {
            setPhoneError(false);
        }

    }

    useEffect(() => {
        if (typeof (openModal) !== 'number') {
            setUploadRes({});
            setFileError(true);
        }
    }, [openModal]);

    useEffect(() => {
        hiringInfoApi();
    }, []);

    return (
        <Spin tip="Loading..." size="large" fullscreen={isLoading} spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 60, color: '#D1AB4A' }} spin />} >
            <section className="container-our-vacancies">
                <div className="vacancies-text">
                    <p>OUR VACANCIES</p>
                    <h1>"Embrace The Challenge, <br />
                        Discover Your Next Career Move"
                    </h1>
                </div>

                <div className="vacancies-info">
                    <ul>
                        {hiringPosData.length > 0 ? hiringPosData.map((item, index) => {
                            return (
                                <li key={index} className="container-card" onClick={() => handleCardClick(index)}>
                                    <div className="wrapper-card">
                                        <div className="info-part1">
                                            <div className="part1-row1">
                                                <p>{item.jobRole}</p>
                                                <span>{`Urgent`}</span>
                                            </div>

                                            <div className="part1-row2">
                                                <p>Job Type: <span>{item.jobType}</span></p>
                                                <p>Experience: <span>{item.experience}</span></p>
                                                <p>No. of Openings: <span>{item.noOfOpenings}</span></p>
                                            </div>
                                        </div>

                                        <div className="info-part2">
                                            {/* <img src={require('../../assets/OurVacancies/🎨-Color.png')} alt="Share Icon" /> */}
                                            {
                                                (openCard === index) ?
                                                    (<img src={require('../../assets/OurVacancies/ArowUp.png')} alt="Toggle Icon" />)
                                                    :
                                                    (<img src={require('../../assets/OurVacancies/drop down.png')} alt="Toggle Icon" />)
                                            }
                                            <span onClick={(e) => handleHiringModal(e, index)} className="btn-apply">Apply Now</span>
                                        </div>

                                        {(openModal === index) ? (<div className="container-modal" onClick={(e) => handleHiringModal(e, index)}>
                                            <div className="modal-hiring-form" onClick={handleFormPropagation}>
                                                <div className="form-row1">
                                                    <img src={require('../../assets/OurVacancies/FormLogo.png')} alt="InFineIt Logo" />
                                                    <div className="close-form" onClick={(e) => handleHiringModal(e, index)}>&#x2715;</div>
                                                </div>

                                                <div className="form-row2">
                                                    <h1>Apply Now</h1>
                                                    <p>Fill out this form, and we will forward your information to the hiring manager!</p>
                                                    <form onSubmit={handleFormSubmit}>
                                                        <label>
                                                            {/* <input type="text" value={fName} onChange={handleFirstName} placeholder='First Name*' /> */}
                                                            <TextField
                                                                onChange={handleFirstName}
                                                                value={fName}
                                                                id="outlined-basic"
                                                                label="First Name" variant="outlined"
                                                                fullWidth size="small"
                                                                margin="none"
                                                                required
                                                                error={fNameError}
                                                                onBlur={fNameCheck} />
                                                        </label>


                                                        <label>
                                                            {/* <input type="text" value={lName} onChange={handleLastName} placeholder='Last Name*' /> */}
                                                            <TextField
                                                                onChange={handleLastName}
                                                                value={lName}
                                                                id="outlined-basic"
                                                                label="Last Name"
                                                                variant="outlined"
                                                                fullWidth
                                                                size="small"
                                                                margin="none"
                                                                required
                                                                error={lNameError}
                                                                onBlur={lNameCheck} />
                                                        </label>

                                                        <label>
                                                            {/* <input type="email" value={eMail} onChange={handleEmail} placeholder='Email*' /> */}
                                                            <TextField
                                                                onChange={handleEmail}
                                                                value={eMail}
                                                                id="outlined-basic"
                                                                label="Email"
                                                                variant="outlined"
                                                                type="email"
                                                                fullWidth
                                                                size="small"
                                                                margin="none"
                                                                required
                                                                error={emailError}
                                                                onBlur={emailCheck} />

                                                        </label>

                                                        <label>
                                                            {/* <input type="text" value={mNumber} onChange={handleMobNumber} placeholder='Phone Number' /> */}
                                                            <TextField
                                                                onChange={(e) => handleMobNumber(e)}
                                                                value={mNumber}
                                                                label="Phone Number"
                                                                fullWidth
                                                                type="text"
                                                                size="small"
                                                                margin="none"
                                                                required
                                                                error={phoneError}
                                                                onBlur={phoneNumCheck} />
                                                        </label>

                                                        <label>
                                                            <input type="file" value={userResume} onChange={handleUserResume} placeholder='Upload Resume/CV' required />
                                                        </label>

                                                        <div className="hiring-form-btn-container">
                                                            <button type="button" onClick={(e) => handleCancelClick(e, index)}>Cancel</button>
                                                            <button type="button" disabled={isLoading} onClick={(e) => handleApplySubmitClick(e, index)}>{isLoading ? `Wait` : `Apply`}</button>
                                                        </div>
                                                    </form>
                                                    <ToastContainer
                                                        position="top-center"
                                                        autoClose={1000}
                                                        limit={0}
                                                        hideProgressBar={true}
                                                        newestOnTop={false}
                                                        closeOnClick
                                                        rtl={false}
                                                        pauseOnFocusLoss={false}
                                                        draggable={false}
                                                        pauseOnHover={false}
                                                        theme="light"
                                                    />
                                                </div>
                                            </div>
                                        </div>) : (null)}

                                        {(successModal === index && openModal !== index) ? (<div className="container-success-modal" onClick={(e) => handleSuccessModal(e, index)}>
                                            <div className="modal-succes" onClick={handleFormPropagation}>
                                                <div className="success-row1">
                                                    <img src={require('../../assets/OurVacancies/FormLogo.png')} alt="InFineIt Logo" />
                                                    <div className="close-form" onClick={(e) => handleSuccessModal(e, index)}>&#x2715;</div>
                                                </div>

                                                <div className="success-row2">
                                                    <img src={require('../../assets/OurVacancies/SuccessGif.gif')} alt="Success Gif" />
                                                    <h1>Applied Successfully</h1>
                                                    <p>We received your application successfully. We will contact you as soon as possible.</p>
                                                    <button type="button" onClick={(e) => handleSuccessModal(e, index)}>Close</button>
                                                </div>
                                            </div>
                                            <div className="container-confetti">
                                                <Confetti
                                                    numberOfPieces='500'
                                                />
                                            </div>
                                        </div>) : (null)}
                                    </div>

                                    {(openCard === index) && (<div className='vacancy-details' onClick={handleChildClick}>
                                        <div className="details-row1">
                                            <p>Job Location: <span>Surat, Gujarat</span></p>
                                        </div>

                                        <div classname="details-row2">
                                            <p>About the Role: <span>{item.aboutTheRole}</span></p>
                                        </div>

                                        <div className="details-row3">
                                            <p>Responsibilities:</p>
                                            <ul>
                                                {item.responsibility.map((itemR, indexR) => {
                                                    return (
                                                        <li key={indexR}>
                                                            {itemR}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>

                                        <div classname="details-row4">
                                            <p>Requirements</p>
                                            <ul>
                                                {item.requirements.map((itemRR, indexRR) => {
                                                    return (
                                                        <li key={indexRR}>
                                                            {itemRR}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>)}
                                </li>
                            );
                        }) : <h2>No Data to Show!</h2>
                        }
                    </ul>
                </div>

            </section>
        </Spin>
    );
}

export default OurVancancies;