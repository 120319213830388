import React from 'react';

function WhatsAppIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_125_1162)">
                <path d="M4.83719 18.5728C6.38245 19.5027 8.14926 19.9928 9.9646 19.9928C15.4655 19.9928 20 15.504 20 9.99634C20 4.49509 15.472 0 9.9646 0C4.47403 0 0.00717164 4.48441 0.00717164 9.99634C0.00717164 11.8359 0.497437 13.6162 1.42715 15.1627L0 20L4.83719 18.5728ZM1.17874 9.99634C1.17874 5.13031 5.12009 1.17142 9.9646 1.17142C14.8521 1.17142 18.8286 5.13031 18.8286 9.99634C18.8286 14.8625 14.8521 18.8213 9.9646 18.8213C8.28278 18.8213 6.64841 18.3449 5.23865 17.4434L5.01343 17.2995L1.73264 18.2675L2.7005 14.9866L2.55661 14.7615C1.65512 13.3514 1.17874 11.7038 1.17874 9.99634Z" fill="white" />
                <path d="M4.8692 8.15435C5.06268 9.17043 5.63702 11.1248 7.30923 12.797C8.98144 14.4692 10.9358 15.0437 11.952 15.2372C13.1157 15.4587 14.8233 15.4921 15.6578 14.6576L16.123 14.1924C16.3578 13.9576 16.4872 13.6452 16.4872 13.313C16.4872 12.9809 16.3578 12.6687 16.123 12.4338L14.2625 10.5733C14.0275 10.3383 13.7153 10.2091 13.3831 10.2091C13.051 10.2091 12.7388 10.3383 12.5038 10.5733L12.0387 11.0384C11.7546 11.3225 11.2182 11.3238 10.932 11.0419L9.0762 9.10833C9.07345 9.10543 9.0707 9.10253 9.0678 9.09978C8.78323 8.81521 8.78323 8.3521 9.0678 8.06753L9.53289 7.60244C10.019 7.11629 10.019 6.3297 9.53289 5.84371L7.67239 3.98321C7.18762 3.49844 6.39874 3.49844 5.91381 3.98321L5.44872 4.44845V4.4483C4.78268 5.11449 4.56066 6.53448 4.8692 8.15435ZM6.27712 5.27685C6.7654 4.79971 6.73931 4.79055 6.79318 4.79055C6.81164 4.79055 6.8301 4.79757 6.84414 4.81161C8.80459 6.78259 8.72585 6.66677 8.72585 6.72323C8.72585 6.74291 8.71868 6.76015 8.7048 6.77419L8.23956 7.23928C7.49874 7.98009 7.49584 9.17928 8.23483 9.92345L10.0917 11.8583C10.0946 11.861 10.0973 11.8639 10.1002 11.8668C10.8409 12.6075 12.124 12.6099 12.8671 11.8668L13.3322 11.4017C13.3603 11.3736 13.406 11.3736 13.4341 11.4017C15.3946 13.3726 15.3158 13.2567 15.3158 13.3132C15.3158 13.333 15.3086 13.3501 15.2948 13.3642L14.8295 13.8292C14.5108 14.148 13.4954 14.3387 12.1711 14.0865C11.2873 13.9181 9.58798 13.4191 8.13763 11.9687C6.68728 10.5184 6.18832 8.81902 6.02001 7.93523C5.76763 6.61092 5.95837 5.59545 6.27712 5.27685Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_125_1162">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}

export default WhatsAppIcon;