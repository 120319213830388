import React from 'react'
import "./scrolltoptop.scss"
import Arrowupicon from '../svg/Arrowupicon'
import scrollToTop from '../../helper/scrollToTop'
import { useRef, useEffect } from 'react'
import { useMatches } from 'react-router-dom'

const ScrolltopTop = () => {

  const scrollUpRef = useRef(null);
  const location =  useMatches()

  useEffect(() => {
    if( (location[1].pathname === '/technologies') && scrollUpRef.current){
      scrollUpRef.current.style.border = '1px solid white';
    }

  }, [location])

  // Up 
  // Scroll Up
  return (
    <div className='scrolltoptopContainer' onClick={()=>scrollToTop()}>
      <div className="scroll">
        Scroll
      </div>
      <div ref = {scrollUpRef} className="up">
      Up <Arrowupicon />
    </div>
    </div>
  )
}

export default ScrolltopTop
