import React from 'react'
import frontendtoolsImage from "../../assets/services/Tools/Frontend.svg"

const Frontendtools = () => {
  return (
    <img src={frontendtoolsImage} loading='lazy' />

  )
}

export default Frontendtools
