import { useEffect, useState } from 'react'

const useOnScroll = () => {
  const [scroll, setScroll] = useState(0)
  
  useEffect(()=>{
    window.addEventListener("scroll",(e)=>{
        setScroll(window.scrollY)
    })    
  },[scroll])

  return [scroll]
}

export default useOnScroll


