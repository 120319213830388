 const data = [
  {
    heading: "",
    paragraph: [
      "Our services are designed to help individuals and businesses achieve their goals through innovative solutions and expert guidance. We offer a wide range of services, including Web and App Development, UI/UX Designing, Software Development, API Integration and many more.",
      "Our team of experienced professionals is dedicated to providing the highest level of service and support to our clients.",
      "We work closely with our clients to understand the unique needs and develop customized solutions to meet those needs. We pride ourselves on our ability to deliver results and exceed expectations.",
    ],
  },
  {
    heading: "UI/UX Designing:",
    paragraph: [
      `Creating user-centric mobile designs that are sure to bring forth a successful app journey. Our experts deliver stunning web experiences for an impactful impression. A powerful and stunning website lets your users imbibe the exact image of your brand.
      `,
      "Our UI/UX designers and front end developers are well aware of the dynamic nature of the industry and are highly-equipped to keep your website updated with the changing trends.",
      "We build futuristic websites that are driven by creativity and technical know-how.",
    ],
  },
  {
    heading : "Figma:",
    paragraph : [
        `Figma us a cloud-based, collaborative design tool that allows multiple users to work on the same design file simultaneously. It is primarily used for creating and editing user interface design, but can also be used for creating illustrations, diagrams and other types of graphics.`,
        `Figma features a real-time collaborative feature that allows users to see changes made by others in real-time, as well as extensive library of design elements and plugins to extends its functionality. It also supports design system management, design handoff and developer handoff, and it’s available for web, Mac, Windows and Linux.`
    ]
  },
  {
    heading: "Adobe XD:",
    paragraph : [
        'Adobe XD is a user experience design software developed and published by Adobe Systems. ',
        'It is used for designing and prototyping user interfaces for websites and mobile apps.',
        ' XD offers a wide range of tools for creating wireframes, mockups, adn interactive prototypes, as well as the ability to share and collaborate on designs with others. ',
        'It also includes support for vector networks, design components, and integration with other Adobe Creative Cloud apps.'
    ]
  },
  {
    heading : "Web Development:",
    paragraph : [
        `Creating Versatile, Responsive and Progressive websites for clients worldwide.`,
        `Our web application development framework has been planned and streamlined to help you save time and money. Our team specializes in Agile development methodologies, which allows you to see the web application evolve at different points in the development process.`,
        `Our passionate team delivers solutions that are visually appealing and excellently coded.`
    ]
  },
  {
    heading : "App Development:",
    paragraph : [
        `Smart Mobile App Development Services For Smarter Businesses.`,
        `We have a varied array of services offered in this arena of mobile application development. This possibility increases our client’s digital presence and brand engagement.`
    ]
  },
  {
    heading : "Angular",
    paragraph : [
        ` Well-liked open-source JavaScript framework for creating dynamic single-page applications is called Angular. It is utilized by enterprises and organizations of all sizes for a range of tasks, including creating intricate, customer-facing apps as well as internal tools.
        `,
        `Our IT company has a group of skilled Angular developers who are experts in providing tailored solutions that are tailored to the individual requirements of our clients. Our team can provide top-notch, scalable solutions because we have a thorough understanding of the framework and its capabilities.`
    ]
  },
  {
    heading : "Node.JS",
    paragraph : [
        `Node.js is a JavaScript server-side framework that gives programmers the tools to create responsive, scalable, and effective applications. Node.js was created in 2009, but due to its capacity to handle large levels of traffic and real-time data, it has swiftly grown to become one of the most popular platforms for developing online applications.`,
        `
        The quick performance of Node.js is one of its main advantages. As a result of the platform's event-driven, non-blocking I/O strategy, which enables it to handle numerous concurrent connections, real-time applications like online games and chat programmed thrive on it.
        `
    ]
  },
  {
    heading : ".Net Core",
    paragraph : [
        'NET Core is a free, open-source and cross-platform framework for building modern applications. Developed by Microsoft, .NET Core is designed for building applications for Windows, Linux and macOS platforms. It is a high-performance framework that provides developers with a set of tools and libraries to create applications that are scalable, reliable and secure.',
        `
        .NET Core supports a wide range of programming languages including C#, F# and Visual Basic .NET. It provides a robust set of libraries and tools that enable developers to create applications for cloud, desktop, web and mobile platforms. The framework also supports multiple platforms, which means that applications can be built for a range of devices including IoT devices, embedded devices and gaming consoles.
        `
    ]
  },
  {
    heading : "React",
    paragraph : [
        `A well-known JavaScript package for creating interactive and user-friendly user interfaces is called React. Since its creation by Facebook, it has grown to be one of the most used libraries for creating web apps.`,
        `Our IT company has a group of skilled React developers who are committed to providing our customers with high-quality solutions that are tailored to their individual demands. Our team can produce apps that are both useful and aesthetically pleasing thanks to a thorough understanding of the library and its possibilities.`
    ]
  },
  {
    heading : "React Native",
    paragraph : [
            `Using JavaScript and React, developers may create mobile applications using the open-source framework React Native. React Native offers a quick, effective, and affordable solution to create high-quality, cross-platform mobile apps by utilising a single codebase.`,
            `Our IT company employs a group of skilled React Native developers who are committed to providing our customers with cutting-edge solutions. Our staff has the knowledge and experience to support you in achieving your objectives, whether you're wanting to create a brand-new React Native application or update an already-existing one.`
    ]
  },
  {
    heading : "Android",
    paragraph : [
        `Over 2 billion active devices worldwide are running the most widely used mobile operating system, Android. Android, which was created by Google, provides a vast array of features and capabilities, making it the perfect platform for a range of mobile applications.`,
        `Our IT company has a group of skilled Android developers who are committed to offering our customers cutting-edge, high-quality solutions. Whether you want to create a brand-new Android app or update an existing one, our team has the knowledge and experience to support you in reaching your objectives.`
    ]
  },
  {
    heading : "Flutter",
    paragraph : [
        `A well-liked open-source framework for creating top-notch, cross-platform mobile apps is called Flutter. Flutter has gained popularity among developers for creating stunning and responsive mobile apps for both iOS and Android thanks to its quick development cycle and customizable UI.`,
        `Our IT company employs a group of skilled Flutter developers who are committed to providing our customers with cutting-edge solutions. Whether you want to create a brand-new Flutter app or update an existing one, our team has the knowledge and experience to support you in reaching your objectives.`
    ]
  },
  {
    heading : "iOS",
    paragraph : [
        `The iPhone and iPad, two of Apple's well-known mobile devices, run on the iOS operating system. iOS has grown to be one of the most widely used mobile platforms in the world because to its user-friendly interface, strong hardware, and diverse software ecosystem.`,
        `
        Our IT company has a group of skilled iOS developers who are committed to offering our customers cutting-edge, high-quality solutions. Our staff has the knowledge and experience to support you in achieving your objectives, whether you're looking to create a brand-new iOS app or update an existing one.
        `
    ]
  }
];

export default data